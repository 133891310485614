import React, { useEffect, useState } from "react";
import InputField from "../../view/elements/Input";
import Select from "react-select";
import { postApiUtility } from "../Utility/user.utility";
import PageLoader from "../../view/elements/PageLoader";
import { toast } from "react-toastify";
import MasterService from "../../service/MasterService";

const IndividualProfile = ({
  profession,
  formData,
  setFormData,
  errors,
  handleUserType,
  is_team_member,
  gst_check

}) => {
  const [panData, setPanData] = useState({});
  const [loading, setLoading] = useState(false);
  const [designation, setDesignation] = useState([]);

  useEffect(() => {
    getDesignationList();
  }, []);
  const user_info = JSON.parse(localStorage.getItem("loginUserInfo"));
  const user_type = user_info?.user_type;
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Ensure only numbers are allowed for mobile fields
    if (value.length === 10 && name === "pan_no") {
      validatePan(value);
    }
    if (name === "dealership_contact") {
      const numericValue = value.replace(/\D/g, "");
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: numericValue,
      }));
    } else if (name === "pan_no") {
      const panValue = value.toUpperCase();
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: panValue,
      }));
    } else {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const getDesignationList = async () => {
    const response = await MasterService.get(
      "/partner/partner/get-designation"
    );
    setDesignation(response.data.message);
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevValues) => ({
      ...prevValues,
      designation: selectedOption.id,
    }));
  };

  const selectedProfession = profession.find(
    (prof) => prof.id === formData.profession_id
  );

  const designation_value = designation?.find(
    (prof) => prof.designation === formData.designation
  );

  const validatePan = async (pan_no) => {
    
    setLoading(true);
    formData.pan_no = pan_no;
    const validatePanResponse = await postApiUtility('partner/partner/validate-PAN', {
      "pan_number": pan_no,
      "partner_id": formData.id
    })

    if(validatePanResponse?.data?.data?.message === 'New PAN') {
    const panDataResponse = await MasterService.get(`/partner/partner/pan-validation?pan_number=${pan_no}&partner_id=${formData.id}&user_id=${formData.user_id}`)
    const panData = panDataResponse.data.data
      if (panData.statusCode === 200 ) {
        toast.success("Pan Verified Successfully");
        setLoading(false);
      } else {
          setLoading(false)
          toast.error("Invalid Pan")
    
      }
  
      setPanData(panData.data);
      // Update formData with values from PAN data
      setFormData((prevValues) => ({
        ...prevValues,
        dealership_contact: panData?.data?.mobile_no || prevValues?.dealership_contact,
        dealership_email: panData?.data?.email || prevValues?.dealership_email,
        name: panData?.data?.name || prevValues?.name,
        aadhar_no : panData?.data?.maskedAadhaar || prevValues?.maskedAadhaar,
      }));
      if (panDataResponse?.data?.data?.gstDetails?.data?.gstinList[0]?.gstin){
        gst_check(panDataResponse?.data?.data?.gstDetails?.data?.gstinList[0]?.gstin)
      }
      if (panData.applicant_type == "Individual" && panData.gst_required === true) {
        handleUserType ("company");
      } else if (panData.applicant_type == "Individual" && panData.gst_required === false) {
        handleUserType ("");
      } else if (panData.applicant_type == "company" && panData.gst_required === true) {  // Fixed typo
        handleUserType ("company");
      } else if (panData.applicant_type == "company" && panData.gst_required === false) {  // Fixed typo
        handleUserType ("company");
      }
      
      setLoading(false);
    }
    else {
      toast.error("Pan Already Exist")
      setLoading(false);
    }
  }
  



  return (
    <form>
      {loading ? <PageLoader /> : ""}
      <div className="row">
        <div className="col-md-4">
          {!is_team_member && (
            <InputField
              type="text"
              name="pan_no"
              id="pan_no"
              placeholder="Pan Card Number"
              label={
                <>
                  Pan Card Number<span style={{ color: "red" }} className="error-msg-txt">*</span>
                </>
              }
              value={formData.pan_no || ""}
              onChange={handleChange}
              error={errors.pan}
              maxLength="10"
              pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
              title="PAN should be in the format: ABCDE1234F"
            />
          )}
          <InputField
            type="text"
            name="name"
            id="name"
            placeholder="Name"
            label={
              <>
                Name<span style={{ color: "red" }} className="error-msg-txt">*</span>
              </>
            }
            value={formData.name || ""}
            onChange={handleChange}
            error={errors.name}
            disabled={formData.name}
          />
          {
            <InputField
              type="text"
              name="dealership_email"
              id="dealership_email"
              placeholder="Email"
              label={
                <>
                  Email<span style={{ color: "red" }} className="error-msg-txt">*</span>
                </>
              }
              value={formData.dealership_email || ""}
              onChange={handleChange}
              error={errors.dealership_email}
            />
          }
        </div>

        {!is_team_member && (
          <div className="col-md-4">
            <label>Profession Type<span className="error-msg-txt">*</span></label>
            <Select
              id="profession_id"
              name="profession_id"
              options={profession}
              value={selectedProfession}
              getOptionLabel={({ profession_name }) => profession_name}
              getOptionValue={({ id }) => id}
              onChange={handleSelectChange}
              error={errors.profession_id}
            />
            {errors.profession_id && (
              <div className="error">{errors.profession_id}</div>
            )}
          </div>
        )}
        {is_team_member && (
          <div className="col-md-4">
            <label>Designation</label>
            <Select
              id="designation"
              name="designation"
              options={designation}
              value={designation_value}
              getOptionLabel={({ designation }) => designation}
              getOptionValue={({ id }) => id}
              onChange={handleSelectChange}
              error={errors.designation}
            />
            {errors.profession_id && (
              <div className="error">{errors.profession_id}</div>
            )}
          </div>
        )}
        <div className="col-md-4">
          <InputField
            type="text"
            name="dealership_contact"
            id="dealership_contact"
            placeholder="Mobile"
            label={
              <>
                Mobile<span style={{ color: "red" }} className="error-msg-txt">*</span>
              </>
            }
            value={formData.dealership_contact || ""}
            onChange={handleChange}
            error={errors.dealership_contact}
            maxLength="10"
            inputMode="numeric"
            pattern="[0-9]*"
          />

          {errors.dealership_contact && (
            <div className="error">{errors.dealership_contact}</div>
          )}
        </div>

        {!is_team_member && (
          <div className="col-md-4">
            <InputField
              type="text"
              name="pincode"
              id="pincode"
              placeholder="Pincode"
              label={
                <>
                  Pin Code<span style={{ color: "red" }} className="error-msg-txt">*</span>
                </>
              }
              value={formData.pincode || ""}
              onChange={handleChange}
              error={errors.pincode}
              maxLength="6"
            />
            {errors.pincode && <div className="error">{errors.pincode}</div>}
          </div>
        )}
        {!is_team_member && (
          <div className="col-md-4">
            <InputField
              type="text"
              name="aadhar_no"
              id="aadhar_no"
              placeholder="Aadhaar Number"
              label="Aadhaar Card Number"
              value={formData.aadhar_no || ""}
              onChange={handleChange}
              disabled={true}
            />
          </div>
        )}
        { user_type === 1 && 
          <div className="col-md-4">
          <InputField
            type="text"
            name="sfa_refferal_user_id"
            id="sfa_refferal_user_id"
            placeholder="SFA code"
            label="SFA code"
            value={formData.sfa_referral_user_id || ""}
            onChange={handleChange}
            disabled={true}
          />
        </div>
        }
      </div>
    </form>
  );
};

export default IndividualProfile;
