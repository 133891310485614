'use strict'

import React from 'react';

class CheckBox extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      active: (props.locked && props.active) || false,
      value: props.value || "",
      id: props.id || "",
      name: props.name || "",
      type: props.type || "type",
      error: props.error || "",
      checked: props.checked || false,
      readonly: props.readOnly || false,
      label: props.label || "Label",
      disabled: props.disabled || false,
    };
  }

  changeValue(event) {
    const value = event.target.value;
    this.setState({ value, error: "" });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked || prevProps.readOnly !== this.props.readOnly || prevProps.disabled !== this.props.disabled) {
      this.setState({
        checked: this.props.checked,
        readonly: this.props.readOnly,
        disabled: this.props.disabled,
      });
    }
  }

  render() {
    const { value, checked, label, id, type, name, readonly, disabled } = this.state;
    
    return (
      <label htmlFor={id} className={"gs_control gs_radio"}>
        {label}
        <input
          id={id}
          className={readonly ? "" : "form-control"}
          type={type}
          value={value}
          name={name}
          checked={checked}
          onChange={this.props.onChange}
          readOnly={readonly}
          disabled={disabled}
        />
        <span className="gs_control__indicator"></span>
      </label>
    );
  }
}

CheckBox.defaultProps = {
  error: {
    status: true,
    message: '',
  },
  popupCls: ""
};

export default CheckBox;
