import moment from 'moment'
import React from 'react'
import { formatCurrency } from '../../MyLeads/helpers/helpers'

const InvoiceCard = ({notesData}) => {
    return (
        <div className='invoice-head'>
            <div className='flex flex-row justify-space-between'>
                <div className='flex gap-10px'>
                    <div className='invoice-icon'><img src={require('../../images/docimg.svg')} width={"25px"} height={"20px"}/></div>
                    <div className='flex flex-col'>
                        <div className='font-18px font-weight-400' >{notesData.invoice_no}</div>
                        <div className='font-12px font-weight-400'>Raised on : {moment(notesData.created_at).format("DD/MM/YYYY")}</div>
                    </div>
                </div>
                <div>
                    <div className='font-18px font-weight-500'>₹ {formatCurrency(notesData.payout_amount)}</div>
                    <div className='invoiceTag'>{notesData.paid_status}</div>
                </div>
            </div>
        </div>
    )
}

export default InvoiceCard