import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import secureStorage from '../../config/encrypt';
import MasterService from '../../service/MasterService';
import { withRouter } from 'react-router-dom';

class NewNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            langOption: [],
            dealer_id_hash:""
        };
        
    }

    componentWillMount = () => {
        const dealer_id_hash = localStorage.getItem("dealer_hash_id")
        
        this.setState({dealer_id_hash : dealer_id_hash})
        let localLangData = secureStorage.getItem('langOption');
        if (!localLangData) {
            MasterService.get('core/commonservice/master?master[]=lang').then((response) => {
                if (response.status === 200 && response.data.status === 200) {
                    let langData = response.data.data.lang;
                    secureStorage.setItem('langOption', langData);
                    this.setState({ langOption: langData });
                }
            });
        } else {
            this.setState({ langOption: localLangData });
        }
    };

    handleLogout = () => {
        if (typeof this.props.logoutUser === 'function') {
            this.props.logoutUser();
        }
    };

    handlenewPartner = () => {
        // window.location.reload()
    }
    handleSchemeClick = () => {
        // this.props.navigate('/scheme-details');
        this.props.history.push('/scheme-details');
    };

    render() {
        let name = "Admin";
        let role, profileTitle, userType, dealerName, stock_category, dealer_id_hash,parent_partner_id,is_payout_applicable = [];
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
                name = loginResponse.data.user_data.name;
                role = loginResponse.data.user_data.role;
                userType = loginResponse.data.user_data.role_type;
                dealer_id_hash = loginResponse.data && loginResponse.data.dealer_data && loginResponse.data.dealer_data[0].dealer_id_hash;
                parent_partner_id = loginResponse.data.user_data.parent_partner_id
                is_payout_applicable = loginResponse.data.user_data.is_payout_applicable || 0
            }
            if (loginResponse && loginResponse.data && loginResponse.data.dealer_data && loginResponse.data.dealer_data[0]) {
                let dealer = loginResponse.data.dealer_data[0];
                if (Array.isArray(dealer.stock_category))
                    stock_category = dealer.stock_category;
                dealerName = `${dealer.organization}`;
            }
            profileTitle = [dealerName, userType].filter(v => v).join(" | "); 
        }
        if (localStorage.getItem('loginUserInfo') && !name) {
            const loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'));
            name = loginUserInfo.user_data.name;
        }

        return (
            <nav className={this.props.navClass}>
                <ul className="navbar-nav">
                   
                        <>
                            {/* <li>
                                <NavLink to="/partner-dashboard" activeClassName="active">
                                    <img
                                        src={require(`../../../src/saathi-web/images/dashboard.svg`)}
                                        alt="dashboard"
                                    />
                                    Dashboard
                                </NavLink>
                            </li> */}
                            <li>
                                <NavLink to="/my-saathi-earnings" activeClassName="active">
                                    <img
                                        src={require(`../../../src/saathi-web/images/myearnings.svg`)}
                                        alt="earnings"
                                    />
                                    My earnings
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/saathi-leads" activeClassName="active">
                                    <img
                                        src={require(`../../../src/saathi-web/images/myleads.svg`)}
                                        alt="leads"
                                    />
                                    My Leads
                                </NavLink>
                            </li>
                            {/* <li>
                                <NavLink to="/saathi-performance" activeClassName="active">
                                    <img
                                        src={require(`../../../src/saathi-web/images/myperformance.svg`)}
                                        alt="performance"
                                    />
                                    My Performance
                                </NavLink>
                            </li> */}
                            <li>
                                {<NavLink to="/my-saathi-teams?type=sub_partner" activeClassName="active">
                                    <img
                                        src={require(`../../../src/saathi-web/images/myteam.svg`)}
                                        alt="teams"
                                    />
                                    My Teams
                                </NavLink> }
                            </li>
                            <li>
                                <NavLink to="/saathi-tools" activeClassName="active">
                                    <img
                                        src={require(`../../../src/saathi-web/images/mytools.svg`)}
                                        alt="tools"
                                    />
                                    My Tools
                                </NavLink>
                            </li>
                        </>
                       {(parent_partner_id==0||(parent_partner_id>0 && is_payout_applicable==1)) && <ul className='payout-menu-item'>
                            <li className={"payout-menu-card"}>
                            {/* <NavLink to="/saathi-tools" className={"payout-menu-card"}> */}
                                    <div>
                                    <img
                                        src={require(`../../../src/saathi-web/images/rupees.svg`)}
                                        alt="tools"
                                    />
                                    <span>Payout Schemes</span>
                                    </div>
                                    <div  onClick={this.handleSchemeClick}>Check</div>
                                {/* </NavLink> */}
                                {/* <span>Payout Schemes</span><button>check</button>  */}
                                
                            </li>
                        </ul>}
                </ul>


                {/* {dealer_id_hash && dealer_id_hash !== "" ? */}
                    <div style={{ borderTop: "1px solid #6952BD" }}>
                         <ul className="navbar-nav"onClick={this.handlenewPartner}>
                            <li>
                            <NavLink to={`/my-account-profile?${this.state.dealer_id_hash}`} activeClassName="active">
                                <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                <img src={require(`../../../src/saathi-web/images/user.svg`)} alt="user" width={40} height={40} />
                                <div> {name}</div>
                                </div>
                                <img src='https://ambak.storage.googleapis.com/partner/3079/1725257002719.svg' alt='user' width={16} height={16} />
                                </div>
                                </NavLink>
                            </li>
                         </ul>
                        {/* <div className="dropdown" title={profileTitle || ''}>
                            <a href="" style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ marginRight: 10 }}>
                                    <img src={require(`../../../src/saathi-web/images/user.svg`)} alt="user" width={40} height={40} />
                                </span>
                                {this.props.t('menu.hi')} {name}<span className="caret"></span>
                            </a>
                            <ul className="dropdown-menu">
                                {role === 'dealercentral' &&
                                    <li>
                                        <NavLink to={"/dealer/" + dealer_id_hash + "/basic-details"} activeClassName="active">
                                            {this.props.t('menu.my_account')}
                                        </NavLink>
                                    </li>
                                }
                                <li onClick={this.handleLogout}>
                                    <NavLink to="" className="headermenulogout">{this.props.t('menu.logout')}</NavLink>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                    {/* : ""} */}
            </nav>
        );
    }
}

export default withRouter(withTranslation('common')(NewNavbar));
