import React, { useEffect, useState } from "react";
import InputField from "../../../../view/elements/Input";
import Select from "react-select";
import { handlePriceInputIndian } from "../../../MyLeads/helpers/helpers";

const LoanOfferAdditionalIncome = ({
  index,
  ALL_OPTIONS,
  setAdditionalIncomeType,
  removeAdditionalIncome,
  setAdditionalIncomeData,
  additionalIncomeData,
  isCoApp = false,
}) => {
  const [additionalIncome, setAdditionalIncome] = useState([]);

  let incomeType = "income_type_id";
  let incomeTypeOption = `filtered_additional_income_type_${index}`;
  let amountType = "amount_type";
  let amountTypeOption = "amount_type";
  let amount = "amount";

  if (isCoApp) {
    incomeType = `ca_${incomeType}`;
    incomeTypeOption = `ca_${incomeTypeOption}`;
    amountType = `ca_${amountType}`;
    amountTypeOption = `ca_${amountTypeOption}`;
    amount = `ca_${amount}`;
  }

  useEffect(() => {
    if (additionalIncomeData && additionalIncomeData.length > 0) {
      setAdditionalIncome(additionalIncomeData);
    }
  }, [additionalIncomeData]);

  const handleSelectChange = (data, field_name, index) => {
    if (data && field_name) {
      if (field_name === incomeType) {
        setAdditionalIncomeType(data, index);
      }
      const exAddInc = [...additionalIncome];
      exAddInc[index] = {
        ...exAddInc[index],
        [field_name]: data.value,
        status: "1",
      };
      setAdditionalIncome(exAddInc);
      setAdditionalIncomeData(exAddInc);
    }
  };

  const handleInputChange = (value, field_name, index) => {
    if (value && field_name) {
      const exAddInc = [...additionalIncome];
      exAddInc[index] = {
        ...exAddInc[index],
        [field_name]: value.replace(/[^0-9]/g, ""),
        status: "1",
      };
      setAdditionalIncome(exAddInc);
      setAdditionalIncomeData(exAddInc);
    }
  };

  const handleRemoveAdditionaIncome = (index) => {
    removeAdditionalIncome(index);
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-4">
          <label>Income Type</label>
          <Select
            id={incomeType}
            onChange={(data) => handleSelectChange(data, incomeType, index)}
            options={ALL_OPTIONS[incomeTypeOption]}
            name={incomeType}
            placeholder="Income Type"
            value={
              ALL_OPTIONS[incomeTypeOption]?.find(
                (option) =>
                  option.value === additionalIncome[index]?.[incomeType] &&
                  additionalIncome[index]?.["status"] === "1"
              ) || ""
            }
          />
        </div>
        <div className="col-sm-4">
          <label>Mode</label>
          <Select
            id={amountType}
            name={amountType}
            onChange={(data) => handleSelectChange(data, amountType, index)}
            options={ALL_OPTIONS[amountTypeOption]}
            placeholder="Select Mode"
            value={
              ALL_OPTIONS[amountTypeOption]?.find(
                (option) =>
                  option.value === additionalIncome[index]?.[amountType] &&
                  additionalIncome[index]?.["status"] === "1"
              ) || ""
            }
          />
        </div>
        <div className="col-sm-4">
          <InputField
            maxLength={12}
            type="text"
            id={amount}
            name={amount}
            placeholder="Amount"
            label="Amount"
            onChange={(e) => handleInputChange(e.target.value, amount, index)}
            value={
              additionalIncome[index]?.["status"] === "1"
                ? handlePriceInputIndian(additionalIncome[index]?.[amount])
                : ""
            }
          />
        </div>
        <button
                          className="loanremoveoffer"
                          style={{
                            marginLeft:"80%",
                            border:"none",
                            background:"#fff",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveAdditionaIncome(index);
                          }}
                        >
                          Remove
                        </button>
      </div>
    </>
  );
};

export default LoanOfferAdditionalIncome;
