import React, { useState } from 'react'
import NotesViewDetailStrip from './NotesViewDetailStrip'
import moment from 'moment'
import { formatCurrency } from '../../MyLeads/helpers/helpers'

const NotesCard = ({ type, togglePopup, data, bankList }) => {
    const [showDetail, setShowDetail] = useState(false)
    return (
        <div>
            <p className='notes-header'>{`1`} {type} Note(s)</p>
            <div className='notes-head'>
                <div className='flex flex-row justify-space-between notes-inner-box'>
                    <div className='flex gap-10px'>
                        <div className='invoice-icon'><img src={data?.leads.length ? bankList.find(item => item.id == data?.leads[0]?.financer_id)?.logo : require('../../images/docimg.svg')} width={"25px"} height={"20px"} /></div>
                        <div className='flex flex-col'>
                            <div className='font-18px font-weight-400' >{data.invoice_no}</div>
                            <div className='font-12px font-weight-400'>Raised on : {moment(data.created_at).format("DD/MM/YYYY")}</div>
                        </div>
                    </div>
                    <div>
                        <div className='font-18px font-weight-500'>₹ {formatCurrency(data.payout_amount)}</div>
                    </div>
                </div>
                <div className='flex justify-space-between notes-bottom'>
                    <div><a className='font-btn-color' target='_blank' href={data.approved_invoice_url || data.pending_invoice_url} >View Note</a></div>
                    <div className='font-btn-color' onClick={() => setShowDetail(!showDetail)}>View Details
                        <img src={require('../../images/accordianicon.svg')} width={"12px"} className={`accordion-icon ${showDetail ? 'rotate' : ''}`} />
                    </div>
                </div>
                {showDetail && data.leads?.length && data.leads.map(leadsData => {
                    return <NotesViewDetailStrip bankList={bankList} togglePopup={togglePopup} leadsData={leadsData} data={data} />
                })}
            </div>
        </div>
    )
}

export default NotesCard