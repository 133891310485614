import React, { Component } from 'react';

class Rupees extends React.Component {

  render() {
    return (
        <span>Rp</span>
    )
  }
}
export default Rupees;