import React, { useEffect, useMemo, useState } from "react";
import InputField from "../../../../view/elements/Input";
import Select from "react-select";
import { handlePriceInputIndian } from "../../../MyLeads/helpers/helpers";
import { toast } from "react-toastify";
import generalService from "../../../../service/generalService";
import { SAVE_CUST_DETAILS } from "../../../../queries/sangam/customer.gql";
import { executeGraphQLMutationFinex } from "../../../../common/executeGraphQLQuery";

const PROPERTY_TABS = [
  { id: "yes", label: "Yes" },
  { id: "no", label: "No" },
];

const SUB_PROPERTY_MAPPING_OBJ = {
  9: [1, 2, 3],
  11: [1, 4],
  12: [1, 4],
  13: [1, 2, 3],
  14: [1, 2, 3, 4],
};

const LoanOfferProperty = ({
  breData,
  handleBreDataChange,
  masterdata,
  viewOffer,
}) => {
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [errors, setErrors] = useState({});

  const ALL_OPTIONS = useMemo(() => {
    const options = masterdata ? masterdata : {};
    const existingPropertyType = breData["property_type"] || null;
    options["property_type"] = options["property_type"]?.map((obj) => ({
      ...obj,
      value: +obj.id,
    }));
    options["property_sub_type"] = options["property_sub_type"]?.map((obj) => ({
      ...obj,
      value: +obj.id,
    }));
    options["agreement_type"] = options["agreement_type"]?.map((obj) => ({
      ...obj,
      value: +obj.id,
    }));
    options["state_list"] = stateList;
    options["city"] = cityList;
    if (existingPropertyType) {
      const filteredSubType = options["property_sub_type"]?.filter((obj) =>
        SUB_PROPERTY_MAPPING_OBJ[existingPropertyType]?.includes(obj.value)
      );
      options["filtered_property_sub_type"] = filteredSubType;
    }
    return options;
  }, [masterdata, stateList]);

  const setStateAndCityList = async () => {
    try {
      const response = await generalService.stateCityList();
      if (response?.data?.status === 200) {
        const result = { ...response.data.data };
        if (result.city && result.city.length) {
          result.city = result.city.map((data) => ({
            ...data,
            value: data.id,
            label: data.name,
          }));
        }

        if (result.state && result.state.length) {
          result.state = result.state.map((data) => ({
            ...data,
            value: data.id,
            label: data.name,
          }));
        }
        setCityList(result.city);
        setStateList(result.state);
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Failed to fecth state and city list");
    }
  };

  useEffect(() => {
    setStateAndCityList();
  }, []);

  const handleFieldChange = (name, value, price = false) => {
    if (price) {
      value = value.replace(/[^0-9]/g, "");
    }
    if (name === "property_type") {
      const filteredSubType = ALL_OPTIONS["property_sub_type"]?.filter((obj) =>
        SUB_PROPERTY_MAPPING_OBJ[value].includes(obj.value)
      );
      ALL_OPTIONS["filtered_property_sub_type"] = filteredSubType;
    }
    setErrors((current) => ({
      ...current,
      [name]: "",
    }));
    handleBreDataChange(name, value);
  };
  const setCityByStateChange = (state) => {
    let options = ALL_OPTIONS ? { ...ALL_OPTIONS } : {};
    if (options?.city && options?.city?.length && state) {
      let record = options.city
        .filter((ob) => ob.state_id == state)
        .map((data) => {
          data.value = data.id;
          data.label = data.label;
          return data;
        });
      ALL_OPTIONS["city_list"] = record;
    }
  };

  const handleTabChange = (name, value) => handleBreDataChange(name, value);

  const isValidData = () => {
    if (
      breData.is_property_identified === "yes" &&
      (!breData.expected_property_value ||
        breData.expected_property_value === "" ||
        breData.expected_property_value === null)
    ) {
      setErrors((current) => ({
        ...current,
        expected_property_value: "Expected Market Value is required",
      }));
      return false;
    }
    return true;
  };

  const handleViewOffer = async () => {
    try {
      if (isValidData()) {
        const AGREEMENT_TYPE_GPA_ID = 4;
        if (
          breData["agreement_type"] === AGREEMENT_TYPE_GPA_ID &&
          breData.is_property_identified === "yes"
        ) {
          alert("We do not provide home loans for GPA properties");
          breData["agreement_type"] = null;
        }
        const {
          lead_id,
          is_property_identified,
          agreement_type,
          expected_property_value,
          property_agreement_value,
          property_type,
          property_sub_type,
          property_city,
          property_state,
        } = breData;
        if (lead_id) {
          const mutation = SAVE_CUST_DETAILS;
          let lead_details = {};
          if (is_property_identified === "no") {
            lead_details = { lead_id, is_property_identified };
          } else {
            lead_details = {
              lead_id,
              expected_property_value,
              is_property_identified,
              property_type,
              property_sub_type,
              property_agreement_value,
              property_city,
              property_state,
              ...(property_type === 9 || property_type === 13
                ? { agreement_type }
                : {}),
            };
          }
          const variables = {
            api_called_by: "saathi_web",
            partner_id: loginUserInfo?.dealer_id,
            LeadAdditionalInput: { lead_id, lead_details },
          };
          await executeGraphQLMutationFinex(mutation, variables);
          toast.success("Success");
        }
        viewOffer();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="commonloancard">
        <div className="mainheadingall">Property Details</div>
        <div className="allheadingincome">Property Details</div>
        <div className="cibilloanflex">
          {PROPERTY_TABS.map((tab) => (
            <div
              key={tab.id}
              className={`cibilhometab ${
                breData.is_property_identified === tab.id ? "active" : ""
              }`}
              onClick={() => handleTabChange("is_property_identified", tab.id)}
            >
              <span>{tab.label}</span>
            </div>
          ))}
        </div>
        {breData.is_property_identified === "yes" && (
          <div className="loanofferallbox">
            <div className="row">
              <div className="col-sm-4">
                <InputField
                  type="text"
                  maxLength={12}
                  required={true}
                  name="expected_property_value"
                  id="expected_property_value"
                  placeholder="Expected Market Value"
                  label="Expected Market Value"
                  onChange={(e) =>
                    handleFieldChange(
                      "expected_property_value",
                      e.target.value,
                      true
                    )
                  }
                  value={handlePriceInputIndian(
                    breData.expected_property_value
                  )}
                />
                {errors?.expected_property_value && (
                  <div className="formerrorinput">
                    {errors.expected_property_value}
                  </div>
                )}
              </div>
              <div className="col-sm-4">
                <InputField
                  type="text"
                  maxLength={12}
                  name="property_agreement_value"
                  id="property_agreement_value"
                  placeholder="Property Registration Value"
                  label="Property Registration Value"
                  onChange={(e) =>
                    handleFieldChange(
                      "property_agreement_value",
                      e.target.value,
                      true
                    )
                  }
                  value={handlePriceInputIndian(
                    breData.property_agreement_value
                  )}
                />
              </div>
              <div className="col-sm-4">
                <label>Property Type</label>
                <Select
                  id="property_type"
                  onChange={(e) => handleFieldChange("property_type", e.value)}
                  options={ALL_OPTIONS["property_type"]}
                  name="property_type"
                  placeholder="Property Type"
                  value={
                    ALL_OPTIONS["property_type"].find(
                      (option) => option.value === breData.property_type
                    ) || ""
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <label>Property Sub-Type</label>
                <Select
                  id="property_sub_type"
                  onChange={(e) =>
                    handleFieldChange("property_sub_type", e.value)
                  }
                  options={ALL_OPTIONS["filtered_property_sub_type"]}
                  name="property_sub_type"
                  placeholder="Property Sub-Type"
                  value={
                    ALL_OPTIONS["filtered_property_sub_type"]?.find(
                      (option) => option.value === breData.property_sub_type
                    ) || ""
                  }
                />
              </div>
              {(breData.property_type == 9 || breData.property_type == 13) && (
                <div className="col-sm-4">
                  <label>Agreement Type</label>
                  <Select
                    id="agreement_type"
                    onChange={(e) =>
                      handleFieldChange("agreement_type", e.value)
                    }
                    options={ALL_OPTIONS["agreement_type"]}
                    name="agreement_type"
                    placeholder="Agreement Type"
                    value={
                      ALL_OPTIONS["agreement_type"].find(
                        (option) => option.value === breData.agreement_type
                      ) || ""
                    }
                  />
                </div>
              )}
              <div className="col-sm-4">
                <label>State</label>
                <Select
                  id="property_state"
                  onChange={(e) => {
                    setCityByStateChange(e.value);
                    handleFieldChange("property_state", e.value);
                  }}
                  options={ALL_OPTIONS["state_list"]}
                  name="property_state"
                  placeholder="Select State"
                  value={
                    ALL_OPTIONS["state_list"]?.find(
                      (option) => option.value === breData.property_state
                    ) || ""
                  }
                />
              </div>
            </div>
            <div className="row">
              {breData?.property_state && (
                <div className="col-sm-4" style={{ marginTop: 20 }}>
                  <label>City</label>
                  <Select
                    id="property_city"
                    onChange={(e) =>
                      handleFieldChange("property_city", e.value)
                    }
                    options={ALL_OPTIONS["city_list"]}
                    name="property_city"
                    placeholder="Select City"
                    value={
                      ALL_OPTIONS["city_list"]?.find(
                        (option) => option.value === breData.property_city
                      ) || ""
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div style={{ textAlign: "end" }}>
        <button className="loanbtnnext" onClick={handleViewOffer}>
          View Offer
        </button>
      </div>
    </>
  );
};

export default LoanOfferProperty;
