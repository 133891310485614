import React, { useEffect, useState } from "react";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import InputField from "../../view/elements/Input";
import SubPartnerKyc from "./SubPartnerKyc";
import MasterService from "../../service/MasterService";
import { toast } from "react-toastify";
import PageLoader from "../../view/elements/PageLoader";
import VerifyOtpModal from "./otp/verify-otp-popup";
import RadioBox from "../../../src/view/elements/Radiobox";
import { postApiUtility } from "../Utility/user.utility";
import EditIcon from "../NewLogin/Components/Icons/EditIcon";
import { DealerService } from "../../service";

const AddSubPartner = (props) => {
  const partnerDetails = props.formData;
  const [formData, setFormData] = useState({
    fullName: "",
    mobile: "",
    panCard: "",
    email: "",
    aadhar_no :"",
    pan : ""
  });
  const [partnerID, setPartnerID] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [showForm, setShowForm] = useState(true);
  const [showThankYou, setShowThankYou] = useState(false);
  const [showKYC, setShowKYC] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [pan, setPan] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpStatus, setOtpStatus] = useState(false);
  const [userData, setUserData] = useState("");
  const [dataFromPan, setDataFromPan] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const showOTP = 0;
  const [isPayoutApplicable, setIsPayoutApplicable] = useState("");
  const [sfa_user , setsfa_user] = useState("")
  const [disabledFields, setDisabledFields] = useState({
    fullName: false,
    mobile: false,
    panCard: false,
    email: false,

  });
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  useEffect(() => {
    const dealer_id_hash = localStorage.getItem("dealer_is_hash")
    const dealerId = loginUserInfo.user_data.dealer_id;

    setPartnerID(dealerId);

    fetchData(dealer_id_hash)
  }, []);
  const fetchData = async (id) => {
    let postdata = { dealer_id_hash: id, source : "saathi_web" ,_with: ["DIF"] };
    await DealerService.getBasicDetails(postdata).then((response) => {
      setsfa_user(response.data.data[0].sfa_referral_user_id)
    })
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    let newValue = value;
  
    if (name === "mobile") {
      if (!/^\d*$/.test(value) || value.length > 10) return;
    }
  
    if (name === "panCard") {
      newValue = value.toUpperCase();
      console.log("++++", newValue);
  
      if (newValue.length === 10) {
        validatePan(newValue);
      }
  
      setPan(newValue);
    } else if (name === "fullName") {
      setName(newValue);
    } else if (name === "mobile") {
      setMobile(newValue);
    } else if (name === "email") {
      setEmail(newValue);
    } else if (name === "is_payout_applicable") {
      setIsPayoutApplicable(newValue);
    }
  
    setFormData({ ...formData, [name]: newValue });
  };
  
  const validate = () => {
    let errors = {};

    if (!formData.fullName.trim()) {
      errors.fullName = "Full Name is required";
    }
    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile number is required";
    } else if (!/^[6-9][0-9]{9}$/.test(formData.mobile)) {
      errors.mobile =
        "Mobile number must start with 6, 7, 8, or 9 and be 10 digits long";
    }
    if (formData.panCard) {
     if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formData.panCard)) {
      errors.panCard = "Invalid PAN Card Number";
    }
  }
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email address";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const verifyOtp = (data) => {
    if (showOTP === 0) {
      // setShowForm(false);
      handleSubmit();
    } else {
      if (validate()) {
        setShowOtpModal(true);
        // setShowForm(false);
        setOtpStatus(data);
      }

      if (data == true) {
        // setShowOtpModal(false);
        handleSubmit();
      }
    }
  };

  const handleSubmit = async () => {
    if (validate()) {
      try {
        setIsSubmitDisabled(true);
        await RegisterPartner();
      } catch (error) {
        console.log("error", error);
        setIsSubmitDisabled(false);
      }
    }
  };

  const RegisterPartner = async () => {
    const reqData = {
      name: formData.fullName,
      mobile: formData.mobile,
      email: formData.email,
    
      user_type: "60",
      term_condition: true,
      parent_partner_id: partnerID,
      profession_id: 1,
      source: "Sub Partner Lead Creation",
      applicant_type_id: 1,
      step: 1,
      is_payout_ambak: isPayoutApplicable == "Yes" ? 1 : 0,
      updated_by: partnerID,
      aadhar_no:formData.aadhar_no,
      sfa_referral_user_id : sfa_user
      // sfa_referral_user_id : 
    };
    if (pan) {
      reqData.pan_no = pan;
      reqData.pan_number = pan;
      reqData.is_pan_verified = 1;
    }
    

    setLoading(true);
    try {
      const response = await MasterService.post(
        "/partner/partner/save-basic-details",
        reqData
      );

      if (response.data.status === 200) {
        setUserData(response.data.data.response);
        setShowForm(false);
        setShowThankYou(true);
      } else {
        toast.error(response.data.message[0]);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message[0]);
      } else {
        toast.error("An unexpected error occurred");
      }
    } finally {
      setLoading(false);
      setIsSubmitDisabled(false);
    }
  };
  const validatePan = async (pan_no) => {
    setLoading(true);
    let formData = {};
    formData.pan_no = pan_no;
    const validatePanResponse = await postApiUtility(
      "partner/partner/validate-PAN",
      {
        pan_number: pan_no,
        partner_id: partnerDetails?.id,
      }
    );
    if (validatePanResponse.data.status !== 200) {
      toast.error(validatePanResponse.data.error.pan_number.message);
      setLoading(false);
      return;
    }
    const panParams = {
      pan_card: pan_no,
      partner_id: formData.id,
    };
    if (validatePanResponse?.data?.data?.message === "New PAN") {
      const panData = await postApiUtility(
        "central-service/pancard/validate",
        panParams
      );
      if (panData.status !== 200) {
        toast.error(panData.data.message);
        setLoading(false);
      } else {
        toast.success("Pan Verified Successfully");
      }
      setDataFromPan(panData?.data?.data?.response);

      // Prefill the formData and disable the fields
      setFormData({
        fullName: panData?.data?.data?.response?.name || "",
        mobile: panData?.data?.data?.response?.mobile_no || "",
        panCard: panData?.data?.data?.response?.pan || pan_no,
        email: panData?.data?.data?.response?.email || "",
        aadhar_no : panData?.data?.data?.response?.maskedAadhaar || "",
        pan : panData?.data?.data?.response?.pan || ""
      });

      setDisabledFields({
        fullName: !!panData?.data?.data?.response?.name,
        mobile: !!panData?.data?.data?.response?.mobile_no,
        panCard: true, // Always disable after PAN validation
        email: !!panData?.data?.data?.response?.email,
        aadhar_no : !!panData?.data?.response?.email
      });

      setLoading(false);
    }
  };

  

  const KycShow = () => {
    // setShowKYC(true);
    // setShowThankYou(false);
    window.location.reload();
  };
  const handleOnEdit = () =>{

  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading === true && <PageLoader />}
      </div>

      {showForm && (
        <>
          <h4>Add Sub Partner</h4>
          <div className="addteammember">
            <img
              src={require(`../../../src/saathi-web/images/addteamicon.svg`)}
              alt="addteam"
              width={80}
            />
          </div>
          <div className="row">
          <div className="col-md-6">
      <InputField
        type="text"
        name="panCard"
        id="pan_card"
        placeholder="PAN Card Number"
        label={
          <>
            Pan Card Number
          </>
        }
        value={formData.panCard}
        onChange={handleChange}
        error={formErrors.panCard}
        maxLength="10"
        >
          {<EditIcon onPress={() => 
                  handleOnEdit()}
                  isValid={""}/>}
        </InputField>
        
    
    </div>
            <div className="col-md-6">
              <InputField
                type="text"
                name="fullName"
                id="full_name"
                placeholder="Full Name"
                label={
                  <>
                    Full Name<span style={{ color: 'red' }} className="error-msg-txt">*</span>
                  </>
                }
                value={formData.fullName}
                onChange={handleChange}
                error={formErrors.fullName}
                disabled={disabledFields.fullName}
              />
            </div>
            <div className="col-md-6">
              <InputField
                type="tel"
                name="mobile"
                id="mobile"
                placeholder="Mobile"
                label={
                  <>
                    Mobile<span style={{ color: 'red' }} className="error-msg-txt">*</span>
                  </>
                }
                maxLength="10"
                value={formData.mobile}
                onChange={handleChange}
                error={formErrors.mobile}
                // disabled={disabledFields.mobile}
              />
            </div>

            <div className="col-md-6">
              <InputField
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                label={
                  <>
                    Email Address<span style={{ color: 'red' }} className="error-msg-txt">*</span>
                  </>
                }
                value={formData.email}
                onChange={handleChange}
                error={formErrors.email}
                // disabled={disabledFields.email}
              />
            </div>

            <div className="col-md-6">
              <InputField
                type="text"
                name="aadhar_no"
                id="aadhar_no"
                placeholder="Aadhar Card Number"
                label="Aadhar Card Number"
                value={formData.aadhar_no || ""}
                onChange={handleChange}
                disabled={true}
              />
            </div>

            <div className="col-md-6" style={{ paddingBottom: 20 }}>
              <span>Is payout applicable</span>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {["Yes", "No"].map((type) => (
                  <div style={{ marginRight: 10, marginTop: 10 }} key={type}>
                    <RadioBox
                      type="radio"
                      name="is_payout_applicable"
                      id={type}
                      value={type}
                      label={type
                        .replace("_", " ")
                        .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())}
                      checked={isPayoutApplicable === type}
                      onChange={handleChange}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="newleadbtnflex">
            <button className="submitbtnnewlead" disabled={isSubmitDisabled} onClick={verifyOtp}>
              Submit
            </button>
          </div>
        </>
      )}
      {showThankYou && (
        <>
          <div className="newleadthankyou">
            <img
              src={require(`../../../src/saathi-web/images/thankyouimg.svg`)}
              alt="thankyou"
              width={300}
            />
            <h4 style={{ marginTop: 20 }}>Congratulations</h4>
            <h4>You have successfully added Sub Partner.</h4>
          </div>
          <div className="newleadbtnflex">
            <button className="submitbtnnewleadpartner" onClick={KycShow}>
              Go to Sub Partners
            </button>
          </div>
        </>
      )}
      {showKYC && (
        <>
          <SubPartnerKyc
            professions_list={props.professions_list}
            formData={formData}
            userList={userData}
          />
        </>
      )}
      {showOtpModal && (
        <VerifyOtpModal mobile={formData.mobile} verifyOtp={verifyOtp} />
      )}
    </>
  );
};

export default AddSubPartner;
