import React, { useState, useRef, useEffect } from "react";
import Modal from "../../view/common/Modal";
import DocumentPopup from "./DocumentPopup";
import DocumentDRESection from "./DocumentDRESection";
import {
  executeGraphQLMutationFinex,
  executeGraphQLQueryFinex,
} from "../../common/executeGraphQLQuery";
import { GET_DOCUMENTS_LIST_UPDATED } from "../../queries/sangam/leads.gql";
import {
  GET_DOC_REQUEST_LIST,
  SAVE_DOC_RECOMMENDED_LIST,
} from "../../queries/sangam/customer.gql";
import { toast } from "react-toastify";

const MANDATORY_DOC_COUNT = 2;

const PartnerDocument = ({ leadDetail, masterdata, stateUpdate }) => {
  const [activeTab, setActiveTab] = useState("partner_name");
  const [showPopup, setShowPopup] = useState(false);
  const [reqDocumentList, setReqDocumentList] = useState([]);
  const [addDocumentList, setAddDocumentList] = useState([]);
  const [recommendDocList, setRecommendDocList] = useState([]);
  const [existingReqDocList, setExistingReqDocList] = useState([]);

  const getDocumentList = async () => {
    try {
      const lead_id = leadDetail?.id;
      let customer_type = "customer";
      if (activeTab.indexOf("co") > -1) {
        customer_type = "co_applicant";
      }
      const obj = {
        property_type_id: leadDetail?.lead_details?.property_type,
        property_sub_type_id: leadDetail?.lead_details?.property_sub_type,
        profession_type_id: leadDetail?.lead_details?.profession,
        product_type_id: leadDetail?.loan_type,
        ...(leadDetail?.loan_sub_type
          ? { product_sub_type_id: leadDetail?.loan_sub_type }
          : {}),
      };

      const getDocListPromise = new Promise((resolve) =>
        resolve(executeGraphQLQueryFinex(GET_DOCUMENTS_LIST_UPDATED(obj)))
      );

      const reqDocListPromise = new Promise((resolve) =>
        resolve(
          executeGraphQLQueryFinex(GET_DOC_REQUEST_LIST(lead_id, customer_type))
        )
      );

      const [getDocListResponse, reqDocListResponse] = await Promise.all([
        getDocListPromise,
        reqDocListPromise,
      ]);

      const { customer_doc, co_applicant_doc, co_doc_list, doc_list } =
        getDocListResponse.data?.get_doc_list;

      let customerDoc = [],
        docList = [];

      if (customer_doc.length) {
        if (activeTab.indexOf("co") > -1) {
          customerDoc = co_applicant_doc;
          docList = co_doc_list;
        } else {
          customerDoc = customer_doc;
          docList = doc_list;
        }
      } else {
        customerDoc = doc_list
          .map((item) => {
            const filteredChildren = item.child.filter(
              (child) => child.is_required === "1"
            );
            if (filteredChildren.length > 0)
              return { ...item, child: filteredChildren };
            return null;
          })
          .filter((item) => item !== null);
      }
      const existingReqDocList = reqDocListResponse.data?.get_doc_request_list;
      if (existingReqDocList.length !== MANDATORY_DOC_COUNT) {
        const filteredRecommendDocs = docList
          .map((doc) => {
            const listOfDoc = doc.child.filter((child) =>
              existingReqDocList.some(
                (recDoc) =>
                  child.id === recDoc.doc_id &&
                  child.parent_id === recDoc.parent_doc_id &&
                  customer_type === recDoc.customer_type
              )
            );
            return {
              ...doc,
              child: listOfDoc,
            };
          })
          .filter((doc) => doc.child.length > 0);

        const filteredOtherDocs = docList
          .map((doc) => {
            const listOfDoc = doc.child.filter(
              (child) =>
                !existingReqDocList.some(
                  (recDoc) =>
                    child.id === recDoc.doc_id &&
                    child.parent_id === recDoc.parent_doc_id &&
                    customer_type === recDoc.customer_type
                )
            );
            return {
              ...doc,
              child: listOfDoc,
            };
          })
          .filter((doc) => doc.child.length > 0);

        const mergedDocs = [];
        customerDoc.forEach((existing) => {
          const matchingFilteredDoc = filteredRecommendDocs.find(
            (filtered) => filtered.id === existing.id
          );
          if (matchingFilteredDoc) {
            const mergedChildren = [
              ...existing.child,
              ...matchingFilteredDoc.child,
            ];
            mergedDocs.push({ ...existing, child: mergedChildren });
          } else {
            mergedDocs.push(existing);
          }
        });

        filteredRecommendDocs.forEach((filtered) => {
          const matchingExistingDoc = customerDoc.find(
            (existing) => existing.id === filtered.id
          );

          if (!matchingExistingDoc) {
            mergedDocs.push(filtered);
          }
        });
        customerDoc = mergedDocs;
        docList = filteredOtherDocs;
      }
      setReqDocumentList(customerDoc);
      setAddDocumentList(docList);
      setExistingReqDocList(existingReqDocList);
    } catch (error) {
      console.log(error.message);
      toast.error("Internal Server Error");
    }
  };

  useEffect(() => {
    getDocumentList();
  }, [activeTab]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const DocumnetPopupShow = () => {
    setShowPopup(!showPopup);
    getDocumentList();
    if (!showPopup) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const handleSetRecommendDocList = (list) => {
    setRecommendDocList(list);
  };

  const handleSaveRecommendDocList = async () => {
    try {
      const lead_id = leadDetail?.id;
      const recDocListClone = [...recommendDocList];
      const customer = {
          customer_id: +leadDetail?.customer?.customer_id,
        },
        custItems = [],
        coapplicant = [];
      const doc = recDocListClone?.map((obj) => {
        const coAppItems = [];
        if (obj?.customer_type === "customer") {
          custItems.push({
            doc_id: obj?.doc_id,
            parent_doc_id: obj?.parent_doc_id,
            recommended: obj?.is_recommended,
          });
        } else {
          const finalObj = {};
          coAppItems.push({
            doc_id: obj?.doc_id,
            parent_doc_id: obj?.parent_doc_id,
            recommended: obj?.is_recommended,
          });
          finalObj["id"] = obj?.customer_id;
          finalObj["items"] = coAppItems;
          coapplicant.push(finalObj);
        }
        return obj;
      });
      customer["items"] = custItems;

      const variables = {
        saveDocRecommendedList: {
          lead_id,
          coapplicant,
          customer,
        },
      };
      const response = await executeGraphQLMutationFinex(
        SAVE_DOC_RECOMMENDED_LIST,
        variables
      );
      if (response.data?.save_doc_recommended_list === "SUCCESS") {
        toast.success("Success");
        getDocumentList();
      } else {
        toast.error(`${response.data}`);
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="documentcard">
        <div className="documenttabs">
          <div className="doctabswidth">
            <div className="commontabs">
              <div>
                <div className="partnername">Primary</div>
                <div
                  className={
                    activeTab === "partner_name"
                      ? "commontabsItem active"
                      : "commontabsItem"
                  }
                  onClick={() => handleTabClick("partner_name")}
                >
                  <div className="newtabContent">
                    {leadDetail?.customer?.first_name}
                  </div>
                </div>
              </div>

              {leadDetail?.co_applicant?.length
                ? leadDetail?.co_applicant?.map((ob, key) => {
                    return (
                      <div>
                        <div className="coapplicantname">Co-Applicant 1</div>
                        <div
                          className={
                            activeTab === "co-" + ob.id
                              ? "commontabsItem active"
                              : "commontabsItem"
                          }
                          onClick={() => handleTabClick("co-" + ob.id)}
                        >
                          <div className="newtabContent">
                            {" "}
                            {ob.ca_first_name}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          {reqDocumentList.length <= MANDATORY_DOC_COUNT ? (
            <div>
              <button className="doclistbtn" onClick={DocumnetPopupShow}>
                Get Recommended Doc List
              </button>
            </div>
          ) : (
            <div>
              <button
                className="doclistbtn"
                onClick={handleSaveRecommendDocList}
              >
                Save Changes
              </button>
            </div>
          )}
        </div>

        <Modal isOpen={showPopup} togglePopup={DocumnetPopupShow} width={800} removeHeight={true}>
          <DocumentPopup
            leadDetail={leadDetail}
            masterdata={masterdata}
            stateUpdate={stateUpdate}
          />
        </Modal>

        <DocumentDRESection
          leadDetail={leadDetail}
          activeTab={activeTab}
          reqDocumentList={reqDocumentList}
          addDocumentList={addDocumentList}
          existingReqDocList={existingReqDocList}
          setRecommendDocList={handleSetRecommendDocList}
          stateUpdate={stateUpdate}
        />
      </div>
    </>
  );
};

export default PartnerDocument;
