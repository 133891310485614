import React from 'react';

function Modal({ isOpen, togglePopup, children, width, removeHeight,className }) {
  return (
    <>
      {isOpen && (
        <div className="modal-popup" onClick={togglePopup}>
          <div 
            className={`modal-context ${removeHeight ? 'modal-height' : ''} ${className? className :''}`} 
            style={{ width: width ? width : 'auto' }} 
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close" onClick={togglePopup}>&times;</span>
            {children}
          </div>
        </div>
      )}
    </>
  );
}

export default Modal;
