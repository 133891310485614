import React, { useContext, useEffect, useRef, useState } from "react";
import InputField from "../../../../view/elements/Input";
import Select from "react-select";
import { PartnerOnBordingContext } from "../../../PartnerContextApi/PartnerOnBordingContext";
import { toast } from "react-toastify";
import sfaService from "../../../../service/SfaService";

const CompanyInfo = () => {
  const { kycData, setKycData, professionList, error, setError, companyInfoRef } = useContext(PartnerOnBordingContext);

  useEffect(() => {
    companyInfoRef.current = { errorHandler };
  }, [companyInfoRef]);
  
  const errorHandler = (kycData) => {
    const {
      name,
      email,
      profession_name,
      company_type,
      spoc_name,
      spoc_email,
      spoc_mobile,
      spoc_designation,
      pincode,
      has_rm,
      sfa_user_id,
      has_rm_value
    } = kycData;
  
    let hasError = false;
    let newErrorState = {
      name: false,
      email: false,
      profession_name: false,
      company_type: false,
      spoc_name: false,
      spoc_email: false,
      spoc_mobile: false,
      spoc_designation: false,
      pincode: false,
      has_rm:false,
      sfa_user_id:false
    };
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[6-9]\d{9}$/;
    const nameRegex = /^[A-Za-z\s]+$/;

    if (!name || !nameRegex.test(name)) {
      toast.error("Name is required and should not contain numbers or special characters.");
      newErrorState.name = true;
      hasError = true;
    }

    if (!email || !emailRegex.test(email)) {
      toast.error("A valid email is required.");
      newErrorState.email = true;
      hasError = true;
    }

    if (!profession_name) {
      toast.error("Profession Type is required.");
      newErrorState.profession_name = true;
      hasError = true;
    }

    if (!company_type) {
      toast.error("Company Type is required.");
      newErrorState.company_type = true;
      hasError = true;
    }

    if (!spoc_name) {
      toast.error("Spoc name is required.");
      newErrorState.spoc_name = true;
      hasError = true;
    }

    if (!spoc_email || !emailRegex.test(spoc_email)) {
      toast.error("A valid Spoc email is required.");
      newErrorState.spoc_email = true;
      hasError = true;
    }

    if (!spoc_mobile || !mobileRegex.test(spoc_mobile)) {
      toast.error("Spoc mobile number is required and should start with 6, 7, 8, or 9 and have 10 digits.");
      newErrorState.spoc_mobile = true;
      hasError = true;
    }

    if (!spoc_designation) {
      toast.error("Spoc designation is required.");
      newErrorState.spoc_designation = true;
      hasError = true;
    }

    if (!pincode) {
      toast.error("Pincode is required.");
      newErrorState.pincode = true;
      hasError = true;
    }

    if (!has_rm_value) {
      toast.error("Selection of Relationship Manager Filed is required.");
      newErrorState.has_rm = true;
      hasError = true;
    }

    console.log("sfa_user_id:", sfa_user_id); // To check if sfa_user_id is truly undefined
    console.log("rmSelection:", rmSelection); // To check if rmSelection is true
    
    if (has_rm === true && !sfa_user_id) {
      toast.error("Selection of Relationship Manager (RM) is required.");
      newErrorState.has_rm = true;
      hasError = true;
    }
    
    if (hasError) {
      setError((prevError) => ({
        ...prevError,
        ...newErrorState,
      }));
      return hasError;
    }
  
    return false;
  };

  const handleChange = (event) => {
    if (event.profession_name) {
      setKycData((prevState) => ({
        ...prevState,
        profession_name: event.id,
      }));
    } else if (event.company_type) {
      setKycData((prevState) => ({
        ...prevState,
        company_type: event.id,
      }));
    } else if (event.spoc_designation) {
      setKycData((prevState) => ({
        ...prevState,
        spoc_designation: event.id,
      }));
    }
  };

  const handleInputChange = (event) => {
    const { id = "", value = "" } = event?.target;
    if (error.email) {
      setError({ ...error, email: false });
    }
    setKycData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const [rmSelection, setRmSelection] = useState(null);
  const [rmList, setRmList] = useState([])
  useEffect(()=>{
    getAllRmList()
  },[])

  const getAllRmList = async () => {
    const requestData = {
      role_id: [1, 2, 3, 4, 5, 6]
    }
    const rmData = await sfaService.getAllRMList(requestData);
    setRmList(rmData);
  }

  const selectedProfessionValue = professionList?.find(({ id }) => id === kycData?.profession_name) || null;

  const companyType = [
    { id: 1, company_type: "Pvt. Ltd." },
    { id: 2, company_type: "LLP" },
    { id: 3, company_type: "Proprietorship" },
    { id: 4, company_type: "Partnership" },
    { id: 5, company_type: "HUF" },
    { id: 6, company_type: "Pub. Ltd." },
    { id: 7, company_type: "Others" },
  ];
  const selectedCompanyType = companyType?.find(({ id }) => id === kycData?.company_type) || null;

  const spocDesignation = [
    { id: 1, spoc_designation: "Owner" },
    { id: 2, spoc_designation: "Co-owner" },
    { id: 3, spoc_designation: "Contact Person" },
  ];
  const selectedSPOCDesignation = spocDesignation?.find(({ id }) => id === kycData?.spoc_designation) || null;

  return (
    <>
      <div className="kycinfooverflow">
        <div className="row">
          <div className="col-md-12">
            <InputField
              type="text"
              name="name"
              id="name"
              placeholder="Company Name"
              label="Name"
              value={kycData?.name}
              onChange={handleInputChange}
              error={error?.name}
              required={true}
            />
          </div>
          <div className="col-md-12">
            <InputField
              type="text"
              name="email"
              id="email"
              placeholder="Email"
              label="Email"
              value={kycData?.email}
              onChange={handleInputChange}
              error={error?.email}
              required={true}
            />
          </div>
          <div className="col-md-6">
            <label>Profession Type <span className="error-msg-txt">*</span></label>
            <Select
              id="profession_id"
              name="profession_id"
              options={professionList}
              value={selectedProfessionValue}
              getOptionLabel={({ profession_name }) => profession_name}
              getOptionValue={({ id }) => id}
              onChange={handleChange}
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: error?.profession_name ? "red" : base.borderColor,
                }),
              }}
            />
          </div>
          <div className="col-md-6">
            <label>Company Type <span className="error-msg-txt">*</span></label>
            <Select
              id="company_type"
              name="company_type"
              options={companyType}
              value={selectedCompanyType}
              getOptionLabel={({ company_type }) => company_type}
              getOptionValue={({ id }) => id}
              onChange={handleChange}
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: error?.company_type ? "red" : base.borderColor,
                }),
              }}
            />
          </div>

          <div className="col-md-12" style={{ marginTop: 20 }}>
            <InputField
              type="text"
              name="spoc_name"
              id="spoc_name"
              placeholder="SPOC Name"
              label={
                <>
                  Company SPOC<span className="error-msg-txt">*</span>
                </>
              }
              value={kycData?.spoc_name}
              onChange={handleInputChange}
              error={error?.spoc_name}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <InputField
              type="text"
              name="spoc_email"
              id="spoc_email"
              placeholder="SPOC Email"
              label={
                <>
                  SPOC Email Id<span className="error-msg-txt">*</span>
                </>
              }
              value={kycData?.spoc_email}
              onChange={handleInputChange}
              error={error?.spoc_email}
            />
          </div>
          <div className="col-md-6">
            <InputField
              type="text"
              name="spoc_mobile"
              id="spoc_mobile"
              placeholder="SPOC Mobile"
              label={
                <>
                  SPOC Mobile No.<span className="error-msg-txt">*</span>
                </>
              }
              value={kycData?.spoc_mobile}
              onChange={handleInputChange}
              error={error?.spoc_mobile}
              maxLength={10}
            />
          </div>
          <div className="col-md-6">
            <label>SPOC Designation <span className="error-msg-txt">*</span></label>
            <Select
              id="spoc_designation"
              name="spoc_designation"
              options={spocDesignation}
              value={selectedSPOCDesignation}
              getOptionLabel={({ spoc_designation }) => spoc_designation}
              getOptionValue={({ id }) => id}
              onChange={handleChange}
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: error?.spoc_designation ? "red" : base.borderColor,
                }),
              }}
            />
          </div>
          <div className="col-md-12" style={{ marginTop: 20 }}>
            <InputField
              type="text"
              name="pincode"
              id="pincode"
              label={
                <>
                  Pin Code<span className="error-msg-txt">*</span>
                </>
              }
              placeholder="Pincode"
              value={kycData?.pincode}
              onChange={handleInputChange}
              error={error?.pincode}
            />
          </div>
          <div className="col-md-12" style={{ marginTop: 16 }}>
                  <label>
                    Do you have a relationship manager at Ambak?
                    <span style={{ color: 'red' }} className="error-msg-txt">*</span>
                  </label>
                  <div className="myteamsflex" style={{marginTop:10}}>
                    <div style={{display:"flex",alignItems:"center"}}>
                      <input
                        type="radio"
                        name="has_rm"
                        value="yes"
                        checked={rmSelection === true}
                        required={true}
                        onChange={(e) => {
                          setRmSelection(true);
                          setError((prevError) => ({ ...prevError, has_rm: false }));
                          setKycData((prevState) => ({ ...prevState, has_rm: true,sfa_user_id:undefined, has_rm_value : 1 }));
                        }}
                      />
                        <label  style={{ marginLeft: '10px' }}>Yes</label>
                        </div>
                        <div style={{display:"flex",alignItems:"center",marginLeft: '20px'}}>
                      <input
                        type="radio"
                        id="sfa_user_id"
                        name="sfa_user_id"
                        value="59"
                        checked={rmSelection === false}
                        required={true}
                        onChange={(e) => {
                          setRmSelection(false);
                          setError((prevError) => ({ ...prevError, has_rm: false }));
                          setKycData((prevState) => ({ ...prevState, has_rm: false , sfa_user_id: 59  , has_rm_value :1}));
                        }}
                      />
                       <label style={{ marginLeft: '10px' }}>No</label>
                    </div>
                  </div>
                </div>
                {rmSelection && (
                    <>
                      <div className="col-md-12" style={{ marginTop: 20 }} isRequired={true}
                      >
                        <label>
                          Name of Relationship Manager
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Select
                          id="sfa_user_id"
                          name="sfa_user_id"
                          options={rmList?.map((rm) => ({
                            value: rm.id,
                            label: `${rm.name} - ${rm.mobile ? '****' + rm.mobile.slice(-4) : 'N/A'}`,
                          }))}
                          value={
                            rmList?.find((rm) => rm.id === kycData?.sfa_user_id)
                              ? {
                                value: rmList.find((rm) => rm.id === kycData?.sfa_user_id).id,
                                label: `${rmList.find((rm) => rm.id === kycData?.sfa_user_id).name} - ${rmList.find((rm) => rm.id === kycData?.sfa_user_id).mobile ? '****' + rmList.find((rm) => rm.id === kycData?.sfa_user_id).mobile.slice(-4) : 'N/A'}`,
                              }
                              : null
                          }
                          onChange={(selectedRm) => {
                            setError((prevError) => ({ ...prevError, sfa_user_id: false }));
                            setKycData((prevState) => ({
                              ...prevState,
                              sfa_user_id: selectedRm.value,
                            }));
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              borderColor: error?.sfa_user_id ? "red" : base.borderColor,
                            }),
                          }}
                          placeholder="Select Relationship Manager"
                          isRequired={true}
                        />

                        {error?.has_rm}
                      </div>
                    </>
                  )}
        </div>
      </div>
    </>
  );
};

export default CompanyInfo;
