import React from 'react'
import SchemeReward from './SchemeReward'
import SchemeTermsAndConditions from './SchemeTermsCondition'

const PayoutSchemeDetails = ({ setCurrentScheme, currentScheme, bankList }) => {
  const getRangeLabel=(amount)=> {
    const newAmount = parseInt(amount)        
    // if (newAmount >= 1000000000) return "100cr";
    if (newAmount >= 900000000) return "90cr";
    if (newAmount >= 800000000) return "80cr";
    if (newAmount >= 700000000) return "70cr";
    if (newAmount >= 600000000) return "60cr";
    if (newAmount >= 500000000) return "50cr";
    if (newAmount >= 400000000) return "40cr";
    if (newAmount >= 300000000) return "30cr";
    if (newAmount >= 200000000) return "20cr";
    if (newAmount >= 100000000) return "10cr";
    if (newAmount >= 90000000) return "9cr";
    if (newAmount >= 80000000) return "8cr";
    if (newAmount >= 70000000) return "7cr";
    if (newAmount >= 60000000) return "6cr";
    if (newAmount >= 50000000) return "5cr";
    if (newAmount >= 40000000) return "4cr";
    if (newAmount >= 30000000) return "3cr";
    if (newAmount >= 20000000) return "2cr";
    if (newAmount >= 10000000) return "1cr";
    return newAmount || "0"; // Default case if amount is below the lowest range
  };
  return (

    <div className="payout-scheme-box-first">
      <div className="scheme-main-card">
        <div className='scheme-detail-heading mb-20'><img style={{ cursor: "pointer" }} src={require('../../images/left arrow.svg')} onClick={() => setCurrentScheme(null)} /> Scheme Details</div>
        {currentScheme.schemefinacergroup.length && currentScheme.schemefinacergroup.map((item, index) => {
          return <React.Fragment key={index}>
            <div className='scheme-detail-card'>
              <div className='scheme-detail-card-heading'>Category {index + 1}</div>
              <div>{item?.products == "" ? "All" : item.products}</div>
            </div>
            <div className='scheme-detail-card-category-one'>
              <div>
                {
                  item?.schemerange?.length && item?.schemerange.map((schemeBox, idx) => <div className='inner-scheme-card' key={idx}>
                    <div>
                      <div>{item?.schemerange.length==1?"Any Volume":item?.schemerange.length-1==idx?`>${getRangeLabel(schemeBox.start_range)}`:`${getRangeLabel(schemeBox.start_range)}-${getRangeLabel(schemeBox.end_range)}`}</div>
                      <div className='slab'>slab</div>
                    </div>
                    <div>
                      <div>{schemeBox.payout}%</div>
                      <div className='slab'>Total Rewards</div>
                    </div>
                  </div>)
                }
              </div>
              <div className='scheme-detail-category-two'>
                {item?.schemebanks?.length && item?.schemebanks.map(banks => {
                  const currentBank = bankList.find(bankDetails => bankDetails.id === banks.financer_id)
                  return <div key={banks.financer_id} className='scheme-bank-card'>
                    <img src={currentBank?.logo} width={"30px"} height={"45px"} />
                    <div>{currentBank?.banklang?.bank_name}</div>
                  </div>
                })}

              </div>
            </div>
            <div className='scheme-detail-reward'>
              {item?.sub_partner_ranges && <SchemeReward getRangeLabel={getRangeLabel} subPartnerData={item.sub_partner_ranges} schemeRange={item.schemerange} />}
            </div>
          </React.Fragment>
        })}
        <div>
          <div>
            {currentScheme?.scheme_tnc && <SchemeTermsAndConditions htmlContent={currentScheme.scheme_tnc} />}
          </div>
          <hr />
          <h2 className='scheme-thank-head'>Thank You!</h2>
          <img className='scheme-thank-image' src={require("../../images/thank-you.png")} />
          <h2 align="center">Contact Us!</h2>
          <br />
          <br />
          <div className='scheme-thank-footer'>
            <div><img src={require(`../../images/website.svg`)} /> <span>www.ambak.com</span></div>
            <div><img src={require(`../../images/whatsapp.svg`)} /> <span>805-805-8009</span></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PayoutSchemeDetails