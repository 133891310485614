import React, { useEffect, useState } from "react";
import Select from "react-select";
import { handlePriceInputIndian } from "../../../MyLeads/helpers/helpers";
import InputField from "../../../../view/elements/Input";

const NO_OF_BANKS = [
  { id: 1, value: 1, label: 1 },
  { id: 2, value: 2, label: 2 },
  { id: 3, value: 3, label: 3 },
  { id: 4, value: 4, label: 4 },
  { id: 5, value: 5, label: 5 },
];

const ACCOUNT_TYPES = [
  { id: "saving", value: "saving", label: "Saving" },
  { id: "current", value: "current", label: "Current" },
];

const LoanOfferBanking = ({
  breData,
  handleBreDataChange,
  customerType = "customer",
  coApplicantOfferDetails = [],
  coApplicantNoOfAccounts = 0,
  coAppIndex = null,
  errors = {},
  setErrorFields = () => null,
}) => {
  const [bankAccountFields, setBankAccountFields] = useState([]);

  useEffect(() => {
    let bankDetails = [],
      noOfAcc = 0;
    if (customerType === "customer") {
      bankDetails = breData.offer_bank_details || [];
      noOfAcc = breData.no_of_accounts || 0;
    } else {
      bankDetails = coApplicantOfferDetails;
      noOfAcc = coApplicantNoOfAccounts;
    }
    const obj = {};
    for (let i = 0; i < bankDetails.length; i++) {
      obj[`bank_account_type_${i}`] = bankDetails[i].account_type;
      obj[`bank_account_avg_amount_${i}`] = bankDetails[i].amount;
      obj[`bank_details_id_${i}`] = bankDetails[i].id;
    }
    if (noOfAcc > 0) {
      addBankAccountsFields(noOfAcc);
    }
    handleBreDataChange(null, null, obj);
  }, [coAppIndex, customerType]);

  const addBankAccountsFields = (noOfAccounts) => {
    const fields = [];
    for (let i = 0; i < noOfAccounts; i++) {
      const dropDownObj = {
        id: `bank_account_type_${i}`,
        name: `bank_account_type_${i}`,
        type: `dropdown`,
      };
      const inputObj = {
        id: `bank_account_avg_amount_${i}`,
        name: `bank_account_avg_amount_${i}`,
        type: "number-format",
      };
      fields.push([dropDownObj, inputObj]);
    }
    setBankAccountFields(fields);
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      if (field_name === "no_of_accounts") {
        addBankAccountsFields(data.value);
      }
      setErrorFields(field_name, "");
      handleBreDataChange(field_name, data.value);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setErrorFields(name, "");
    handleBreDataChange(name, value.replace(/[^0-9]/g, ""));
  };

  let noOfAccounts = "";
  if (customerType === "customer") {
    noOfAccounts = breData.no_of_accounts || 0;
  } else {
    noOfAccounts = coApplicantNoOfAccounts;
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-4" style={{ marginBottom: 20 }}>
          <label>
            Banking Income<span style={{ color: "red" }}>*</span>
          </label>
          <Select
            id="no_of_accounts"
            name="no_of_accounts"
            onChange={(data) => handleSelectChange(data, "no_of_accounts")}
            options={NO_OF_BANKS}
            placeholder="No of accounts"
            value={NO_OF_BANKS.find((option) => option.value === noOfAccounts)}
          />
          {errors?.no_of_accounts && (
            <div className="formerror">{errors.no_of_accounts}</div>
          )}
        </div>
      </div>

      {/* BANK ACCOUNTS FIELDS */}
      {bankAccountFields.map((data, bankIndex) => (
        <div className="bank-account-entry" key={bankIndex}>
          <div className="allheadingincome">Bank Account {bankIndex + 1}</div>
          <div className="row">
            {data?.map((field, index) =>
              field.type === "dropdown" ? (
                <div className="col-sm-4">
                  <label>Account Type</label>
                  <Select
                    id={field.id}
                    name={field.name}
                    onChange={(data) => handleSelectChange(data, field.name)}
                    options={ACCOUNT_TYPES}
                    placeholder="Select Account Type"
                    value={ACCOUNT_TYPES.find(
                      (option) =>
                        option.value ===
                        (customerType === "customer"
                          ? breData?.[field.name]
                          : breData.co_applicant[coAppIndex]?.[field.name])
                    )}
                  />
                </div>
              ) : (
                <div className="col-sm-4">
                  <InputField
                    type="text"
                    maxLength={12}
                    name={field.name}
                    id={field.id}
                    onChange={handleInputChange}
                    placeholder="Amount"
                    label="Amount"
                    value={handlePriceInputIndian(
                      customerType === "customer"
                        ? breData?.[field.name]
                        : breData.co_applicant[coAppIndex]?.[field.name]
                    )}
                  />
                </div>
              )
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default LoanOfferBanking;
