import React, { Component } from "react";
import Button from '../../elements/Button'
import MasterService from './../../../service/MasterService';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

class MakeBumpUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carId: props.carId,
            view_history: []
        }
    }
    componentDidMount = () => {
        this.getViewHistory();
    }
    getViewHistory() {
        var thisObj = this;
        var postObj = { car_id: this.state.carId };
        MasterService.post('inventory/inventory/get_stock_update_history', postObj)
            .then(function (response) {
                if (response.data.status == 200) {
                    if (response.data.data.length) {
                        thisObj.setState({ view_history: response.data.data });
                    }
                }
                else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    render() {
        console.log("view_history", this.state.view_history);
        let view_history = this.state.view_history;
        return (
            <div>
                <div className="clearfix">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>{this.props.t('viewHistory.id')}</th>
                                    <th>{this.props.t('viewHistory.car_id')}</th>
                                    <th>{this.props.t('viewHistory.change_lable')}</th>
                                    <th>{this.props.t('viewHistory.change_value')}</th>
                                    <th>{this.props.t('viewHistory.source_id')}</th>
                                    <th>{this.props.t('viewHistory.sub_source')}</th>
                                    <th>{this.props.t('viewHistory.updated_by')}</th>
                                    <th>{this.props.t('viewHistory.updated_by_time')}</th>
                                    <th>{this.props.t('viewHistory.id_address')}</th>
                                    <th>{this.props.t('viewHistory.change_time')}</th>
                                </tr>
                            </thead>


                            <tbody>
                                {
                                    view_history.map((history) => {
                                        const { id, usedcar_id, changed_label, changed_value, source_id, sub_source, updated_by, updated_by_type, changed_ipaddress, changed_time } = history //destructuring
                                        return (
                                            <tr key={id}>
                                                <td>{id}</td>
                                                <td>{usedcar_id}</td>
                                                <td>{changed_label}</td>
                                                <td>{changed_value}</td>
                                                <td>{source_id}</td>
                                                <td>{sub_source}</td>
                                                <td>{updated_by}</td>
                                                <td>{updated_by_type}</td>
                                                <td>{changed_ipaddress}</td>
                                                <td>{changed_time}</td>
                                            </tr>
                                        )

                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation('inventory')(MakeBumpUp);