import MasterService from './MasterService';

const InventoryService = {
    ...MasterService,
    
    getCarDetail(postdata) {
        return this.post('inventory/inventory/usedstockcardetails', postdata);
    },

    addStock(postdata) {
        return this.post('inventory/inventory/saveusedstockcar', postdata);
    },

    updateStock(postdata) {
        return this.post('inventory/inventory/updateusedstockcar', postdata);
    },

    uploadFilesToS3(postdata, headers) {
        return this.post('core/commonservice/docs_upload', postdata, headers);
    },

    saveUsedCarImages(postdata, headers) {
        return this.post('inventory/inventory/uploadstockusedcarimage', postdata, headers);
    },
    makeClassifedStockCond(postdata) {
        return this.post('inventory/inventory/make_classifed_new_stock_cond', postdata);
    },
    deleteAllUsedCarImages(postdata, headers) {
        return this.post('inventory/inventory/delete_all_images', postdata, headers);
    },
    
    cityList(postdata) {
        return this.get('core/commonservice/state_city_all');
    },
    localityList(city_object){
        return this.post('core/commonservice/locality_list',city_object);
    },

    colorList(postdata) {
        return this.get('core/commonservice/color_list');
    },
    checkAutoClassified(postdata) {
        return this.get('inventory/inventory/auto_classified_check', postdata);
    },
    deleteReason(postdata){
        return this.post('inventory/inventory/removeusedstockcar', postdata);        
    },

    stockStatusCount(postdata){       
        return this.post('inventory/inventory/countusedcarstatus', postdata);
    },
    onRoadCarPriceList(postdata){       
        return this.post('inventory/inventory/on_road_car_price', postdata);
    },
    getVersionDetail(postdata){       
        return this.post('inventory/inventory/get_version_detail', postdata);
    },
    colorList(postdata) {
        return this.get('core/commonservice/color_list');
    },
    tenureList(list) {
        return this.get('core/commonservice/master', { master: list });
    },
    provinceList() {
        return this.get('core/commonservice/province_list');
    }
}

export default InventoryService;
