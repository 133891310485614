import { gql } from "@apollo/client";


export const GET_CIBIL_DETAILS = gql`
mutation get_cibil_details($cibilDetailsInput: CibilDetailsInput!) {
    get_cibil_details(cibilDetailsInput: $cibilDetailsInput)
  }
`;

export const GET_DETAILS_BY_PANCARD_UPDATE = (panCard) => gql`
  query {
    get_details_by_pancard(pan_card: "${panCard}")
  }
`;

export const SEND_CONSIGNMENT_LINK =(user_id, lead_id) => gql`
mutation send_consignment_link($sendConsignmentLinkInput: CheckConsignmentInput!) {
    send_consignment_link(sendConsignmentLinkInput: $sendConsignmentLinkInput, user_id: ${user_id},lead_id: ${lead_id},)
  }
`;


export const CHECK_CIBIL_CONSIGNMENT = gql`
mutation check_cibil_consignment($checkConsignmentInput: CheckConsignmentInput!) {
    check_cibil_consignment(checkConsignmentInput: $checkConsignmentInput)
  }
`;



export const GENERATE_CIBIL_PDF = (panCard,lead_id) => gql`
  query {
    generate_cibil_report(pan_card: "${panCard}",lead_id:${lead_id})
  }
`;

export const GET_CIBIL_DETAILS_BY_ID=(lead_id)=>gql`
query{
  get_lead_detail(api_called_by:web,lead_id:${lead_id}){
    id
    partner_id
    assign_to
    is_login_verified
    is_sanction_verified
    reject_reason_id
    rejection_type
    is_disbursal_verified
    is_login_confirmation
    is_disbursed_confirmation
    partner_name
    created_by
    loan_type
    loan_sub_type
    tranche_type
    fulfillment_type
    followup_date
    followup_type
    followup_status 
    doc_status  
    status_id 
    sub_status_id
    last_sub_status_id
    is_qualified
    docs_collected
    rmdetails{
      label
      mobile
    }
    disbursement_done   
    co_applicant{
      id
      ca_mobile
      ca_email
      ca_dob
      ca_type
      same_as_cus_addr
      ca_first_name
      ca_last_name
      ca_gender
      ca_house_type
      ca_mother_name
      ca_father_name
      ca_nationality
      ca_qualification
      ca_pa_city
      ca_pa_house_number
      ca_pa_sameas_cra
      ca_pa_state
      ca_pa_street
      ca_pa_zipcode
      ca_pancard_no
      ca_alt_phone
      ca_aadhar_no
      ca_marital_status
      ca_existing_emi
      ca_existing_emi_amount
      ca_annual_income
      ca_profession
      ca_salary_credit_mode
      ca_company_name
      ca_is_itr_filled
      ca_is_form_16_filled
      ca_gross_monthly_salary
      relationship_with_customer
      ca_company_type
      ca_loan_amount
      ca_tenure
      ca_no_of_emi
      ca_property_type
      ca_property_value
      ca_usage_type
      ca_agreement_type
      ca_cra_pincode
      ca_cra_address1
      ca_cra_address2
      ca_cra_city
      ca_cra_state
      recommended_docs{
        id
        lead_id
        doc_id
        parent_doc_id
        is_doc_uploaded
        updated_by_source
        status
        doc_upload_url
        customer_type
        customer_id
        is_recommended
      }
      ca_monthly_salary
      ca_business_proof
      ca_gross_monthly_salary
      ca_is_additional_income
      ca_salutation
      ca_no_of_accounts
      ca_offer_type
      ca_emi_ending_six_month
      ca_additional_income{
        id
        lead_id
        customer_id
        customer_type
        income_type_id
        amount_type
        amount
        status
      }
      ca_is_obligation
      ca_obligation{
        id
        lead_id
        customer_id
        customer_type
        obligation_type_id
        emi_amount
        pending_emi_months
        status
      }
      ca_offer_itr_details{
        id
        lead_id
        is_itr_filled
        npat
        depreciation
        interest
        tax_paid
        itr_year
        customer_id
        customer_type
      }
      ca_offer_bank_details{
        id
        lead_id
        account_type
        amount
        customer_id
        customer_type
      }
      employment_details{
          ca_business_address
          ca_company_phone
          ca_designation
          ca_employer_name
          ca_gross_monthly_income
          ca_industry
          ca_year_with_company
          co_applicant_id
          }
          bank_details{
          ca_account_name
          ca_account_number
          ca_account_type
          ca_bank_id
          ca_branch_name
          co_applicant_id
          ca_branch_name
        }
      __typename
    }
    customer{
      customer_id
      mobile
      email
      pancard_no
      aadhar_no
      first_name
      last_name
      dob
      pa_pincode
      gender
      no_of_dependent
      pa_house_number
      pa_street
      pa_sameas_cra
      cra_house_number
      cra_city
      cra_state
      cra_street
      cra_pincode
      cra_address1
      cra_address2
      house_type
      father_name
      mother_name
      marital_status
      qualification
      duration_of_stay
      no_of_accounts
      business_vintage_years
      business_proof
      industry
      recommended_docs{
        id
        lead_id
        doc_id
        parent_doc_id
        is_doc_uploaded
        updated_by_source
        status
        doc_upload_url
        customer_type
        customer_id
        is_recommended
      }
      offer_type
      existing_emi_amount
      emi_ending_six_month
      is_additional_income
      salutation
      additional_income{
        id
        lead_id
        customer_id
        customer_type
        income_type_id
        amount_type
        amount
        status
      }
      is_obligation
      obligation{
        id
        lead_id
        customer_id
        customer_type
        obligation_type_id
        emi_amount
        pending_emi_months
        status
      }
      offer_itr_details{
        id
        lead_id
        is_itr_filled
        npat
        depreciation
        interest
        tax_paid
        itr_year
        customer_id
        customer_type
      }
      offer_bank_details{
        id
        lead_id
        account_type
        amount
        customer_id
        customer_type
      }
      employment{
        employer_name
        business_address
        company_phone
        designation
        industry
        gross_monthly_income
        year_with_company
        telephone_number
        __typename
      }
      bank_details {
        bank_id
        branch_name
        account_name
        account_type
        account_number
        __typename
      }
      __typename
    }
    
    lead_details{
      bank{
        id
        dsa_code
        banklang{
          bank_name          
        }
      }
      lead_id
      bank_id
      login_amount
      annual_income
      no_of_emi
      tenure
      loan_amount
      existing_emi
      company_name
      profession
      salary_credit_mode
      existing_emi_amount
      is_property_identified
      usage_type
      agreement_type
      property_type
      property_sub_type
      property_agreement_value
      expected_property_value
      property_value
      property_city
      property_state
      property_address1
      property_address2
      property_pincode
      login_number
      approval_date
      login_date
      loan_amount
      approved_emi
      approval_date
      approval_number
      approved_amount
      monthly_salary
      cibil_score
      is_calling
      call_status
      calling_tag
      company_type
      company_name
      is_itr_filled
      is_form_16_filled
      gross_monthly_salary
      work_experience
      calling_reason_id
      subvension_type_id
      subvension_amount
      cross_sell_type_id
      cross_sell_product_amount
      project_name
      builder_name_id
      check_oc_cc
      ready_for_registration
      gross_monthly_salary
      emi_ending_six_month
      expected_login_date
      expected_approved_date
      expected_disbursed_date
      lod_applied_date
      lod_apply
      offer_share_date
      house_item_value
      approved_roi
      is_offer
      offer_bank
      offer_loan_amount
      offer_roi
      __typename
    }
    checklists{
      id
      lead_id
      checklist_id
      sub_status_id
      checklist_complete_date
      checklist_sub_status_id
    }
    splitpayment{
      id
      lead_id
      product_sub_type
      disbursed_id
      disbursed_roi
      disbursed_date
      disbursed_amount
      disbursed_tenure
      disbursed_date
      is_tranch_verified
      transaction_done
      transaction_mode
      transaction_date
      transaction_id
      pdd_status
      pdd_remark
      pdd_date
      pay_in_done
      pay_in_confirm_mode
      pay_in_confirm_date
      pay_in_confirm_amount
      is_transaction_verified
      is_pdd_verified
      is_payout_verified
      is_pay_in_verified
      pay_in_confirm_id
      disbursed_roi
      is_tranch_confirmation
      is_transaction_confirmation
      is_pdd_confirmation
      __typename
    }
    lead_status_history{
      status_id
      sub_status_id
      __typename
    }
    status_info{
      statuslang{
        status_name
        __typename
      }
      __typename
    }
    sub_status_info {
      substatuslang {
        sub_status_name
        __typename
      }
      __typename
    }
    rmdetails{
      id
      label
      mobile
    }
    leaddocs{
      id
      lead_id
      doc_id
      parent_doc_id
      doc_path
      doc_status
      customer_type
      customer_id
      ext
      docmaster {
        
        __typename
      }
      __typename
    }
    insurance_lead_details{
      id
      ref_id
      insurance_url
      created_date
      last_updated_date
      insurance_type
      selected_premium
      content_sum_insured
    } 
  }
}
`
export const GET_LAST_CIBIL_INFO = (pan_card) => gql`
  query {
    last_cibil_info(pan_card: "${pan_card}")
  }
`; 


export const CREATE_LEAD_BY_PAN = () =>  gql`
mutation{
  create_lead_with_pancard(createLeadInput:{
   customer:{
       first_name: "final ramesh"
       last_name: "kumar fdf"
       mobile: "96549328830"
       pancard_no: "aaaaa8888d"
       dob: "12-12-2000"
   }
   lead_details:{
     loan_amount: "44444"
     profession: 1
     monthly_salary: "123456"
     annual_income: "333333"
   }
   loan_type: 2
   fulfillment_type:"self"
   loan_sub_type: "1,2"

 }, api_called_by: partner, partner_id: 183){
   id
   message
   customer{
     first_name
   }
 }
}
`

export const LEADS_QUERY = (filterData , partner_id , lead_id) => gql`
  query{
    leads(
      page_no: 1,
      user_id: ${lead_id},
      role_id: 1,
      api_called_by: partner,
      partner_id : ${partner_id}
      filter_params: {
        mobile: ${filterData.mobile},
        pancard_no: "${filterData.pan_card}"
      }
    ) {
      lead_list {
        id 
        fulfillment_type
        is_login_verified
        is_sanction_verified
        is_status_verified
        is_disbursal_verified
        created_date
        updated_date
        sub_status_id 
        followup_date
        followup_type
        loan_type
        loan_sub_type
        status_id
        partner_name
        followup_status 
        assign_to
        verifier_id         
        status_info {
          statuslang {
            status_name
          }
        }
        sub_status_info {
          substatuslang {
            sub_status_name
          }
        } 
        customer {
          first_name
          last_name
          mobile
        }
        co_applicant {
          ca_first_name
          ca_last_name
          ca_mobile
        }
        rmdetails {
          label
          mobile
        } 
        splitpayment {
          disbursed_date
          disbursed_amount
        }         
        lead_details {
          bank {
            id
            banklang {
              bank_name
            }
          }
          tenure
          login_date
          approval_date            
          loan_amount
          login_amount
          approved_amount
          annual_income
        }
      }
      pagination {
        page_no,
        totalrecords,
        nextpage,
        prevpage,
        totalpage
      } 
    }
  }
`;
