import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getNumberFormat_IN } from "../MyLeads/helpers/helpers";
import PageLoader from "../../view/elements/PageLoader";
import { toast } from "react-toastify";
import Modal from "../../view/common/Modal";
import NewLeadLoanOffer from "../my-tools/component/loanoffer/NewLeadLoanOffer";
import crypto from "../../config/crypto";
import { SAVE_LOAN_OFFER } from "../../queries/sangam/create.gql";
import { executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";

const MIN_TENURE_YEAR = 5;
const MIN_LOAN_AMOUNT = 500000;

const BankOfferList = ({
  bankOffer,
  recalculateOffer,
  loading,
  leadDetail,
  shortlistedOffers,
  newLeadId = null,
}) => {
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [checkedOffers, setCheckedOffers] = useState([]);
  const [showView, setShowView] = useState(false);
  const [ShowAllFees, setShowAllFees] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [leadId, setLeadId] = useState(null);
  const [bankDetails, setBankDetails] = useState([]);
  const [allFees, setAllFees] = useState({});
  const [loanDetails, setLoanDetails] = useState({});

  useEffect(() => {
    if (!bankOffer.length) return false;

    const listOfShortlistedOffer = [];
    const listOfMaxEligibalLoanAmount = bankOffer.map(
      (offer) => offer.maxEligibalLoanAmount
    );
    const listOfMaxLoanAmount = bankOffer.map((offer) =>
      offer.eligibalLoanAmount.replaceAll(",", "")
    );
    const listOfTenure = bankOffer.map((offer) => offer.finalMaxTenure);
    const listOfMaxTenure = bankOffer.map((offer) => offer.maxTenure);
    const maxEligibleLoan = Math.max(...listOfMaxEligibalLoanAmount);
    const maxLoan = Math.max(...listOfMaxLoanAmount);
    const maxTenure = Math.max(...listOfTenure);
    const tenure = Math.max(...listOfMaxTenure);

    for (let i = 0; i < bankOffer.length; i++) {
      const offer = bankOffer[i];
      const isShortlisted = shortlistedOffers.some(
        (obj) => obj.bank_id === offer.bankId
      );
      if (isShortlisted) listOfShortlistedOffer.push(offer);
    }

    setCheckedOffers(listOfShortlistedOffer);
    setLoanDetails((prev) => ({
      ...prev,
      maxEligibleLoanAmount: maxEligibleLoan,
      requiredLoanAmount: maxEligibleLoan,
      minLoanAmount: MIN_LOAN_AMOUNT,
      requiredTenure: maxTenure,
      maxLoan,
      tenure,
      maxTenure,
    }));
  }, [bankOffer, shortlistedOffers]);

  useEffect(() => {
    if (Object.keys(leadDetail).length || newLeadId) {
      setLeadId(leadDetail?.id || newLeadId);
    }
  }, [leadDetail, newLeadId]);

  const handleCheckboxChange = (item, isChecked) => {
    if (isChecked) {
      setCheckedOffers((prevCheckedOffers) => [...prevCheckedOffers, item]);
    } else {
      setCheckedOffers((prevCheckedOffers) =>
        prevCheckedOffers.filter((offer) => offer !== item)
      );
    }
  };

  const handleAmountSlider = (event) => {
    const { value } = event.target;
    setLoanDetails((prev) => ({
      ...prev,
      maxLoan: +value,
    }));
  };

  useEffect(() => {
    const rangeInputs = document.querySelectorAll(
      'input[type="range"].slider-progress'
    );
    rangeInputs.forEach((e) => {
      e.style.setProperty("--value", e.value);
      e.style.setProperty("--min", e.min === "" ? "0" : e.min);
      e.style.setProperty("--max", e.max === "" ? "100" : e.max);
      e.addEventListener("input", (event) => {
        e.style.setProperty("--value", event.target.value);
      });
    });
    return () => {
      rangeInputs.forEach((e) => {});
    };
  }, [loanDetails?.maxLoan]);

  const handleCopyOffer = async () => {
    let msgBody1 = "",
      msgBody2 = "";
    if (checkedOffers.length === 1) {
      msgBody1 = `Based on your profile and requirement, I have identified the best loan offer for you with ${checkedOffers[0].bankName} Bank. Please go through the details and note that this offer is subject to change basis the final lender policy.`;
    } else if (checkedOffers.length > 1) {
      msgBody1 = `These are the best suited loan offers for you based on your profile and requirements. Please go through the details & note that the offers are subject to change basis the final lender policy.`;
    }

    for (let i = 0; i < checkedOffers.length; i++) {
      const offer = checkedOffers[i];
      const msg = `
${offer.bankName} Bank
Loan amount: ${offer.eligibalLoanAmount}
Rate of Interest: ${offer.minRoi}
Tenure: ${offer.maxTenure}
EMI: ${offer.monthlyEMI}
Login Fee: ${offer.login_fee?.replace("Login Fee: ", "")}
Processing Fee: ${offer.processingText?.replace("Processing Fee: ", "")}`;
      msgBody2 += msg;
      msgBody2 += "\n\n";
    }

    const msgTemplate = `
Hi There,

${msgBody1}

${msgBody2}

Looking forward to your confirmation for taking the loan process ahead.

Regards,
${loginUserInfo.name}`;

    try {
      await navigator.clipboard.writeText(msgTemplate);
      toast.success("Copied!");
    } catch (error) {
      toast.error("Failed to copy offer");
    }
  };

  const togglePopup = () => {
    setShowView((prevShowView) => !prevShowView);

    if (!showView) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const toggleFessPopup = (index) => {
    const feesObj = {};
    const selectedOffer = bankOffer[index];
    feesObj["Processing Fees"] = selectedOffer?.processingText || "N/A";
    feesObj["Login Fee"] = selectedOffer?.login_fee || "N/A";
    feesObj["Account handling charges/Other Charges"] =
      selectedOffer?.ac_handling_charge || "N/A";
    feesObj["Legal and Technical Fee"] =
      selectedOffer?.legal_technical_fee || "N/A";
    feesObj["E-Stamp Charges"] = selectedOffer?.e_stamp_charge || "N/A";
    feesObj["Due Diligence Charges"] =
      selectedOffer?.due_diligence_charge || "N/A";
    feesObj["CERSAI Charges"] = selectedOffer?.CERSAI_charge || "N/A";
    feesObj["MODT Charges"] = selectedOffer?.MODT_charge || "N/A";
    feesObj["NOI Charges"] = selectedOffer?.NOI_charge || "N/A";
    setAllFees(feesObj);

    setShowAllFees((prevShowView) => !prevShowView);
    if (!ShowAllFees) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const toggleSuccessPopup = () => {
    setShowSuccess((prev) => !prev);

    if (!showSuccess) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const saveLoanOffer = async (bankData = [], lead_id) => {
    try {
      const data = bankData.map((obj) => {
        return {
          lead_id,
          roi: obj.minRoi,
          emi: +obj.monthlyEMI?.replaceAll(",", ""),
          loan_amount: +obj.eligibalLoanAmount?.replaceAll(",", ""),
          bank_id: obj.bankId,
          processing_fee: obj.processingText,
        };
      });
      const mutation = SAVE_LOAN_OFFER;
      const variables = {
        partner_id: loginUserInfo.user_data.dealer_id,
        api_called_by: "saathi_web",
        loanOfferInput: data,
      };

      const resp = await executeGraphQLMutationFinex(mutation, variables);
      if (!resp?.data?.save_loan_offer) throw new Error("Something went wrong");

      toggleSuccessPopup();
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const handleApplyOffer = (bankDetails) => {
    setBankDetails([bankDetails]);
    if (!Object.keys(leadDetail).length && !newLeadId) {
      return togglePopup();
    } else {
      saveLoanOffer([bankDetails], leadId);
    }
  };

  const handleSavetoLead = () => {
    if (leadId) {
      return saveLoanOffer(checkedOffers, leadId);
    }
    return togglePopup();
  };

  const handleSkipAndContinue = (data) => {
    togglePopup();
  };

  const handleLeadCreated = (lead_id) => {
    const offerList = bankDetails.length > 0 ? bankDetails : checkedOffers;
    setLeadId(lead_id);
    togglePopup();
    saveLoanOffer(offerList, lead_id);
  };

  const handleGoToLead = () => {
    window.location.href = `/saathi-details-page/${crypto.encode(leadId)}`;
  };

  const getMaxTenureOptions = (minYear, maxYear) => {
    const options = [];
    for (let i = minYear; i <= maxYear; i++) {
      options.push({
        id: i,
        label: `${i} Years`,
        value: i,
      });
    }
    return options;
  };

  const handleTenureChange = (data) => {
    setLoanDetails((prev) => ({
      ...prev,
      tenure: data.value,
    }));
  };

  const handleRecalculate = () => {
    const { maxLoan, tenure } = loanDetails;
    const reCalculate = {
      loan_amount: `${maxLoan}`,
      max_tenure: tenure,
    };
    recalculateOffer(reCalculate);
  };

  return (
    <>
      <div className="loantype">Curated Offers</div>
      {loanDetails?.requiredLoanAmount > 0 && (
        <>
          {/* <div className="bankofferselect"> */}
          {/* <div className="bankselectbox"> */}
          <div
          // style={{
          //   display: "flex",
          //   gap: "100px",
          //   marginBottom: "20px",
          //   width: "100%",
          // }}
          >
            {/* <div style={{ width: "80%", marginTop: "8px" }}>
                <div className="newloancomon">
                  <label>Desired Amount</label>
                  <div className="newmainamount">
                    <div>₹ {getNumberFormat_IN(loanDetails?.maxLoan)}</div>
                  </div>
                </div>

                <div className="range">
                  <input
                    type="range"
                    id="loan_amount"
                    name="loan_amount"
                    className="styled-slider slider-progress"
                    step="10000"
                    onChange={handleAmountSlider}
                    min={loanDetails?.minLoanAmount}
                    max={loanDetails?.maxEligibleLoanAmount}
                    value={loanDetails?.maxLoan}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <div>
                    <label>
                      ₹ {getNumberFormat_IN(loanDetails?.minLoanAmount)}
                    </label>
                  </div>
                  <div>
                    <label>
                      ₹ {getNumberFormat_IN(loanDetails?.maxEligibleLoanAmount)}
                    </label>
                  </div>
                </div>
              </div> */}

            {/* </div> */}
          </div>

          {/* <div className="banktipscard">
          <img
            src="https://ambak.storage.googleapis.com/partner/4486/1729775917444.png"
            alt="tips"
          />
          <div>
            Income allows for a higher loan of upto
            <span>
              {" "}
              ₹ {getNumberFormat_IN(loanDetails?.maxLoanAmount || 0)}
            </span>{" "}
            customer can increase their property budget!
          </div>
        </div> */}
          {/* </div> */}
          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-sm-2">
              <label>Tenure</label>
              <Select
                id="company_type"
                onChange={(data) => handleTenureChange(data)}
                options={getMaxTenureOptions(
                  MIN_TENURE_YEAR,
                  loanDetails?.maxTenure
                )}
                name="company_type"
                placeholder="Company Type"
                value={getMaxTenureOptions(
                  MIN_TENURE_YEAR,
                  loanDetails?.maxTenure
                ).find((obj) => obj.id == loanDetails.tenure)}
              />
            </div>
            <div
              className="col-sm-2 bankofferrecalculate"
              onClick={handleRecalculate}
              style={{ marginTop: "30px" }}
            >
              Recalculate
            </div>
          </div>
        </>
      )}
      {loading ? (
        <PageLoader loaderClass="loading" />
      ) : (
        <div>
          {bankOffer.map((item, index) => (
            <div className="bankoffercard">
              <div className="pcoffercard">
                <div className="offerpadding">
                  <div className="bankofferbox">
                    <div className="bankcheckbox">
                      <input
                        type="checkbox"
                        name="bank_check"
                        id="bank_check"
                        checked={checkedOffers.includes(item)}
                        onChange={(e) =>
                          handleCheckboxChange(item, e.target.checked)
                        }
                      />
                    </div>
                    <div className="banklogo">
                      <img
                        src={item.bankLogo}
                        alt="thankyou"
                        width={50}
                        height={20}
                      />
                      <span>{item.bankName}</span>
                    </div>
                  </div>
                  <div className="priceofferbox">
                    <div>
                      <div className="offerprice">
                        ₹ {getNumberFormat_IN(item.eligibalLoanAmount || 0)}
                      </div>
                      <div className="bankonwords">Loan Amount</div>
                    </div>
                    <div>
                      <div className="offerprice">
                        ₹ {getNumberFormat_IN(item.monthlyEMI || 0)}*
                      </div>
                      <div className="bankonwords">Monthly EMI</div>
                    </div>
                    <div>
                      <div className="offerprice">{item.minRoi}%*</div>
                      <div className="bankonwords">Rate of interest</div>
                    </div>
                    <div>
                      <div className="offerprice">{item.maxTenure} years</div>
                      <div className="bankonwords">Tenure</div>
                    </div>
                  </div>
                  <div className="offergst">
                    <span></span>
                    {item.processingText}
                  </div>
                </div>
                <div className="mobileonwordshide">*onwards</div>

                <div className="appylbtnborder">
                  <button
                    className="applybtn"
                    onClick={() => handleApplyOffer(item)}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
              <div className="offerfeeslist">
                <div className="offerpricelist">
                  <div className="processingfees">
                    <div className="offergstpc">
                      <span></span>
                      {item.processingText}
                    </div>
                    <div
                      style={{
                        color: "blue",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() => toggleFessPopup(index)}
                    >
                      View All Fees
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div style={{ fontSize: 12 }}>
        *All offers are subject to document proofs submitted and lender policies
        and approval.
      </div>

      {checkedOffers.length > 0 && (
        <div className="bankofferfixed">
          <div className="bankselected">
            <div style={{ width: "100%", padding: "0px 10px" }}>
              <span> {checkedOffers.length} Offers shortlisted</span>
            </div>
            <button
              className="selectedbankshare"
              onClick={handleSavetoLead}
              style={{ marginRight: "20px" }}
            >
              Save to Lead
            </button>
            <button className="selectedbankshare" onClick={handleCopyOffer}>
              Copy to Clipboard
            </button>
          </div>
        </div>
      )}

      <Modal isOpen={ShowAllFees} togglePopup={toggleFessPopup} width={700}>
        {Object.keys(allFees)?.map((feeKey, index) => (
          <div key={index}>
            <div className="offerlistingfees">
              <div
                className="feesheading"
                style={{ fontSize: 12, paddingBottom: 10 }}
              >
                <ul style={{ paddingLeft: "20px" }}>
                  <li style={{ listStyle: "initial" }}>
                    {feeKey} :{" "}
                    <span className="feessubheading">{allFees[feeKey]}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </Modal>

      <Modal
        isOpen={showView}
        togglePopup={togglePopup}
        width={900}
        removeHeight={true}
      >
        <NewLeadLoanOffer
          skipAndContinue={handleSkipAndContinue}
          leadCreated={handleLeadCreated}
        />
      </Modal>

      {showSuccess && (
        <Modal
          isOpen={showSuccess}
          togglePopup={toggleSuccessPopup}
          width={900}
          removeHeight={true}
        >
          <div className="newleadthankyou">
            <img
              src={require(`../../../src/saathi-web/images/thankyouimg.svg`)}
              alt="thankyou"
              width={300}
            />
            <h4>Offer Shortlisted!</h4>
            <p>
              Your preference for{" "}
              {checkedOffers.length > 0
                ? `${checkedOffers.length} Banks`
                : `${bankDetails[0]?.bankName} Bank`}{" "}
              has been saved.
            </p>
            <p>Go to the lead to continue to Login</p>
          </div>
          <div className="newleadbtnflex">
            {leadId && (
              <button className="submitbtnnewlead" onClick={handleGoToLead}>
                Go to Lead
              </button>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default BankOfferList;
