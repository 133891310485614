import React, { useContext, useEffect, useState } from "react";
import InputField from "../../../../view/elements/Input";
import RadioBox from "../../../../view/elements/Radiobox";
import Select from "react-select";
import { getUserInfo } from "../../../Utility/user.utility";
import CompanyInfo from "./CompanyInfo.component";
import { PartnerOnBordingContext } from "../../../PartnerContextApi/PartnerOnBordingContext";
import { toast } from "react-toastify";
import sfaService from "../../../../service/SfaService";

const UserInfoKyc = () => {
  const {
    kycData,
    setKycData,
    handleOnChange,
    professionList,
    error,
    setError,
    userInfoRef,
    setIsCompanyInfo,
  } = useContext(PartnerOnBordingContext);


  const isCompany =  kycData?.applicant_type !=="" ?  kycData?.applicant_type == 2 :  kycData?.applicant_of == "2";
  const userInfo = getUserInfo();
  const [selectedOption, setSelectedOption] = useState(isCompany ? "Company" : "Individual");
  const [rmSelection, setRmSelection] = useState(null);
  const [tartanname, settartanname] = useState(false)
  const [is_company, setIs_company] = useState(false)
  const [rmList, setRmList] = useState([])
  useEffect(() => {
    userInfoRef.current = { errorHandler };
  }, [userInfoRef]);

  useEffect(() => {
    getAllRmList()
    if (selectedOption === "Company") {
      setIsCompanyInfo(true);
      setRmSelection(null);
      setKycData((prevState) => ({ ...prevState, has_rm: undefined, sfa_user_id: undefined }));
    } else {
      setIsCompanyInfo(false);
      setRmSelection(null); 
      setKycData((prevState) => ({ ...prevState, has_rm: undefined, sfa_user_id: undefined }));
    }
    if (kycData.name) {
      settartanname(true)
    }
  }, [selectedOption]);

  const errorHandler = (kycData) => {
    const { email, profession_name, pincode, name, has_rm, sfa_user_id , has_rm_value} = kycData;
    let hasError = false;
    let newErrorState = { email: false, profession_name: false, pincode: false, name: false, has_rm: false ,sfa_user_id:false};

    if (!name || !/^[A-Za-z\s]+$/.test(name)) {
      toast.error("Valid name is required. Only alphabets and spaces are allowed.");
      newErrorState.name = true;
      hasError = true;
    }

    if (!email || !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      toast.error("Valid email is required.");
      newErrorState.email = true;
      hasError = true;
    }

    if (!profession_name) {
      toast.error("Profession Type is required.");
      newErrorState.profession_name = true;
      hasError = true;
    }

    if (!pincode || !/^\d{6}$/.test(pincode)) {
      toast.error("Valid pincode is required.");
      newErrorState.pincode = true;
      hasError = true;
    }

    if (!has_rm_value) {
      toast.error("Selection of Relationship Manager Filed is required.");
      newErrorState.has_rm = true;
      hasError = true;
    }

    console.log("sfa_user_id:", sfa_user_id); // To check if sfa_user_id is truly undefined
    console.log("rmSelection:", rmSelection); // To check if rmSelection is true
    
    if (has_rm === true && !sfa_user_id) {
      toast.error("Selection of Relationship Manager (RM) is required.");
      newErrorState.has_rm = true;
      hasError = true;
    }
    
    if (hasError) {
      setError((prevError) => ({ ...prevError, ...newErrorState }));
      return hasError;
    }

    return false;
  };

  const getAllRmList = async () => {
    const requestData = {
      role_id: [1, 2, 3, 4, 5, 6]
    }
    const rmData = await sfaService.getAllRMList(requestData);
    setRmList(rmData);
  }

  const handleChange = (event) => {
    
    if (event.profession_name) {
         setKycData((prevState) => ({
          ...prevState,
          profession_name: event.id,
          sfa_user_id:undefined
        }))
      } else {
        setSelectedOption(event.target.value);
        console.log("event.target.valueevent.target.value", event.target.value);
        if(event.target.value === 'Company') {
          setIsCompanyInfo(true);
          setIs_company(true)
          setKycData((prevState) => ({
            ...prevState,
            applicant_of: "2", 
            sfa_user_id: undefined, 
          }));
        } else {
          setIsCompanyInfo(false);
          setIs_company(false)
          setKycData((prevState) => ({
            ...prevState,
            applicant_of: "1", 
            sfa_user_id: undefined,
          }));
        }
      }
  };

  const handleInputChange = (event) => {
    const { id = "", value = "" } = event?.target;

    if (error[id]) {
      setError((prevError) => ({ ...prevError, [id]: false }));
    }

    setKycData((prevState) => ({ ...prevState, [id]: value }));
  };

  const maskName = (name) => {
    if (tartanname === false)
      return name
    if (name && name.length > 4) {
      return name[0] + "*****" + name.slice(-4);
    }
    return name;
  };

  const selectedProfessionValue = professionList?.find(({ id }) => id === kycData?.profession_name) || null;

  return (
    <>
      {professionList.length > 1 ? (
        <div>
          <div className="maininfokyc">
            <div>
              <img
                src="https://ambak.storage.googleapis.com/partner/1730/1720763140702.svg"
                alt="doc"
                width={100}
                height={100}
              />
            </div>
            <div>
              <div className="mainkycinput">
              </div>
              <div className="mobilenumbershow">
                +91 <span>{kycData.mobile ? kycData.mobile : userInfo.mobile}</span>
              </div>
            </div>
          </div>

          <div className="myteamsflex">
            <div style={{ marginRight: 10 }}>
              <RadioBox
                type="radio"
                name="kyc_add"
                id="Individual"
                value="Individual"
                label="Individual"
                checked={selectedOption === "Individual"}
                onChange={handleChange}
                disabled={kycData.applicant_type}
                readOnly={kycData.applicant_type}
              />
            </div>
            <div style={{ marginRight: 10 }}>
              <RadioBox
                type="radio"
                name="kyc_add"
                id="Company"
                value="Company"
                label="Company"
                checked={selectedOption === "Company"}
                onChange={handleChange}
                disabled={kycData.applicant_type}
                readOnly={kycData.applicant_type}
              />
            </div>
          </div>

          {selectedOption === "Individual" && (
            <>
              <div className="row">
                <div className="col-md-12">
                  <InputField
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    label="Name"
                    value={kycData.name ? maskName(kycData.name) : ""}
                    onChange={handleInputChange}
                    error={error?.name}
                    required={true}
                    // disabled={kycData.name ? true : false}
                  />
                </div>
                <div className="col-md-12">
                  <InputField
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Enter email id"
                    label="Email"
                    value={kycData?.email}
                    onChange={handleInputChange}
                    error={error?.email}
                    required={true}
                  />
                </div>
                <div className="col-md-12">
                  <label>Profession Type <span style={{ color: 'red' }} className="error-msg-txt">*</span></label>
                  <Select
                    id="profession_id"
                    name="profession_id"
                    options={professionList}
                    value={selectedProfessionValue}
                    getOptionLabel={({ profession_name }) => profession_name}
                    getOptionValue={({ id }) => id}
                    onChange={handleChange}
                    styles={{
                      control: (base) => ({
                        ...base,
                        borderColor: error?.profession_name ? "red" : base.borderColor,
                      }),
                    }}
                  />
                </div>

                <div className="col-md-12" style={{ marginTop: 20 }}>
                  <InputField
                    type="text"
                    name="pincode"
                    id="pincode"
                    placeholder="Enter pin code"
                    label={
                      <>
                        Pin Code<span style={{ color: 'red' }} className="error-msg-txt">*</span>
                      </>
                    }
                    value={kycData.pincode}
                    onChange={handleOnChange}
                    error={error?.pincode}
                  />
                </div>
                {/* <div className="col-md-12" style={{ marginTop: 20 }}> */}
                {/* <InputField
              type="text"
              name="sfa_referral_user_id"
              id="sfa_referral_user_id"
              label={
                <>
                  Yodha Code<span className="error-msg-txt">*</span>
                </>
              }
              placeholder="Yoddha Code"
              value={kycData?.sfa_referral_user_id}
              onChange={handleInputChange}
              error={error?.sfa_referral_user_id}
            /> */}
                {/* </div> */}
                <div className="col-md-12" style={{ marginTop: 16 }}>
                  <label>
                    Do you have a relationship manager at Ambak?
                    <span style={{ color: 'red' }} className="error-msg-txt">*</span>
                  </label>
                  <div className="myteamsflex" style={{marginTop:10}}>
                    <div style={{display:"flex",alignItems:"center"}}>
                      <input
                        type="radio"
                        name="has_rm"
                        value="yes"
                        checked={rmSelection === true}
                        required={true}
                        onChange={(e) => {
                          setRmSelection(true);
                          setError((prevError) => ({ ...prevError, has_rm: false }));
                          setKycData((prevState) => ({ ...prevState, has_rm: true ,sfa_user_id:undefined , has_rm_value : 1}));
                        }}
                      />
                        <label  style={{ marginLeft: '10px' }}>Yes</label>
                        </div>
                        <div style={{display:"flex",alignItems:"center",marginLeft: '20px'}}>
                      <input
                        type="radio"
                        id="sfa_user_id"
                        name="sfa_user_id"
                        value="59"
                        checked={rmSelection === false}
                        required={true}
                        onChange={(e) => {
                          setRmSelection(false);
                          setError((prevError) => ({ ...prevError, has_rm: false }));
                          setKycData((prevState) => ({ ...prevState, has_rm: false , sfa_user_id: 59 , has_rm_value :1}));
                        }}
                      />
                       <label style={{ marginLeft: '10px' }}>No</label>
                    </div>
                  </div>
                </div>
                {rmSelection && (
                    <>
                      <div className="col-md-12" style={{ marginTop: 20 }} isRequired={true}
                      >
                        <label>
                          Name of Relationship Manager
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Select
                          id="sfa_user_id"
                          name="sfa_user_id"
                          options={rmList?.map((rm) => ({
                            value: rm.id,
                            label: `${rm.name} - ${rm.mobile ? '****' + rm.mobile.slice(-4) : 'N/A'}`,
                          }))}
                          value={
                            rmList?.find((rm) => rm.id === kycData?.sfa_user_id)
                              ? {
                                value: rmList.find((rm) => rm.id === kycData?.sfa_user_id).id,
                                label: `${rmList.find((rm) => rm.id === kycData?.sfa_user_id).name} - ${rmList.find((rm) => rm.id === kycData?.sfa_user_id).mobile ? '****' + rmList.find((rm) => rm.id === kycData?.sfa_user_id).mobile.slice(-4) : 'N/A'}`,
                              }
                              : null
                          }
                          onChange={(selectedRm) => {
                            setError((prevError) => ({ ...prevError, sfa_user_id: false }));
                            setKycData((prevState) => ({
                              ...prevState,
                              sfa_user_id: selectedRm.value,
                            }));
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              borderColor: error?.sfa_user_id ? "red" : base.borderColor,
                            }),
                          }}
                          placeholder="Select Relationship Manager"
                          isRequired={true}
                        />
                        {error?.has_rm}
                      </div>
                    </>
                  )}
              </div>
            </>
          )}

          {selectedOption === "Company" && <CompanyInfo />}

          <div className="mainflexbg">
            <div className="mainkycflex">
              <img
                src="https://ambak.storage.googleapis.com/partner/1886/1720765123441.svg"
                alt="doc"
                width={20}
                height={20}
              />
              <div>Don't worry, your personal data is safe with us. You will not receive any spams.</div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UserInfoKyc;
