import React,{ useEffect, useState, useMemo, useCallback } from "react";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import FormFields from "../../../src/saathi-web/MyLeads/formFields/customerDetailsFormFields.json";
import GeneralService from "../../service/generalService";
import { SAVE_CUST_DETAILS } from "../../queries/sangam/customer.gql";
import { executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import Formsy, { addValidationRule } from 'formsy-react';
import FormsyInputField from '../../view/elements/FormsyInputField';
import FormsySelect from '../../view/elements/FormsySelect';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {DISBURSE_REJECTION_ID} from "../../config/constant";
import { GET_BUILDER_LIST, GET_PROJECT_LIST } from '../../queries/sangam/bre.gql';

const FORM_FIELDS = FormFields["PROPERTY_IDENTIFIED"];

addValidationRule("matchAccNo", (values, value) => {
  if (values.account_number) {
    return !values.re_account_number ? 'Please Re-Enter Account Number' : values.account_number !== values.re_account_number ? "Account No. does not match" : true
  }
  return true;
})

const PropertyDetails = ({leadDetail,masterdata,masterStatusData}) => {
  const params = useParams();
  const history = useHistory();
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  const [propertyIdentified, setPropertyIdentified] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [isPropertyIdentified, setIsPropertyIdentified] = useState("");  
  const [isFormTouch,setTouch] = useState({})
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const location = useLocation();
  
  const getBuilderList = async (key_name, value) => {
    try {
      let cityId = null,
        builderId = null;
      if (key_name === "builder") {
        cityId = +value;
        builderId = null;
      } else if (key_name === "project") {
        cityId = +propertyIdentified["property_city"] || null;
        builderId = +value;
      }
      const variables = {
        [`${key_name}Params`]: {
          ...(cityId ? { cityId } : {}),
          ...(builderId ? { builderId } : {}),
        },
      };
      const mutation =
        key_name === "builder" ? GET_BUILDER_LIST : GET_PROJECT_LIST;
      const resp = await executeGraphQLMutationFinex(mutation, variables);
      let optionList = [];
      if (resp.data?.[`get_${key_name}_name`]?.data) {
        const responseData = resp.data?.[`get_${key_name}_name`];
        optionList = responseData.data?.map((obj) => {
          return {
            id: obj[`${key_name}_id`],
            value: obj[`${key_name}_id`],
            label: obj[`${key_name}_name`],
          };
        });
        return optionList;
      } else {
        toast.error(resp.data?.[`get_${key_name}_name`]?.message);
      }
    } catch (error) {
      console.log(`🔻 ERROR : ${error.message}`);
      toast.error("Internal server error");
      return [];
    }
  };
  
  const setBuilderAndProjectList = async (key_name, value) => {
    if (leadDetail?.lead_details?.is_property_identified === "yes" && location.pathname.indexOf("property-identified") > -1) {
      const list = await getBuilderList(key_name, value);
      ALL_OPTIONS[`${key_name}_name`] = list;
    }
  };

  useEffect(() => {
    if (leadDetail && leadDetail?.lead_details) {
      let property_identified = { ...leadDetail?.lead_details };
      setPropertyIdentified(property_identified);
      setIsPropertyIdentified(property_identified["is_property_identified"] || "no");
      const filteredSubType = ALL_OPTIONS["property_sub_type"]?.filter(obj => SUB_PROPERTY_MAPPING_OBJ[property_identified["property_type"]]?.includes(obj.value));
      ALL_OPTIONS["filtered_property_sub_type"] = filteredSubType;

      if (property_identified?.property_city) {
        setBuilderAndProjectList("builder", property_identified.property_city);
      }
      if (property_identified?.builder_name_id) {
        setBuilderAndProjectList("project", property_identified.builder_name_id);
      }
    }
  }, [leadDetail]);

  useEffect(() => {
    const getCityStateList = async () => {      
      try {
        const response = await GeneralService.stateCityList();     
        if (response?.data?.status === 200) {  
          let result = { ...response.data.data }; 
          
          if(result.city && result.city.length){
            result.city  = result.city.map(data => ({
               ... data,
               value: data.id,
               label: data.name
            }));   
          }

          if(result.state && result.state.length){
            result.state  = result.state.map(data => ({
               ... data,
               value: data.id,
               label: data.name
            }));   
          }

          setCityList(result.city); 
          setStateList(result.state);         
        }
      } catch (error) {
        console.error("Failed to fetch city and state list", error);
      }
    };
    getCityStateList();    
  }, []); 

  useEffect(() => {
    if (propertyIdentified?.property_city) {
      setBuilderAndProjectList("builder", propertyIdentified?.property_city);
    }
  }, [propertyIdentified?.property_city]);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata } : {};
    const existingPropertyType = propertyIdentified["property_type"] || null;
    options["city_list"] = cityList;
    options["state_list"] = stateList;
    options["property_sub_type"] = options["property_sub_type"]?.map((obj) => ({
      ...obj,
      value: +obj.id,
    }));
    if (existingPropertyType) {
      const filteredSubType = options["property_sub_type"]?.filter((obj) =>
        SUB_PROPERTY_MAPPING_OBJ[existingPropertyType].includes(obj.value)
      );
      options["filtered_property_sub_type"] = filteredSubType;
    } 
    return options;
  }, [masterdata]);

  const SUB_PROPERTY_MAPPING_OBJ = useMemo(() => ({
    9: [1, 2, 3],
    11: [1, 4],
    12: [1, 4],
    13: [1, 2, 3, 4],
    14: [1, 2, 3, 4],
  }), []);
  

  const handleInputChange = useCallback((event) => {
    const { id, value } = event.target;
    setTouch(prev => ({ ...prev, [id]: true }));
    setPropertyIdentified(prev => ({ ...prev, [id]: value }));
    if (id === 'property_pincode' && value.length === 6) {
      setCityStateByPin(value);
    }
  }, []);



  const setCityStateByPin = async (pincode)=>{
    try {
      let response = await GeneralService.stateCityByPincode(pincode);
      if (response?.data?.data?.length) {
        let item = response.data.data[0]  
        setPropertyIdentified(propertyIdentified => ({
          ...propertyIdentified,
          property_pincode: pincode,
          property_state: item?.state_id,
          property_city: item?.city_id
        }))
      }  
    } catch (error) {
      console.error('Error :', error);
    }
  }

  const handlePatternFormatChange = (data, field_name) => {
    if (field_name && data.value !== undefined) {
      setTouch({
        ...isFormTouch,
        [field_name]:true
      })
      setPropertyIdentified(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleSelectChange = useCallback(async (data, field_name) => {    

    if (data && field_name) {
      if (field_name === "is_property_identified") {
        setIsPropertyIdentified(data.value);
      }
      if (field_name === "property_type") {
        const filteredSubType = ALL_OPTIONS["property_sub_type"]?.filter(obj => SUB_PROPERTY_MAPPING_OBJ[data.value]?.includes(obj.value));
        ALL_OPTIONS["filtered_property_sub_type"] = filteredSubType;
      }
      if (field_name === "property_city") {
        const list = await getBuilderList("builder", data.value);
        ALL_OPTIONS["builder_name"] = list;
      }
      if (field_name === "builder_name_id") {
        const list = await getBuilderList("project", data.value);
        ALL_OPTIONS["project_name"] = list;
      }
      setPropertyIdentified(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }, [ALL_OPTIONS, propertyIdentified]);

  const handleSubmit = (isExitPage = false) => {
    setIsSaveDisabled(true);
    let lead_id = leadDetail?.id || "";
    let mutation = SAVE_CUST_DETAILS,
      variables = {
        api_called_by: 'saathi_web',
        partner_id: loginUserInfo?.dealer_id,
        LeadAdditionalInput: {
          lead_id: lead_id,
          lead_details: {
            is_property_identified: propertyIdentified.is_property_identified,  
            property_value: propertyIdentified.property_value,
            property_type: +propertyIdentified.property_type,
            usage_type: +propertyIdentified.usage_type,
            agreement_type: +propertyIdentified.agreement_type, 
            property_pincode: propertyIdentified.property_pincode,
            property_city: propertyIdentified.property_city,
            property_state: propertyIdentified.property_state,
            property_address1: propertyIdentified.property_address1,
            property_address2: propertyIdentified.property_address2,
            property_agreement_value: propertyIdentified.property_agreement_value,
            property_sub_type: +propertyIdentified.property_sub_type,
            builder_name_id: propertyIdentified.builder_name_id,
            project_name: propertyIdentified.project_name,
            expected_property_value: propertyIdentified.expected_property_value,
          }
        }
      };

      executeGraphQLMutationFinex(mutation, variables).then(resp => {
      let response = resp?.data?.LeadAdditionalInput || null;
      if (response && !response.success) {
        setIsSaveDisabled(false);
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      setIsSaveDisabled(false);
      if (isExitPage) return history.push(`/saathi-details-page/${params.lead_id}`);
      //dispatch(getLoanDetail(lead_id, client))
    })
      .catch(err => {
        setIsSaveDisabled(false);
        toast.error(err.message);
      })
  }

  let PropertyIdentifiedForm = FORM_FIELDS;

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    let isPropIdentified = "no";
    if (checked) {
      isPropIdentified = "yes";
    }
    setIsPropertyIdentified(isPropIdentified);
    setPropertyIdentified((currentValue) => ({
      ...currentValue,
      [name]: isPropIdentified,
    }));
  }

  const rejectDisable = (leadDetail?.reject_reason_id > 0 && !DISBURSE_REJECTION_ID.includes(leadDetail?.reject_reason_id) && [2, 3].includes(leadDetail?.status_id)) ? true : false 
  const  IS_LOST = (leadDetail?.status_id ==5)? true: false

  return (
    <>
      <div>
        <h4>Property Details</h4>

        <div className="property-identified">
            <label>Property Identified ?</label>
            <label className="toggle-label">No</label>
            <label className="toggle-switch">
              <input
                type="checkbox"
                name="is_property_identified"
                id="is_property_identified"
                value="Yes"
                checked={isPropertyIdentified === "yes" ? true : false}
                onChange={handleCheckboxChange}
              />
              <span className="slider"></span>
            </label>
            <label className="toggle-label">Yes</label>
          </div>

          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={() => handleSubmit(false)}
          >
            <div className="row" style={{paddingBottom:40}}>

              {PropertyIdentifiedForm?.map((field,index) => (
                (isPropertyIdentified === "" || isPropertyIdentified === null && (field.name !== "is_property_identified")) ||
                (isPropertyIdentified === "no" && field.id === "property_aggrement_value") || 
                (isPropertyIdentified === "yes" && field.id === "expected_property_value") || 
                (isPropertyIdentified === "no" && field.id === "property_value") || 
                (propertyIdentified["property_type"] === 9 && field.name === "project_name") ||
                (propertyIdentified["property_type"] === 11 && field.name === "project_name") || 
                (propertyIdentified["property_type"] === null && field.name === "project_name") || 
                (isPropertyIdentified === "no" && field.id === "project_name") || 
                (propertyIdentified["property_type"] === 9 && field.name === "builder_name_id") ||
                (propertyIdentified["property_type"] === 11 && field.name === "builder_name_id") || 
                (propertyIdentified["property_type"] === null && field.name === "builder_name_id") || 
                (isPropertyIdentified === "no" && field.id === "builder_name_id") || 
                (isPropertyIdentified === "no" && field.id === "property_sub_type") || 
                (isPropertyIdentified === "no" && field.id === "property_address1") ||
                (isPropertyIdentified === "no" && field.id === "property_address2") ? null :
                ["text", "password", "pattern-format", "number-format","number"].includes(field.type) ? (
                  <fieldset className="form-filed col-md-6 " key={index}>
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={propertyIdentified && propertyIdentified[field.name] || ''}
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={','}
                      decimalSeparator={'.'}
                      label={field.label}
                      maxLength={field.maxLength}
                      onChange={
                        ["pattern-format", "number-format"].includes(field.type)
                          ? (data) => handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={(isFormTouch[field.name])? field.validations:null}
                      validationError={
                        propertyIdentified[field.name] || propertyIdentified[field.name] =='' ?  (isFormTouch[field.name])? field.validationError : "" : ""
                      }
                      required={field.required}
                    />
                  </fieldset>
                ) :

                  (isPropertyIdentified === "no" && field.id === "agreement_type") ? null :
                  field.type === "dropdown" ? (
                    <fieldset className="single-select col-md-6" key={index}>
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                         // options: getSelectList(ALL_OPTIONS,field.optionsKey),
                          options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                          placeholder: field.label,
                          className: "react-select",
                          classNamePrefix: "react-select",
                          value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey]?.filter(({ value }) => value == propertyIdentified[field.name]),
                          isDisabled: field.readOnly
                        }}
                        required={field.required}
                        value={propertyIdentified && propertyIdentified[field.name]}
                        onChange={(data) => handleSelectChange(data, field.name)}
                      />
                    </fieldset>
                  ) : null
              ))}

            </div>

            <div style={{ marginTop: 40 }}>
              <div className="saveandexit">
                <button
                  type="button"
                  className="markloatbrn"
                  onClick={() => handleSubmit(true)}
                >
                  Save & Exit
                </button>
                <button
                  type="submit"
                  className="editnextbtn1"
                  disabled={rejectDisable || IS_LOST || isSaveDisabled}
                >
                  Save
                </button>
              </div>
            </div>
          </Formsy>
       
        
        
      </div>

      
    </>
  );
};

export default PropertyDetails;
