import React, { useState, useEffect } from "react";
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import Modal from "../../view/common/Modal";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { UPDATE_LEAD_STATUS } from "../../queries/sangam/leads.gql";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { useParams } from "react-router-dom";
import Loader from "../../view/elements/PageLoader";
import { toast } from "react-toastify";
import crypto from "../../config/crypto";
import { getLeadRemarks } from "../../store/action/allAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import secureStorage from "../../config/encrypt";
import ActionTypes from "../../store/action/action";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import LostModal from "./LostModal";
import {
  executeGraphQLQueryFinex,
  executeGraphQLMutationFinex,
} from "../../common/executeGraphQLQuery";
//import { useNavigate } from "react-router-dom";

const LoanRibbenBox = (props) => {
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  const { leadDetail, masterdata, statuses } = props;
  const [rejectionType, setRejection] = useState("");
  const [MarkAsLost, setMarkAsLost] = useState(false);
  const [reopenModal, setReopenModal] = useState(false);
  const [remarkText, setRemarkText] = useState("");

  const params = useParams();
  let history = useHistory();
  let lead_id = crypto.decode(params.lead_id);

  let loan_type = masterdata?.loan_type;
  // navigate = useNavigate();

  class NameFormatter {
    capitalizeFirstLetter(word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }

    capitalizeEachWord(name) {
      return name?.split(" ").map(this.capitalizeFirstLetter).join(" ");
    }

    formatFirstName(firstName) {
      return this.capitalizeEachWord(firstName);
    }
  }
  const nameFormatter = new NameFormatter();

  const showModalMarkAsLost = (type) => {
    console.log(type);
    setRejection(type);
    setMarkAsLost(true);
  };
  const hideModalMarkAsLost = () => {
    setMarkAsLost(false);
  };

  const hideModalReopen = () => {
    setRemarkText("");
    setReopenModal(false);
  };

  useEffect(() => {
    //updateReduxData();
  });

  const updateReduxData = async () => {
    const loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse) {
      props.updateStateIntoRedux(ActionTypes.LOGIN_USER, loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return <Redirect to={"/login"} />;
    }
  };

  const afterLoginSetState = (loginResponse) => {
    const role = loginResponse?.data?.user_data?.role || null;
    if (role !== "dealercentral") {
      return <Redirect to={"/"} />;
    }
  };

  const showModalReopen = () => {
    setReopenModal(true);
  };

  const submitReopen = () => {
    if (!remarkText || /^\s*$/.test(remarkText)) {
      toast.error("Please enter remarks");
    } else {
      let mutation = UPDATE_LEAD_STATUS,
        variables = {
          api_called_by: "saathi_web",
          partner_id: loginUserInfo?.dealer_id,
          UpdateLeadStatus: {
            update_type: "reopen",
            status_id: [],
            lead_id: leadDetail.id,
            user_id: 38,
            reopen_form: {
              reopen_remark: remarkText,
            },
          },
        };
      executeGraphQLMutationFinex(mutation, variables)
        .then((resp) => {
          let response = resp?.data?.update_lead_status || null;
          if (response) {
            toast.success("Success");
            hideModalMarkAsLost();
            //props.getLeadDetail(leadDetail.id);
            setReopenModal(false);
          } else {
            toast.error("Error");
          }
        })
        .catch((err) => {
          toast.error("Error");
        });
    }
  };

  const moveStatusLink = (lead_id, status_id) => {
    let url = `/loggedin/${crypto.encode(lead_id)}`;
    if (status_id == 1) {
      url = `/loggedin/${crypto.encode(lead_id)}`;
    } else if (status_id == 2) {
      url = `/approved/${crypto.encode(lead_id)}`;
    } else if (status_id == 3) {
      url = `/disbursed/${crypto.encode(lead_id)}`;
    } else if (status_id == 4) {
      url = `/disbursed/${crypto.encode(lead_id)}`;
    }
    // window.open(url, '_blank');
    history.push(url);
    // navigate(url)
  };

  const statusButtonText =
    leadDetail?.status_id === 1
      ? "Move To Login"
      : leadDetail?.status_id === 2
      ? "Move To Sanction"
      : leadDetail?.status_id === 3 || leadDetail?.status_id === 4
      ? "Move To Disburse"
      : "Move To Login";
  let statusBtnVisiblity = true;

  const path = history.location.pathname;
  const excludeUrls = ["loggedin", "approved", "disbursed"];

  const isExist = excludeUrls.some((url) => path.indexOf(url) !== -1);
  if (isExist) statusBtnVisiblity = false;

  let backTo = `/saathi-details-page/${params.lead_id}`;
  if (path.indexOf("saathi-details-page") !== -1) backTo = "/saathi-leads";

  const leadStatus =
    leadDetail?.status_info?.statuslang?.status_name === "LogIn"
      ? "Logged In"
      : leadDetail?.status_info?.statuslang?.status_name;

  return (
    <>
      <div>
        <div className="topcontainer">
          <div className="user_info">
            <div>
              <Link to={backTo}>
                {" "}
                <img
                  src={require(`../../../src/saathi-web/images/left arrow.svg`)}
                  alt="not"
                />
              </Link>
            </div>
            <div>
              <img
                src={require(`../../../src/saathi-web/images/user.svg`)}
                alt="not"
              />
            </div>
            <div className="nam_mob_mail">
              <div>
                {leadDetail?.customer?.first_name}{" "}
                {leadDetail?.customer?.last_name}
              </div>
              <div>
                <img
                  src={require(`../../../src/saathi-web/images/mobile.svg`)}
                  alt="mobile"
                />
                {leadDetail?.customer?.mobile}
              </div>
              <div>
                <img
                  src={require(`../../../src/saathi-web/images/email.svg`)}
                  alt="email"
                />
                {leadDetail?.customer?.email || "--"}
              </div>
            </div>
          </div>
          <div className="loan_id_cont">
            <div>
              <button>{leadStatus}</button>
            </div>
            <div>
              {" "}
              Lead ID • <span>{leadDetail?.id}</span>
            </div>
            <div>
              {loan_type
                ?.filter((data) => data.id == leadDetail?.loan_type)
                ?.map((v) => v.label)}{" "}
              {leadDetail?.lead_details?.bank?.banklang?.bank_name ? "•" : ""}{" "}
              <span>
                {" "}
                {nameFormatter.formatFirstName(
                  leadDetail?.lead_details?.bank?.banklang?.bank_name
                )}
              </span>
            </div>
          </div>
          {statusBtnVisiblity && (
            <div className="move_login">
              <div>
                {leadDetail.rejection_type == "bank_hard_reject" ? (
                  <div className="d-flex" style={{ marginLeft: "20px" }}>
                    <button className="lost-btn">Lead Closed</button>
                  </div>
                ) : (
                  <div className="d-flex" style={{ marginLeft: "20px" }}>
                    {leadDetail.status_id == 5 &&
                    leadDetail.rejection_type != "bank_hard_reject" ? (
                      <button className="markloatbrn" onClick={showModalReopen}>
                        Reopen
                      </button>
                    ) : leadDetail.status_id != 4 ? (
                      <button
                        className="markloatbrn"
                        onClick={() => showModalMarkAsLost("lost")}
                      >
                        Mark as lost
                      </button>
                    ) : null}
                  </div>
                )}
              </div>
              <div>
                <LostModal
                  MarkAsLost={MarkAsLost}
                  hideModalMarkAsLost={hideModalMarkAsLost}
                  rejection_type={rejectionType}
                  statusId={leadDetail.status_id}
                  leadDetail={leadDetail}
                  masterdata={masterdata}
                />
                <Modal isOpen={reopenModal} togglePopup={hideModalReopen} removeHeight={true}>
                  <div className="modal-header">
                    <h2>Re-open</h2>
                  </div>
                  <div className="modal-body">
                    <fieldset className="form-filed">
                      <div className="material">
                        <textarea
                          placeholder=" "
                          className="form-input"
                          rows="3"
                          value={remarkText}
                          onChange={(e) => setRemarkText(e.target.value)}
                        ></textarea>
                        <label
                          data-label="Remarks"
                          className="form-label"
                        ></label>
                      </div>
                    </fieldset>
                    <button className="searchbutton" onClick={submitReopen}>
                      Save
                    </button>
                  </div>
                </Modal>

                <button
                  className="logintomovebtn"
                  onClick={() =>
                    moveStatusLink(leadDetail?.id, leadDetail?.status_id)
                  }
                >
                  {statusButtonText}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default LoanRibbenBox;
