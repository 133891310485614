import React from 'react';

const SchemeTermsAndConditions = ({ htmlContent }) => {
  return (
    <div className='scheme-terms'>
      <h2>Terms & Conditions</h2>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default SchemeTermsAndConditions;
