import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import InputField from './../../elements/InputField';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import MasterService from './../../../service/MasterService';
import {MOBILE_VALIDATION} from './../../../config/constant';
class EditCustomerDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editFormData: {},
            successResponse: false,
            redirect: false,
            errors: {},
            show: false,
            renderSection: 'lead',
            dealerLocality: [],
            loaderClass: ''
        }

    }
    componentDidMount = async () => {
        let editFormData = this.state.editFormData;
        editFormData = this.props.editFormData;
        editFormData['dealer_id'] = this.props.dealer_id;
        let dealer_locality = this.state.dealerLocality;
        if (this.props.dealer_locality != null) {
            dealer_locality = this.props.dealer_locality;
        }
        this.setState({
            editFormData,
            dealerLocality: dealer_locality
        });
    }

    submitLeadCustomerDetailsForm = (event) => {
        event.preventDefault();
        if (this.validForm()) {
            this.setState({ loaderClass : 'loading' })
            delete this.state.editFormData.mobile;
            MasterService.post('lead/lead/updateLeadCustomer', this.state.editFormData, {
                'headers': { 'Authorization': this.props.auth_token }
            }).then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    toast.success(response.data.message);
                    setTimeout(
                        function () {
                            if (typeof this.props.cdcloseModal === 'function') {
                                this.props.cdcloseModal();
                            }
                            if (typeof this.props.setDefaultPage === 'function') {
                                this.props.setDefaultPage();
                            }
                        }
                            .bind(this),
                        700
                    );
                } else if (response.status == 401 && response.data.status == 401) {
                    this.setState({ redirect: true })
                }
            }).catch((error) => {
            });
        }
    }

    handleChangeEvent = event => {
        let fieldRequired = event.target.getAttribute('validationreq');
        let editFormData = {...this.state.editFormData};
        let errors = {...this.state.errors};
        editFormData[event.target.name] = event.target.value;
        if(fieldRequired === 'yes'){
            if (event.target.value !== '') {
                delete errors[event.target.name];
            } else {
                errors[event.target.name] = this.props.t('validation.'+event.target.name)+this.props.t('validation.is_required');
            }
        }
        this.setState({ editFormData: editFormData, errors: errors });
    }

    validForm = () => {
        let editFormData = {...this.state.editFormData};
        let errors = {...this.state.errors};
        let formIsValid = true;
        let mobileRule = MOBILE_VALIDATION;
        if (!editFormData["name"]) {
            errors['name'] = this.props.t('validation.cust_name_required')
            formIsValid = false;
        }
        if(editFormData["email"] && !editFormData["email"].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
            formIsValid = false;
            errors["email"] = this.props.t('validation.cust_email_invalid');
        }
        if(editFormData["alt_mobile_number"] && !mobileRule.test(editFormData["alt_mobile_number"])){
            formIsValid = false;
            errors["alt_mobile_number"] = this.props.t('validation.mobile_invalid');
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleChangeOption = (field_name, field_obj) => {
        let editFormData = this.state.editFormData;
        editFormData[field_name] = field_obj.id;
        this.setState({ editFormData });
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/login'} />);
        }
        const { dealer_id } = this.props;
        const { dealerLocality, editFormData, errors } = this.state;
        return (
            <div className="comment-wrap">
                <form method="post" id="updateLeadCustomerDetails" onSubmit={this.submitLeadCustomerDetailsForm}>
                    <div className="row">
                        <div className="col-md-6">
                            <InputField
                                inputProps={{
                                    id: "name",
                                    type: "text",
                                    name: "name",
                                    label: this.props.t('listLead.Name'),
                                    value: editFormData.name || '',
                                    dataerror: errors.name || '',
                                    validationreq: "yes"
                                }}
                                onChange={this.handleChangeEvent}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputField
                                inputProps={{
                                    id: "email",
                                    type: "text",
                                    name: "email",
                                    label: this.props.t('listLead.Email'),
                                    value: editFormData.email || '',
                                    dataerror: errors.email || ''
                                }}
                                onChange={this.handleChangeEvent}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputField
                                inputProps={{
                                    id: "mobile",
                                    type: "text",
                                    name: "mobile",
                                    label: this.props.t('listLead.Mobile'),
                                    value: editFormData.mobile || '',
                                    readOnly: 'readOnly',
                                    dataerror: errors.mobile || '',
                                    validationreq: "yes",
                                    maxLength: 12
                                }}
                                onChange={this.handleChangeEvent}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputField
                                inputProps={{
                                    id: "alt_mobile_number",
                                    type: "text",
                                    name: "alt_mobile_number",
                                    label: this.props.t('listLead.Alternate_Mobile_Number'),
                                    value: editFormData.alt_mobile_number || '',
                                    dataerror: errors.alt_mobile_number || '',
                                    maxLength: 12
                                }}
                                onChange={this.handleChangeEvent}
                            />
                        </div>
                        <div className="col-md-12 form-group ">
                            <label>Select Location</label>
                            <Select
                                id="location"
                                value={dealerLocality}
                                onChange={this.handleChangeOption.bind(this, 'location')}
                                options={dealerLocality}
                                name="location"
                                placeholder= {this.props.t('listLead.Select_Location') }
                                value={dealerLocality && dealerLocality.filter(({ id }) => id === editFormData.location)}
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                            />
                        </div>
                        <div className="col-md-12 form-group text-right ">
                            <button type="submit" onClick={this.submitLeadCustomerDetailsForm} id="savedetail" name="submit" className={"btn btn-primary btn-lg " + this.state.loaderClass}>{this.props.t('listLead.Save_Details') }</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id,
        dealer_locality: state.locality
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default  withTranslation('lead') (connect(mapStateToProps, mapDispatchToProps)(EditCustomerDetails));