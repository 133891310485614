import React, { Component } from "react";
import InputField from './../../elements/InputField'
import Button from '../../elements/Button'
import DateFormate from 'dateformat';
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

class BlackListPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blackListUpdateData: this.props.blackListUpdateData,
            currentIndex:this.props.currentIndex,
            current_org_name:this.props.current_org_name,
            errors: {},
        };
    }

    componentDidMount = () => {
        this.loadAllComponentData();        
    }

    loadAllComponentData = async () => {
        
    }

    submitBlackListForm = async(event) => {
        event.preventDefault();
        var thisObj = this;
        if (this.validFormData()) {
            const response = await MasterService.post('dealer/dealer/status_change', { ...this.state.blackListUpdateData });
            if (response.status == 200) {                       
                toast.success(response.data.message);
                if (typeof thisObj.props.updateDealerListData === 'function') {
                    thisObj.props.updateDealerListData({ ...this.state.blackListUpdateData }, thisObj.state.currentIndex);
                  }
                this.props.modalClose();
            } else{
                toast.error(response.data.message);
            }   
        }
    }
    validFormData = () => {
        let blackListUpdateData = this.state.blackListUpdateData;
        let errors = {};
        let formIsValid = true;
        if(!blackListUpdateData.blacklist_comment){
            errors['blacklist_comment'] = this.props.t('Listing.Blacklist_Popup.blacklist_comment_required');
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleChangetext = (event) => {
        let fieldRequired = event.target.getAttribute('validationreq');
        let blackListUpdateData = this.state.blackListUpdateData;
        let errors = this.state.errors;
        if (fieldRequired === 'yes') {
            if (event.target.value === '') {
                errors[event.target.name] = this.props.t('Listing.Blacklist_Popup.blacklist_comment_required');
            } else {
                delete errors[event.target.name];
            }
        }
        blackListUpdateData[event.target.name] = event.target.value;
        this.setState({ blackListUpdateData },()=>{});
    }
    render() {
        const {formData, errors ,current_org_name} = this.state;
        let css = '';
        if (errors) {
            css = `.form-error {display:block}`;
        }   
        let actType='';     
        if(parseInt(this.state.blackListUpdateData.status)==1){
            actType="Whitelist";
        }else if(parseInt(this.state.blackListUpdateData.status)==5){
            actType="Blacklist";
        }
        return (
            <div>
                <style type="text/css">
                    {css}
                </style>
                {/* <p>Are you sure to {actType} {current_org_name} dealer!</p> */}
                <p>{this.props.t('Listing.Blacklist_Popup.message_blacklist_whitelist',{ actType: actType,current_org_name:current_org_name })}</p>
                <div className="row">
                    <div className="col-sm-6 col-md-12 form-group">
                        <InputField
                            inputProps={{
                                id: "blacklist_comment",
                                type: "text",
                                placeholder: this.props.t('Listing.Blacklist_Popup.Comments'),
                                name: "blacklist_comment",
                                autocompleate: "off",
                                label: this.props.t('Listing.Blacklist_Popup.Comments'),
                                validationreq: "yes",
                                validation_error: errors.blacklist_comment,
                                label_before_input:'true'
                            }}
                            onChange={this.handleChangetext}
                        />
                    </div>

                    <div className="col-sm-6 col-md-12 form-group text-right">
                        <Button onClick={this.props.modalClose} colclass="col-sm-12" btnClass="btn btn-link mrg-r15" type="reset" name="addleadbtn" id="cancel" title={this.props.t('Listing.Blacklist_Popup.Cancel')} />
                        <Button onClick={this.submitBlackListForm} colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title={this.props.t('Listing.Blacklist_Popup.Save')} />
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

export default withTranslation('dealers')(connect(mapStateToProps)(BlackListPopup));