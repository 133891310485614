import DateFormate from "dateformat";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { DEALER_WALLET_ID } from "../../../config/constant";
import ViewHistory from "../../dealerDetail/component/ViewHistory";
import ModalPopup from "../../elements/Popup";
import { DealerService } from "./../../../service";
import MasterService from "./../../../service/MasterService";
import BlackListPopup from "./BlackListPopup";
import { de } from "date-fns/locale";
class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealerListData: props.dealerListData,
      loading: false,
      pageloading: false,
      statusClassname: {
        ready_for_verification: "nav-item active",
        pending: "nav-item",
        active: "nav-item ",
        rejected: "nav-item",
        inactive: "nav-item",
        incomplete: "nav-item",
        blacklist: "nav-item",
        finmissed: "nav-item",
        kycmissed: "nav-item",
        all: "nav-item",
        only_registerd: "nav-item",
        doc_pending: "nav-item",
        all_pending: "nav-item",
      },
      filterData: this.props.filterData,
      dealerUpdateData: {},
      blackListPopup: false,
      blackListUpdateData: {},
      currentIndex: null,
      current_org_name: null,
      historyPopup: false,
      histoy_dealer_id: 0,
      openedRowIndex: null,
    };
  }

  showRowIndex = (index) => {
    this.setState(
      (prevState) => ({
        openedRowIndex: prevState.openedRowIndex === index ? null : index,
      }),
      () => {
        const tooltips = document.querySelectorAll(".toggle-checkbox:checked");
        tooltips.forEach((tooltip, i) => {
          if (i !== index) {
            tooltip.checked = false;
          }
        });
      }
    );
  };

  closeHistoryModel = () => {
    this.setState({ historyPopup: false });
  };

  handleHistory = (id) => {
    this.setState({ historyPopup: true });
    this.setState({ histoy_dealer_id: id });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.dealerListData !== this.props.dealerListData ||
      nextProps.loading !== this.props.loading
    ) {
      this.setState({
        dealerListData: nextProps.dealerListData,
        loading: nextProps.loading,
        pageloading: nextProps.pageloading,
      });
    }
  }
  handleStatusClick = (sname, ovalue) => {
    this.setState({
      loading: true,
    });
    let filterData = this.state.filterData;
    if (sname == "active") {
      filterData["status"] = '1';
      filterData["list_type"] = "";
    } else if (sname == "inactive") {
      filterData["status"] = '2';
      filterData["list_type"] = "";
    } else if (sname == "incomplete") {
      filterData["status"] = '3';
      filterData["list_type"] = "";
    } else if (sname == "pending") {
      filterData["status"] = '4';
      filterData["list_type"] = "pending";
    } else if (sname == "blacklist") {
      filterData["status"] = '5';
      filterData["list_type"] = "";
    } else if (sname == "all") {
      filterData["status"] = null;
      filterData["list_type"] = "";
    } else if (sname == "finmissed") {
      filterData["status"] = '4';
      filterData["list_type"] = "fin_missed";
    } else if (sname == "rejected") {
      filterData["status"] = '4';
      filterData["list_type"] = "rejected";
    } else if (sname == "kycmissed") {
      filterData["status"] = '4';
      filterData["list_type"] = "kyc_missed";
    } else if (sname == "only_registerd") {
      filterData["status"] = '4';
      filterData["list_type"] = "only_registerd";
    } else if (sname == "ready_for_verification") {
      filterData["status"] = '4';
      filterData["list_type"] = "ready_for_verification";
    } else if (sname == "doc_pending") {
      filterData["status"] = '4';
      filterData["list_type"] = "doc_pending";
    } else if (sname == "all_pending") {
      filterData["status"] = '4';
      filterData["list_type"] = "pending";
    }

    this.setState({ filterData });
    if (typeof this.props.statusClick === "function") {
      this.props.statusClick(sname, filterData["list_type"]);
      this.setState({
        statusClassname: {
          active: "nav-item",
          inactive: "nav-item",
          rejected: "nav-item",
          incomplete: "nav-item",
          blacklist: "nav-item",
          pending: "nav-item",
          finmissed: "nav-item",
          kycmissed: "nav-item",
          all: "nav-item",
          ready_for_verification: "nav-item",
          doc_pending: "nav-item",
          only_registerd: "nav-item",
          all_pending: "nav-item",
          [sname]: "nav-item active",
        },
      });
    }
  };

  getBasicDetails() {
    return new Promise((resolve, reject) => {
      DealerService.getMasters(["dealer_sku"])
        .then((response) => {
          if (response.status == 200 && response.data.status == 200) {
            let responsedata = response.data.data || {};
            if (responsedata.dealer_sku && responsedata.dealer_sku.length) {
              let subscM = [];
              responsedata.dealer_sku.map((v) => {
                if (v.sku_type == "subscription") subscM.push(v);
              });
              resolve(subscM);
            }
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  checkBalance = async (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        let balance = await DealerService.checkBalance(params);
        if (balance && balance.data && balance.data.status === 200) {
          if (
            balance &&
            balance.data &&
            balance.data.data &&
            balance.data.data.closing_balance === 0
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        } else {
          reject(balance.data.message);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  fetchEditData = (dealer_id_hash) => {
    return new Promise((resolve, reject) => {
      DealerService.getSkusDetails(dealer_id_hash)
        .then((response) => {
          if (response.status == 200 && response.data.status == 200) {
            let editDataAll = response.data.data || [];
            let [row] = editDataAll.filter(
              (item) => item.b_details_sku_id === DEALER_WALLET_ID
            );
            this.getBasicDetails()
              .then((subM) => {
                let [master] = subM.filter(
                  (item) => item.id === DEALER_WALLET_ID
                );
                row.b_details_is_active = "0";
                row.master = master;
                resolve(row);
              })
              .catch((error) => reject(error));
          } else {
            reject(response.data);
          }
        })
        .catch((error) => reject(error));
    });
  };

  handleChange =
    (idx, dealer_id_hash, current_status) =>
      async ({ target }) => {
        let isChecked = target.checked;
        let [dealer_data] = this.state.dealerListData.filter(
          (item) => item.dealer_id_hash === dealer_id_hash
        );
        await this.changeDealerStatus(
          idx,
          target,
          isChecked,
          current_status,
          dealer_id_hash
        );
      };

  handleBlackList =
    (idx, dealer_id_hash, status) =>
      async ({ target }) => {
        let tempBlackListUpdateData = { ...this.state.blackListUpdateData };
        tempBlackListUpdateData.status = status;
        tempBlackListUpdateData.dealer_id_hash = dealer_id_hash;
        let org_name =
          this.state.dealerListData[idx] &&
          this.state.dealerListData[idx].organization;
        this.setState(
          {
            blackListPopup: true,
            blackListUpdateData: tempBlackListUpdateData,
            currentIndex: idx,
            current_org_name: org_name,
          },
          () => { }
        );
      };

  closeModal = () => {
    this.setState({ blackListPopup: false });
  };
  updateDealerListData = (updatedObj, index) => {
    let dealerListTempData = [...this.state.dealerListData];
    dealerListTempData[index].status = updatedObj.status;
    this.setState({ dealerListData: dealerListTempData });
  };
  async changeDealerStatus(
    idx,
    target,
    isChecked,
    current_status,
    dealer_id_hash
  ) {
    let org = this.state.dealerListData[idx] || {};
    let active = isChecked ? "Active" : "Inactive";
    var r = window.confirm(
      this.props.t("Listing.Search_Result.alert_active_inactive", {
        status: active,
        dealer_name: org.organization,
      })
    );
    let dealerUpdateData = this.state.dealerUpdateData;
    if (r == true) {
      if (isChecked) {
        dealerUpdateData["status"] = "1";
        dealerUpdateData["current_status"] = current_status;
        dealerUpdateData["dealer_id_hash"] = dealer_id_hash;
      } else {
        dealerUpdateData["status"] = "2";
        dealerUpdateData["current_status"] = current_status;
        dealerUpdateData["dealer_id_hash"] = dealer_id_hash;
      }
      this.setState({ dealerUpdateData });
      var thisObj = this;
      let dealerListTempData = [...this.state.dealerListData];
      thisObj.setState({ loading: true });
      let checkVal = isChecked;
      const response = await MasterService.post("dealer/dealer/status_change", {
        ...this.state.dealerUpdateData,
      });
      thisObj.flag = false;
      if (response.data.status == 200) {
        if (checkVal) {
          target.parentNode.style.textDecoration = "";
          dealerListTempData[idx].status = 1;
        } else {
          target.parentNode.style.textDecoration = "line-through";
          dealerListTempData[idx].status = 2;
        }
        thisObj.setState({
          dealerListData: dealerListTempData,
          loading: false,
        });
      } else {
        thisObj.setState({ loading: false });
        toast.error(response.data.message);
      }
    }
  }
  ListOfNotUploadedDocuments(dealer_id) {
    let dealerdata = dealer_id;
    console.log(dealerdata)
    console.log(dealerdata);
    let arr = []
    if (dealerdata.applicant_type === '1') {
      dealerdata.pending_docs.forEach(item => {
        if (item.dealer_id === null) {
          arr.push(item.name);
        }
      });
      console.log(arr)
      return (
        <ul>
          {arr.map(element => (
            <li key={element}>{element}</li>
          ))}
        </ul>
      );
    } else if (dealerdata.applicant_type === "2") {
      dealerdata.pending_docs.forEach(item => {
        if (item.dealer_id === null) {
          arr.push(item.name);
        }

      });
      if (dealerdata.is_gst === null) {
        arr = arr.filter(value => value !== 'GSTN');
      }

      if (dealerdata.is_msme === null) {
        arr.filter(value => {
          return value !== 'MSME Certificate'
        })
      }
      console.log(arr)
      return (
        <ul>
          {arr.map(element => (
            <li key={element}>{element}</li>
          ))}
        </ul>
      );
    }
  }





  render() {
    const { dealerCountStatusDetail } = this.props;
    return (
      <div
        className={this.state.loading ? "loading result-wrap" : "result-wrap"}
      >
        <div className="pad-15">
          <div className="row">
            <div className="col-sm-10">
              <ul className="list-inline nav-tabs">
                {(this.state.filterData.list_type === "pending" ||
                  this.state.filterData.list_type ===
                  "ready_for_verification" ||
                  this.state.filterData.list_type === "doc_pending" ||
                  this.state.filterData.list_type === "all_pending" ||
                  this.state.filterData.list_type === "only_registerd") && (
                    <>
                      <li
                        className={
                          this.state.statusClassname.ready_for_verification
                        }
                        onClick={this.handleStatusClick.bind(
                          this,
                          "ready_for_verification"
                        )}
                      >
                        <div>
                          Ready For Verification
                          <span>
                            ({dealerCountStatusDetail.readyforVerification})
                          </span>
                        </div>
                      </li>
                      <li
                        className={this.state.statusClassname.doc_pending}
                        onClick={this.handleStatusClick.bind(this, "doc_pending")}
                      >
                        <div>
                          Docs Pending
                          <span>({dealerCountStatusDetail.docPending})</span>
                        </div>
                      </li>
                      <li
                        className={this.state.statusClassname.only_registerd}
                        onClick={this.handleStatusClick.bind(
                          this,
                          "only_registerd"
                        )}
                      >
                        <div>
                          Only Registered
                          <span>({dealerCountStatusDetail.onlyRegisterd})</span>
                        </div>
                      </li>
                      <li
                        className={this.state.statusClassname.all_pending}
                        onClick={this.handleStatusClick.bind(this, "all_pending")}
                      >
                        <div>
                          All<span>({dealerCountStatusDetail.pending})</span>
                        </div>
                      </li>
                      {/* <li className={this.state.statusClassname.pending} onClick={this.handleStatusClick.bind(this, 'pending')}>
                                            <div>New<span>({dealerCountStatusDetail.pending})</span></div>
                                        </li>
                                        <li className={this.state.statusClassname.active} onClick={this.handleStatusClick.bind(this, 'active')}>
                                            <div>{this.props.t('Verified')}<span>({dealerCountStatusDetail.active})</span></div>
                                        </li>
                                        <li className={this.state.statusClassname.inactive} onClick={this.handleStatusClick.bind(this, 'inactive')}>
                                            <div>{this.props.t('Listing.Search_Result.Inactive')}<span>({dealerCountStatusDetail.inactive})</span></div>
                                        </li>
                                        <li className={this.state.statusClassname.rejected} onClick={this.handleStatusClick.bind(this, 'rejected')}>
                                            <div>Rejected<span>({dealerCountStatusDetail.rejected})</span></div>
                                        </li> */}
                      {/* Additional list items */}
                      {/* <li className={this.state.statusClassname.blacklist} onClick={this.handleStatusClick.bind(this, 'blacklist')}>
                                                    <div>{this.props.t('Listing.Search_Result.Blacklist')}<span>({dealerCountStatusDetail.blacklist})</span></div>
                                                    </li> */}
                      {/* <li className={this.state.statusClassname.finmissed} onClick={this.handleStatusClick.bind(this, 'finmissed')}>
                                                    <div>{this.props.t('Fin Missed')}<span>({dealerCountStatusDetail.fin_pending})</span></div>
                                                    </li> */}
                      {/* <li className={this.state.statusClassname.kycmissed} onClick={this.handleStatusClick.bind(this, 'kycmissed')}>
                                                    <div>{this.props.t('KYC Missed')}<span>({dealerCountStatusDetail.kyc_pending})</span></div>
                                                    </li> */}
                      {/* <li className={this.state.statusClassname.all} onClick={this.handleStatusClick.bind(this, 'all')}>
                                                <div>{this.props.t('Listing.Search_Result.All')}<span>({dealerCountStatusDetail.all})</span></div>
                                            </li> */}
                    </>
                  )}
              </ul>
            </div>
            <div className="text-right">
              {/* <button onClick={()=>this.props.onExportData()} type="button" className="btn btn-success mrg-r30" title={this.props.t('Listing.Search_Result.Export_Data')}>{this.props.t('Listing.Search_Result.Export_Data')}</button> */}
              <NavLink to="/dealer/basic-details" className="btn btn-success">
                {this.props.t("Listing.Search_Result.Add_Dealer")}
              </NavLink>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="clearfix">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>{this.props.t("Listing.Search_Result.AMB")}</th>
                    <th>{this.props.t("Listing.Search_Result.Details")}</th>
                    {this.state.filterData.list_type ===
                      "ready_for_verification" && (
                        <th>Aadhar-PAN Linked</th>
                      )}

                    {this.state.filterData.list_type ===
                      "doc_pending" && (
                        <th>Docs Status</th>
                      )}
                    <th>Location</th>
                    <th>
                      {this.props.t("Listing.Search_Result.partner_type")}
                    </th>
                    {/* <th>{this.props.t("Listing.Search_Result.profession")}</th> */}
                    <th>{this.props.t("Listing.Search_Result.Source")}</th>
                    <th>
                      {this.props.t("Listing.Search_Result.registration_date")}
                    </th>
                    <th>Referred</th>
                    {this.state.filterData.list_type ===
                      "ready_for_verification" && (
                        <th>Approval Status</th>
                      )}
                    <th>{this.props.t("Listing.Search_Result.Actions")}</th>
                    {/* <th>{this.props.t('dealerDetails.Basic_Details_master.view_history')}</th> */}
                    {/* <th>Active</th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.dealerListData.length == 0 ? (
                    <tr>
                      <td align="center" colSpan="7">
                        <h6 className="text-center text-danger text-bold">
                          {this.props.t(
                            "Listing.Search_Result.No_Record_Found"
                          )}
                        </h6>
                      </td>
                    </tr>
                  ) : null}
                  {this.state.dealerListData.length != 0 &&
                    this.state.dealerListData.map((dealerdata, k) => (
                      <tr key={k}>
                        <td>
                          {dealerdata.agent_code}
                          {dealerdata.bm_info.name ? (
                            <div>
                              <div>
                                <b>BM Name: </b>
                                <span title={dealerdata.bm_info.name}>
                                  {dealerdata.bm_info.name.substring(0, 20)}
                                </span>
                                {dealerdata.bm_info.name.length > 20
                                  ? "..."
                                  : ""}
                              </div>
                              <div>
                                <b>BM Mobile: </b>
                                {dealerdata.bm_info.mobile}{" "}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {dealerdata.bro_info.name ? (
                            <div>
                              <div>
                                <b>BRO Name: </b>
                                <span title={dealerdata.bro_info.name}>
                                  {dealerdata.bro_info.name.substring(0, 20)}
                                </span>
                                {dealerdata.bro_info.name.length > 20
                                  ? "..."
                                  : ""}
                              </div>
                              <div>
                                <b>BRO Mobile: </b>
                                {dealerdata.bro_info.mobile}{" "}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </td>

                        <td style={{ width: "10%" }}>
                          <div>
                            {dealerdata.applicant_type_id == "2"
                              ? dealerdata.organization
                              : dealerdata.first_name}
                          </div>
                          <div>{dealerdata.dealership_email}</div>
                          <div>{dealerdata.dealership_contact}</div>

                          {/* <div><img src="https://dealeradmin.gaadi.com/admin/public/images/gaadi.png"/></div> */}
                        </td>
                        {this.state.filterData.list_type ===
                          "ready_for_verification" && (
                        <td>
                          {/* <b>Bussiness</b>: {dealerdata.is_bussiness_approve == "1" ? "Yes" : "Pending"}<br></br> */}
                          {dealerdata.is_aadhar_pan_linked == "1" ? "Yes" : "Pending"}
                        </td>
                         )}
                         
                        {this.state.filterData.list_type ===
                          "doc_pending" && (
                            <td>
                              <input
                                type="checkbox"
                                className="toggle-checkbox"
                                id={`toggle-tips-${k}`}
                              />


                              <label htmlFor={`toggle-tips-${k}`}>
                                <div
                                  className="offermeternew"
                                  onClick={() => this.showRowIndex(k)}
                                >
                                  <div className="confidencename">
                                    <div className="confidencename">
                                      {(() => {
                                        const filteredPendingDocs =
                                          dealerdata.pending_docs && dealerdata.pending_docs.filter(
                                            (element) => element.dealer_id === null
                                          );
                                        const pendingDocsCount = filteredPendingDocs ? filteredPendingDocs.length : 0;

                                        if (dealerdata.applicant_type === "1") {
                                          return `${pendingDocsCount} pending`;
                                        } else {
                                          const subtract =
                                            dealerdata.applicant_type === "2" &&
                                              (dealerdata.is_gst === null ||
                                                dealerdata.is_msme === null)
                                              ? 1
                                              : 0;
                                          return `${pendingDocsCount - subtract
                                            } pending`;
                                        }
                                      })()}
                                    </div>
                                  </div>
                                  <img
                                    src={require("./../../ruleEngine/assets/images/downarrow.svg")}
                                    alt=""
                                  />
                                  {this.state.openedRowIndex === k && (
                                    <div className="tips-list">
                                      <div>
                                        <ul>
                                          {this.ListOfNotUploadedDocuments(dealerdata)}
                                        </ul>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </label>

                            </td>
                          )}
                        <td>
                          {dealerdata.cityname ? dealerdata.cityname : null}
                          <div>
                            {dealerdata.pincode ? dealerdata.pincode : ""}
                          </div>
                        </td>
                        <td>
                          {dealerdata.applicant_type_id == "2"
                            ? "Company"
                            : "Individual"}
                        </td>
                        {/* <td>
                          {dealerdata.profession_name
                            ? dealerdata.profession_name
                            : null} 
                        </td> */}
                        <td>
                          {dealerdata.source
                            ? dealerdata.source
                            : "Partner App"}
                        </td>
                        <td>
                          Created :{" "}
                          {dealerdata.created_date
                            ? DateFormate(
                              dealerdata.created_date,
                              "dS mmm yyyy h:MM TT"
                            )
                            : ""}
                          <br></br>
                          <br></br>Update :{" "}
                          {dealerdata.updated_date
                            ? DateFormate(
                              dealerdata.updated_date,
                              "dS mmm yyyy h:MM TT"
                            )
                            : ""}
                        </td>
                        <td>
                          {dealerdata.sfa_refferal_user_name ? (
                            <div>
                              <span> {dealerdata.sfa_refferal_user_name} </span>
                            </div>
                          ) : (
                            ""
                          )}
                          {dealerdata.sfa_refferal_user_name ? (
                            <div>{dealerdata.sfa_refferal_user_mobile}</div>
                          ) : (
                            ""
                          )}
                        </td>
                        {this.state.filterData.list_type ===
                          "ready_for_verification" && (
                        <td>
                          {/* <b>Bussiness</b>: {dealerdata.is_bussiness_approve == "1" ? "Yes" : "Pending"}<br></br> */}
                          <b>Payout</b>: {dealerdata.is_payout_approve == "1" ? "Yes" : "Pending"}
                        </td>
                         )}
                        {/* <td>
                                            {
                                                        (dealerdata.status == '1' || dealerdata.status == '2' || dealerdata.status == '4') ? <>
                                                           {dealerdata.status == '4' ? "Pending " : ""} <br></br><label className="switch-btn btn btn-link" htmlFor={"active" + dealerdata.dealer_id_hash} >
                                                                <input className="switch-btn" id={"active" + dealerdata.dealer_id_hash} value="open" name={"active" + dealerdata.dealer_id_hash} type="checkbox" onClick={this.handleChange(k, dealerdata.dealer_id_hash,dealerdata.status)} checked={(dealerdata.status == '1') ? true : false} />
                                                                <div className="slider round"></div>
                                                                <span className="switch-label"></span>
                                                                {this.props.t('Listing.Search_Result.Active')}
                                                            </label></> 
                                                            : dealerdata.status == '5' ? "Blacklisted" : ""
                                                    }
                                            </td>  */}
                        <td>
                          <div className="btn-group btn-group-small mrg-r10">
                            
                            <Link
                              to={`/dealer/${dealerdata.dealer_id_hash}/basic-details`}
                              className="btn btn-default"
                            >
                              <i className="ic-createmode_editedit icons-normal"></i>
                            </Link>
                            {/* <Link to={`/dealer/${dealerdata.dealer_id_hash}/subscription-details`} className="btn btn-default" title="Edit Subscription"><i className="ic-subscription icons-normal"></i></Link> */}
                            {dealerdata.status == "1" && (
                              <button
                                type="button"
                                className="btn btn-default"
                                onClick={this.handleBlackList(
                                  k,
                                  dealerdata.dealer_id_hash,
                                  "2"
                                )}
                                title="Mark as InActive"
                              >
                                <i className="ic-peson-blacklist icons-normal"></i>
                              </button>
                            )}
                            {dealerdata.status == "2" && (
                              <button
                                type="button"
                                className="btn btn-default"
                                onClick={this.handleBlackList(
                                  k,
                                  dealerdata.dealer_id_hash,
                                  "1"
                                )}
                                title="Mark as Active"
                              >
                                <i className="ic-peson-listed icons-normal"></i>
                              </button>
                            )}
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={() => {
                                this.handleHistory(dealerdata.id);
                              }}
                              title="View History"
                            >
                              <i className="ic-remove_red_eyevisibility icons-normal"></i>
                            </button>
                          </div>
                        </td>
                        {/* <td>
                                                <button className="btn" onClick={() => {this.handleHistory(dealerdata.id)}}>{this.props.t('dealerDetails.Basic_Details_master.view_history')}</button>
                                            </td> */}
                      </tr>
                    ))}
                  {this.state.loading ? (
                    <tr>
                      <td className="loading" colSpan="6"></td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {this.state.blackListPopup == false ? null : (
          <ModalPopup
            id="black_list"
            className="modal"
            title={
              parseInt(this.state.blackListUpdateData.status) == 5
                ? this.props.t("Listing.Search_Result.Black_List_Dealer")
                : this.props.t("Listing.Search_Result.active_inactive_Dealer")
            }
            modalClose={this.closeModal}
          >
            <BlackListPopup
              modalClose={this.closeModal}
              blackListUpdateData={this.state.blackListUpdateData}
              updateDealerListData={this.updateDealerListData}
              currentIndex={this.state.currentIndex}
              current_org_name={this.state.current_org_name}
            />
          </ModalPopup>
        )}

        {this.state.historyPopup === false ? null : (
          <ModalPopup
            id="view_history"
            className="modal ViewHistory data-table"
            title={"View History"}
            modalClose={this.closeHistoryModel}
          >
            {this.state.histoy_dealer_id !== 0 && (
              <ViewHistory histoy_dealer_id={this.state.histoy_dealer_id} />
            )}
          </ModalPopup>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};
export default withTranslation("dealers")(
  connect(mapStateToProps, mapDispatchToProps)(SearchResult)
);
