import React, { useEffect, useState } from 'react';
import secureStorage from '../../../config/encrypt';

const SchemeReward = ({getRangeLabel, subPartnerData, schemeRange }) => {

  const [selectedTab, setSelectedTab] = useState(null);
  const parent_partner_id = secureStorage.getItem('loginUserInfo').data?.user_data?.parent_partner_id ||0

  useEffect(() => {
    setSelectedTab(subPartnerData[0])
  }, [])


  return (
    selectedTab && subPartnerData?.length && selectedTab?.schemeranges?.length  ? parent_partner_id==0 ?<div>
      <p className='scheme-detail-card-heading'>Rewards Split with Sub-Partners</p>
      <div className="scheme-rewards-tabs">
        {subPartnerData?.map((tab, index) => (
          tab?.schemeranges?.length ? <button
            key={index}
            onClick={() => setSelectedTab(tab)}
            className={selectedTab?.sub_partner_id === tab?.sub_partner_id ? 'active' : ''}
          >
            {tab?.sub_partner_name}
          </button>:null
        ))}
      </div>

      <div className="scheme-rewards-table-container">
        <table>
          <thead>
            <tr>
              <th>Slab</th>
              <th>Sub-Partner</th>
              <th>Self</th>
            </tr>
          </thead>
          <tbody>
            {selectedTab && selectedTab?.schemeranges?.length ? selectedTab?.schemeranges?.map((row, index) => {
              const self = ((+schemeRange.find(scm => scm.id == row.id).payout) - (+row.payout)).toFixed(3) || "";
              return <tr key={index}>
                <td>{selectedTab?.schemeranges.length==1?"Any Volume":selectedTab?.schemeranges.length-1==index?`>${getRangeLabel(row.start_range)}`:`${getRangeLabel(row.start_range)}-${getRangeLabel(row.end_range)}`}</td>
                
                <td>{row?.payout}%</td>
                <td>{self}%</td>
              </tr>
            }) : null}
          </tbody>
        </table>
      </div>
    </div>:<div className="scheme-rewards-table-container">
        <table>
          <thead>
            <tr>
              <th>Slab</th>
              <th>Payout</th>
            </tr>
          </thead>
          <tbody>
            {selectedTab && selectedTab?.schemeranges?.length ? selectedTab?.schemeranges?.map((row, index) => {
              return <tr key={index}>
                <td>{selectedTab?.schemeranges.length==1?"Any Volume":selectedTab?.schemeranges.length-1==index?`>${getRangeLabel(row.start_range)}`:`${getRangeLabel(row.start_range)}-${getRangeLabel(row.end_range)}`}</td>
                <td>{row?.payout}%</td>
              </tr>
            }) : null}
          </tbody>
        </table>
      </div> : null
  );
};

export default SchemeReward;
