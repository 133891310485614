import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import secureStorage from "../../../config/encrypt";
import Modal from "../../../view/common/Modal";
import SchemePopup from "./SchemePopup";
import PayoutSchemeDetails from "./PayoutSchemeDetails";
import { SCHEME_LIST } from "../../../queries/payout.gql";
import { executeGraphQLQuery, executeGraphQLQueryFinex } from "../../../common/executeGraphQLQuery";
import moment from "moment";
import { FINEX_MASTER } from "../../../queries/scheme.gql";
import PageLoader from "../../../view/elements/PageLoader";
import NoDataFoundPage from "../../../common/NoDataFoundPage";


const PayoutScheme = (props) => {
  const { updateStateIntoRedux } = props;
  const [showView, setShowView] = useState(false);
  const [currentScheme, setCurrentScheme] = useState(null)
  const [schemeData, setSchemeData] = useState([])
  const [bankList, setBankList] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const loginResponse = secureStorage.getItem("loginUserInfo");
    const updateReduxData = async () => {
      if (loginResponse) {
        updateStateIntoRedux("LOGIN_USER", loginResponse);
      }
    };
    updateReduxData();
    getBankList()
    fetchData(loginResponse.data.dealer_id);
  }, [updateStateIntoRedux]);

  const getBankList = async () => {
    try {
      const masterData = await executeGraphQLQueryFinex(FINEX_MASTER());
      setBankList(masterData.data.masterdata.bank_list);
    } catch (err) {
      console.error("Error fetching bank list:", err);
    }
  };

  const fetchData = async (partner) => {
    try {
      setLoading(true)
      const result = await executeGraphQLQuery(SCHEME_LIST(partner));
      setSchemeData(result.data.partner_schemes);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      
    }
  }


  const togglePopup = () => {

    setShowView((prevShowView) => !prevShowView);

    if (!showView) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };



  return (
    <div className="mytoolscard">
      {loading ? <PageLoader loaderClass="loading-custom" /> : null}
      <div className="scheme-heading">
        <div>{currentScheme==null?"Payout Schemes":currentScheme.scheme_name}</div>
        <div className="bell-icon" style={{ visibility: "hidden" }}>
          <img width={"20px"} src={require("../../images/notifybell.svg")} />
        </div>
      </div>
      <div className="payout-scheme">
      
      {!loading && schemeData.length==0?<NoDataFoundPage title={"uh-ho!"} subTitle={"Looks like there are no schemes mapped to you"}/>:
      <>
      {currentScheme == null ? <div className="payout-scheme-box-first">
          <div className="scheme-main-card">
            <div className="schemeinnercard">
              {schemeData.length ? schemeData.map((item, index) => {
                return <div key={index} className="scheme-card" onClick={() => setCurrentScheme(item)}>
                  <div className="scheme-item">
                    <span className="scheme-card-item-label">Scheme Name</span>
                    <span className="scheme-card-item-value">{item.scheme_name}</span>
                  </div>
                  {/* <div className="scheme-item">
                    <span className="scheme-card-item-label">Sub Partner</span>
                    <span className="scheme-card-item-value">Shivam Dubey</span>
                  </div> */}
                  <div className="scheme-item">
                    <span className="scheme-card-item-label">Date</span>
                    <span className="scheme-card-item-value">{moment(item.start_date).format("Do MMM YYYY")} to {moment(item.end_date).format("Do MMM YYYY")}</span>
                  </div>
                  <div className="scheme-item">
                    <span className="scheme-card-item-label">Applicable on</span>
                    <span className="scheme-card-item-value">{item.scheme_for=="amount"?"Amount":item.scheme_for=="count"?"Count":null}</span>
                  </div>
                  <div className="scheme-card-divider"></div>
                  <div className="badges">
                    <div className={`badge ${item.scheme_type}`}>{item.scheme_type == "standard" ? "Standard" : "Special"}</div>
                    <div className="badge fulfillment">{item.fulfillment_type == "self" ? "Self" : "Ambak-fullfillment"}</div>
                  </div>
                </div>
              }):null}

            </div>

          </div>
          {schemeData?.length ? <div>
            <div className="leadheading">Current Contests</div>
            <div className="scheme-contest">
              {schemeData[0]?.banners.map((image, index) => {
                return <img key={index} src={image.banner_url} width={"350px"} style={{ objectFit: "contain" }} />
              })}
            </div>

          </div>:null}
        </div> : null}
        {currentScheme !== null ? <PayoutSchemeDetails setCurrentScheme={setCurrentScheme} currentScheme={currentScheme} bankList={bankList} /> : null}
      </>
      }
        <div className="payout-scheme-box-second">
          <div className="leadheading">Other details</div>
          <div className="other-card">
            <span>DSA Lender Codes</span><a onClick={togglePopup}>View</a>
          </div>
          {schemeData[0]?.hl_payout_points_url ?<div className="other-card">
            <span>Payment Process (HL)</span>
            <a href={schemeData.length && schemeData[0]?.hl_payout_points_url} target="_blank" rel="noopener noreferrer">View</a>
            {/* <button className="btn" type="button" onClick={()=>togglePopup("home_loan_popup")}>View</button> */}
          </div>:null}
          {schemeData[0]?.lap_payout_points_url ? <div className="other-card">
            <span>Payment Process (LAP)</span><a href={schemeData.length && schemeData[0]?.lap_payout_points_url} target="_blank" rel="noopener noreferrer">View</a>
          </div>:null}
        </div>
        
      </div>
      <Modal isOpen={showView} togglePopup={togglePopup} width={1000} removeHeight={true} className={"remove-scroll-bar"}>
        <SchemePopup bankList={bankList} />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth_token: state.authToken,
  user_loggedin: state.userLoggedIn,
  dealer_id: state.dealer_id,
});

const mapDispatchToProps = (dispatch) => ({
  updateStateIntoRedux: (actionType, stateData) =>
    dispatch({ type: actionType, componentState: stateData }),
});

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(PayoutScheme)
);
