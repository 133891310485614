import React from "react";




const NoDataFoundPage = ({ title, subTitle }) => {
    return (
        <>
            <div className="nodataflexpage">
                <div className="nodatacardpage">
                    <img src="https://ambak.storage.googleapis.com/partner/3701/1727502314187.svg" width={600} alt="no data found" />
                </div>
                {title && <h2>{title}</h2>}
                {subTitle && <p>{subTitle}</p>}
            </div>
        </>
    );
}



export default NoDataFoundPage;