import React, { useEffect, useState } from "react";
import RadioBox from "../../../src/view/elements/Radiobox";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import {
  GET_SCHEME_FINANCER_GROUPS,
  SCHEME_DATA,
} from "../../queries/scheme.gql";
import { GET_PAYOUT_POINTS, GET_PAYOUT_POINTS_WITH_FILTERS , GET_GROUP_PAYOUT_DETAILS } from "../../queries/payout.gql";
import { useApolloClient } from "@apollo/client";
import moment from "moment";

const MyEarningsPopup = (props) => {
  const [selectedOption, setSelectedOption] = useState("breakup");
  const [currentView, setcurrentView] = useState("scheme");
  const [schemeData, setSchemeData] = useState(null);
  const [payout_point, setPayout_point] = useState("")
  const [partner_type , setPartner_type] = useState("")

  const invoiceData = props.invoiceData;
  console.log("props.invoiceData",props.invoiceData)
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  //const client = useApolloClient()


  useEffect(() => {
    getSchemeData();
    getPayoutData();

  }, []);


  const getPayoutData = async () => {

  // let arr =[5]
  // arr.push(props?.projectedData?.financer_id || invoiceData?.payouts.partnerleads?.financer_id)


  //  const variables = {
  //   filter_params :{
  //     "page_no": 1,
  //     "financier_id": arr,
  //     "start_date": new Date(invoiceData.disbursal_date).toLocaleDateString() ||  new Date(invoiceData.payouts.partnerleads.disbursal_date).toLocaleDateString(),
  //     "end_date":  new Date(invoiceData.disbursal_date).toLocaleDateString()  ||  new Date(invoiceData.payouts.partnerleads.disbursal_date).toLocaleDateString(),
  //     "point_id": null
  //   }
  //  }
  console.log(invoiceData?.lead_id ||  invoiceData?.payouts?.partnerleads.lead_id, invoiceData?.tranche_id ||
    invoiceData?.payouts?.partnerleads.tranche_id, invoiceData?.range_id, invoiceData?.payout_id || invoiceData?.payouts?.id,invoiceData?.partner_id || invoiceData?.payouts?.partner_id)
    
    const response = await executeGraphQLQuery(GET_GROUP_PAYOUT_DETAILS(invoiceData?.lead_id ||  invoiceData?.payouts?.partnerleads.lead_id, invoiceData?.tranche_id ||
      invoiceData?.payouts?.partnerleads.tranche_id, invoiceData?.range_id || invoiceData?.payouts?.range_id, invoiceData?.payout_id || invoiceData?.payouts?.id,invoiceData?.partner_id || invoiceData?.payouts?.partner_id) )
    if (setPayout_point.length > 0) {
    setPayout_point(response?.data.group_payout_details[0]?.points)    
    }
  }
  const getSchemeData = async () => {

    try {
      const loginUserInfo1 = JSON.parse(localStorage.getItem("loginUserInfo"));
      const dealerId = loginUserInfo1.user_data.dealer_id;
      const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
      if (loginUserInfo.user_type == 1)
        {
          setPartner_type("1")
        }
      else {
          setPartner_type("2")
      }
      const partner_id = dealerId; 
      const scheme_id = invoiceData.scheme_id || invoiceData.payouts?.scheme?.id;
      const financier_group_id = invoiceData.financer_group_id || invoiceData.payouts.financer_group_id;

      const schemeFGDataList = await executeGraphQLQuery(
        SCHEME_DATA(scheme_id, partner_id, financier_group_id),
        {
          notifyOnNetworkStatusChange: true,
        }
      );
      if (schemeFGDataList.data && schemeFGDataList.data.schemeFinancerGroupbySchemeId) {
        if (loginUserInfo !== 1) {
          schemeFGDataList.data.schemeFinancerGroupbySchemeId.forEach(schemeGroup => {
            schemeGroup.schemerange.forEach(range => {
                const matchingPartnerRange = schemeGroup.schemepartnerrange.find(
                    partnerRange => partnerRange.range_id === range.id
                );
                if (matchingPartnerRange) {
                    range.partner_payout = matchingPartnerRange.payout;
                }
            });
        });
        }
        setSchemeData(schemeFGDataList.data.schemeFinancerGroupbySchemeId);
      }
    } catch (err) {
    }
  };

  const handleTabClick = (tabName) => {
    setcurrentView(tabName);
  };

  const tab_status = {
    Verified: {
      is_completed: true,
      is_active: true,
    },
    New_Lead: {
      is_completed: false,
      is_active: false,
    },
    Reject: {
      is_completed: false,
      is_active: false,
    },
  };


  const getRangeLabel = (amount) => {
    if (amount >= 1000000000) return "100cr+";
    if (amount >= 900000000) return "90cr+";
    if (amount >= 800000000) return "80cr+";
    if (amount >= 700000000) return "70cr+";
    if (amount >= 600000000) return "60cr+";
    if (amount >= 500000000) return "50cr+";
    if (amount >= 400000000) return "40cr+";
    if (amount >= 300000000) return "30cr+";
    if (amount >= 200000000) return "20cr+";
    if (amount >= 100000000) return "10cr+";
    if (amount >= 90000000) return "9cr+";
    if (amount >= 80000000) return "8cr+";
    if (amount >= 70000000) return "7cr+";
    if (amount >= 60000000) return "6cr+";
    if (amount >= 50000000) return "5cr+";
    if (amount >= 40000000) return "4cr+";
    if (amount >= 30000000) return "3cr+";
    if (amount >= 20000000) return "2cr+";
    if (amount >= 10000000) return "1cr+";
    return "0"; // Default case if amount is below the lowest range
  };

  const formatCurrency = (x) => {
    if (x){
    x=x.toString();
    var lastThree = x.substring(x.length-3);
    var otherNumbers = x.substring(0,x.length-3);
    if(otherNumbers != '')
        lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res
}
return null
  }

  return (
    <>
    
      <div className="myteamsflex">
        <div style={{ marginRight: 10 }}>
          <RadioBox
            type="radio"
            name="lead_type"
            id="breakup"
            value="breakup"
            label="Breakup"
            checked={selectedOption === "breakup"}
            onChange={handleChange}
          />
        </div>
        <div>
          <RadioBox
            type="radio"
            name="lead_type"
            id="scheme"
            value="scheme"
            label="Scheme"
            checked={selectedOption === "scheme"}
            onChange={handleChange}
          />
        </div>
      </div>

      {selectedOption === "breakup" && (
        <>
          <div className="breakuplistflex">
            <div>
              <div className="headingbreak">
                {invoiceData?.tranche_id ||
                  invoiceData?.payouts?.partnerleads.tranche_id}
              </div>
              <div className="subheadingbreak">Tranche</div>
            </div>
            <div>
              <div className="headingbreak">
                {invoiceData?.products ||
                  invoiceData?.payouts?.partnerleads.product_type}
              </div>
              <div className="subheadingbreak">Product Type</div>
            </div>
            <div>
              <div className="headingbreak">
                {formatCurrency(invoiceData?.disbursal_amount ||
                  invoiceData?.payouts?.partnerleads.disbursal_amount)}
              </div>
              <div className="subheadingbreak">Disb. Amount</div>
            </div>
            <div>
              <div className="headingbreak">
                {new Date(
                  invoiceData?.disbursal_date ||
                    invoiceData?.payouts?.partnerleads.disbursal_date
                ).toLocaleDateString()}
              </div>
              <div className="subheadingbreak">Date</div>
            </div>
            <div>
              <div className="headingbreak">{formatCurrency(invoiceData.payout_amount)}</div>
              <div className="subheadingbreak">Invoiced Amt</div>
            </div>
          </div>

          <div className="breakshowdetails">
            <div className="breakleftflot">
              <div className="mainheadingpayout">Total Payout</div>
              <div className="breakamountshow">
                <div className="amountdetailsname">Disbursed Amount</div>
                <div className="amountdetailsprice">
                  ₹{" "}
                  {invoiceData?.disbursal_amount ||
                    invoiceData?.payouts?.partnerleads.disbursal_amount}{" "}
                </div>
              </div>

              <div className="breakamountshow">
                <div className="amountdetailsname">Payout % of disbursal</div>
                <div className="amountdetailsprice">
                  {" "}
                  {parseFloat(invoiceData?.payout_per ||
                    invoiceData?.payouts?.payout_per).toFixed(3)}
                  %
                </div>
              </div>

              <div className="breakamountshow">
                <div className="amountdetailsname">Gross Payout</div>
                <div className="amountdetailsprice">₹  {invoiceData?.payout_amount ||
                    invoiceData?.payouts?.payout_amount}</div>
              </div>

       

              {/* <div className="breakamountshow">
                <div className="amountdetailsname">TDS</div>
                <div className="amountdetailsprice">-₹3,800</div>
              </div> */}
            </div>

            <div className="breakrightflot">
              <div className="mainheadingpayout"> Payout Point</div>
              <div>
                <div className="menu-tab-left-panel-new">
                  <ul>
                    {/* {JSON.stringify(payout_point)} */}
                    {payout_point && payout_point.length>0 && payout_point.map(point=>{
                      return ( <li>
                        <p
                          className={
                            point?.status =='1'
                              ? "completed"
                               : "active"
                          }
                        >
                          <div className="img-type"></div>
                          <div className="showhistory">
                            <div className="amountdetailsname">
                              {point.paid_per}% @ {point.point}
                            </div>
                            <span className="amountdetailsprice">₹  {point.payout_amount}</span>
                          </div>
                        </p>
                        </li> )
                    })
                     
                    }
                   
                   
                  </ul>
                </div>

                {/* <div className="showhistory">
                  <div className="mainheadingpayout">Booster</div>
                  <div className="checknowbtn">Check Now</div>
                </div>

                <div className="breakamountshow">
                  <div className="amountdetailsname">
                    Booster payout % of disbursal{" "}
                  </div>
                  <div className="amountdetailsprice">0.2%</div>
                </div> */}
              </div>
            </div>
          </div>
        </>
      )}

      {selectedOption === "scheme" && schemeData && (
        <>
          <div className="breaktabs">
            <div>
              <button
                className={currentView === "self" ? "active" : ""}
                // onClick={() => handleTabClick("self")}
              >
                For Self-Fulfilled Cases
              </button>
              <button
                className={currentView === "scheme" ? "active" : ""}
                // onClick={() => handleTabClick("scheme")}
              >
                Scheme
              </button>
            </div>
          </div>

          {currentView === "self" && <div>Hello</div>}

          {currentView === "scheme" && (
            <>
              <div className="schememonth">{schemeData[0]?.scheme?.scheme_name}</div>

              <div className="schemedate">
                <img
                  src={require(`../../../src/saathi-web/images/dateicon.svg`)}
                  alt="not"
                  width={16}
                  height={16}
                  style={{ marginRight: 10 }}
                />
                <div>
                  {/* {new Date(schemeData[0]?.scheme?.start_date).toLocaleDateString()} */}
                  {moment(schemeData[0]?.scheme?.start_date).format('Do MMM')} to {moment(schemeData[0]?.scheme?.end_date).format('Do MMM YYYY')}
                </div>
              </div>

              <div className="schemeshowtable">
                <div className="table-container">
                  <table>
                    <thead>
                      <tr className="tablesticky">
                        <th>Type</th>
                        <th>Slab</th>
                       {partner_type === "1" && <th>Partner Payout %</th>}
                        {partner_type === "1" ? <th>Self Payout %</th> :  <th>Total Payout %</th>}
                      {partner_type === "1" &&  <th>Total Payout %</th>}
                      </tr>
                    </thead>
                    <tbody>
                     
                      {schemeData[0]?.schemerange?.map((data) => (
                        <tr key={data.id}>

                          <td>{schemeData[0]?.loan_type}</td>
                          <td>
                         { getRangeLabel(data?.start_range)} - {getRangeLabel(data?.end_range)}
                          </td>
                          {/* <td>{partner_type == "parent_partner" ? data?.payout : data?.partner_payout}%</td> */}
                          { partner_type === "1" && <td>{data?.partner_payout}%</td>}
                          <td>{parseFloat(parseFloat(data?.payout)-parseFloat(data?.partner_payout)).toFixed(3)}%</td>
                          {partner_type === "1" && <td>{data?.payout}%</td>}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MyEarningsPopup;
