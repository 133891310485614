import React, { useState } from "react";
import Select from "react-select";
import InputField from "../../view/elements/Input";
import Modal from "../../view/common/Modal";
import { useHistory } from "react-router-dom";
import { handlePriceInputIndian } from "../MyLeads/helpers/helpers";

const CibilAdditionalDetails = ({ handleAdditionInfoButton }) => {
  const history = useHistory();
  
  // Manage form data in a single state object
  const [formData, setFormData] = useState({
    loan_type: "homeloan",
    loan_sub_type: [],
    required_loan: "",
    employment_type: null,
    monthly_salary: ""
  });

  const [openModal, setOpenModal] = useState(false);

  // Handle modal open/close and manage body overflow
  const ShowPopup = () => {
    handleAdditionInfoButton(true);
    setOpenModal(!openModal);
    document.body.classList.toggle("overflow-hidden", openModal);
  };

  const loanTypes = [
    { name: "salaried", id: 1 },
    { name: "self-employeed", id: 2 }
  ];

  // Handle tab switching for loan types
  const handleTabClick = (loanType) => {
    setFormData({ ...formData, loan_type: loanType });
  };

  // Handle checkbox changes
  const handleLoanSubTypeChange = (event) => {
    const { value, checked } = event.target;
    const updatedLoanSubType = checked
      ? [...formData.loan_sub_type, value]
      : formData.loan_sub_type.filter((item) => item !== value);

    setFormData({ ...formData, loan_sub_type: updatedLoanSubType });
  };

  // Handle other input changes
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if(value){
      value=value.replace(/[^0-9]/g, '')
    }
    setFormData({ ...formData, [name]: value });
  };

  // Handle employment type change from the Select dropdown
  const handleEmploymentTypeChange = (selectedOption) => {
    setFormData({ ...formData, employment_type: selectedOption });
  };

  const handleReport = () => {
    history.push("/my-tool-cibil");
    history.push("/my-credit-reports");
  };
 
  
  return (
    <>
      <div className="mytoolverification">
        <h2 className="verificationheadingmain">Additional Details</h2>
        
        <div>Loan Type*</div>

        <div className="cibilloanflex">
          {/* Loan Type Tabs */}
          <div
            className={`cibilhometab ${formData.loan_type === "homeloan" ? "active" : ""}`}
            onClick={() => handleTabClick("homeloan")}
          >
            {formData.loan_type === "homeloan" ? (
              <img
                src="https://ambak.storage.googleapis.com/partner/2123/1724129825863.svg"
                alt="home"
                width={20}
                height={20}
              />
            ) : (
              <img
                src="https://ambak.storage.googleapis.com/partner/2123/1724129398984.svg"
                alt="loan"
                width={20}
                height={20}
              />
            )}
            <span>Home Loan</span>
          </div>

          <div
            className={`cibilhometab ${formData.loan_type === "lap" ? "active" : ""}`}
            onClick={() => handleTabClick("lap")}
          >
            {formData.loan_type === "lap" ? (
              <img
                src="https://ambak.storage.googleapis.com/partner/2123/1724130094744.svg"
                alt="home"
                width={20}
                height={20}
              />
            ) : (
              <img
                src="https://ambak.storage.googleapis.com/partner/2123/1724129903084.svg"
                alt="loan"
                width={20}
                height={20}
              />
            )}
            <span>Lap</span>
          </div>
        </div>

        {/* Loan Sub-Type Checkboxes */}
        <div className="loanfulfillment">
          <div className="loaninput">
            <input
              type="checkbox"
              id="balancetransfer"
              value="1"
              checked={formData.loan_sub_type.includes("1")}
              onChange={handleLoanSubTypeChange}
            />
            <label htmlFor="balancetransfer">Balance Transfer</label>
          </div>
          <div className="loaninput">
            <input
              type="checkbox"
              id="top-up"
              value="2"
              checked={formData.loan_sub_type.includes("2")}
              onChange={handleLoanSubTypeChange}
            />
            <label htmlFor="top-up">Top-Up</label>
          </div>
        </div>

        <div className="row">
          {/* Required Loan Amount */}
          <div className="col-sm-6">
            <InputField
              type="text"
              name="required_loan"
              id="required_loan"
              placeholder="Required Loan Amount"
              label="Required Loan Amount"
              value={handlePriceInputIndian(formData.required_loan)}
              onChange={handleInputChange}
            />
          </div>

          {/* Employment Type */}
          <div className="col-sm-6">
            <label>Employment Type</label>
            <Select
              id="employment_type"
              options={loanTypes}
              onChange={handleEmploymentTypeChange}
              value={formData.employment_type}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          </div>
        </div>

        {/* Monthly Salary */}
        <div className="row">
          <div className="col-sm-6">
            <InputField
              type="text"
              name="monthly_salary"
              id="monthly_salary"
              placeholder="Take Home Monthly Salary"
              label="Take Home Monthly Salary"
              value={formData.monthly_salary}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <div className="allcustomerinfo">
        All customer information is secure and no customer will be directly
        reached out without your permission
      </div>

      {/* Send Link Button */}
      <div className="buttonflexmobile">
        <button className="cibilcheckbtn" onClick={ShowPopup}>
          Send Link
        </button>
      </div>

      {/* Modal */}
      <Modal isOpen={openModal} togglePopup={ShowPopup} width={800} removeHeight={true}>
        <div className="cibilgiffile">
          <img
            src={require("../../saathi-web/images/pingball.gif")}
            alt="Loading"
            width={150}
            height={150}
          />
          <h4 className="verificationheadingmain">
            Waiting for customer’s consent
          </h4>
          <p>
            Consent Link has been Shared with the Customer via SMS. You can also
            copy the link below and send to your customer. They will receive an
            OTP on clicking on the link.
          </p>
          <div className="cibillink">
            <a href="https:/ambak.consentlink.com" target="_blank">
              https:/ambak.consentlink.com
            </a>
            <div className="cibillinkcopy">
              <i className="ic-content_copy">Copy</i>
            </div>
          </div>
        </div>
        <div className="buttonflexmobile">
          <button className="cibilcheckbtn" onClick={handleReport}>
            Go To Lead
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CibilAdditionalDetails;
