import React from "react";
import LoanOfferITR from "./LoanOfferITR";
import LoanOfferBanking from "./LoanOfferBanking";

const LoanOfferIncomeDetailsSE = ({
  breData,
  handleBreDataChange,
  errors,
  setErrorFields,
}) => {
  const handleFieldsValue = (key, value, price = false) => {
    if (price) {
      value = value.replace(/[^0-9]/g, "");
    }
    handleBreDataChange(key, value);
  };

  return (
    <>
      <div className="allheadingincome">
        What income do you want to consider?
      </div>
      <div className="cibilloanflex">
        <div
          className={`cibilhometab ${
            breData?.income_calculation_mode === "itr" ? "active" : ""
          }`}
          onClick={() => handleFieldsValue("income_calculation_mode", "itr")}
        >
          <span>ITR Income</span>
        </div>

        <div
          className={`cibilhometab ${
            breData?.income_calculation_mode === "bank" ? "active" : ""
          }`}
          onClick={() => handleFieldsValue("income_calculation_mode", "bank")}
        >
          <span>Banking Income</span>
        </div>
      </div>

      {breData?.income_calculation_mode === "itr" && (
        <LoanOfferITR
          breData={breData}
          handleBreDataChange={handleBreDataChange}
        />
      )}

      {breData?.income_calculation_mode === "bank" && (
        <LoanOfferBanking
          breData={breData}
          handleBreDataChange={handleBreDataChange}
          errors={errors}
          setErrorFields={setErrorFields}
        />
      )}
    </>
  );
};

export default LoanOfferIncomeDetailsSE;
