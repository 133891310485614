import React, { useState } from 'react';
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import { useHistory } from 'react-router-dom';
import crypto from '../../config/crypto'
import  LoginDetails  from '../StatusUpdate/LoginDetails';


const MoveToStatusTabs = (props) => {
    const { leadDetail, masterdata, masterStatusData} = props;  
    const [currentStep, setCurrentStep] = useState(1) ;
    let history = useHistory();
    
   const handleStepClick = (step) => {
    let url = '' ; 
    if(step === 1){
     url =  `/loggedin/${crypto.encode(leadDetail?.id)}`;
    } else if(step === 2){
      const isValid = checkValidDetails("logged_in");
      if (!isValid) return isValid;
      url =  `/approved/${crypto.encode(leadDetail?.id)}`;
    } else if(step === 3){
      const isValidLoggedIn = checkValidDetails("logged_in");
      const isValidSanctioned = checkValidDetails("sanctioned");
      if (!(isValidLoggedIn && isValidSanctioned)) return isValidLoggedIn;
      url = `/disbursed/${crypto.encode(leadDetail?.id)}`;
    } 
       
    history.push(url)
   };
   

   const checkValidDetails = (detailsType) => {
    const {
      login_amount,
      login_number,
      bank_id,
      login_date,
      approval_date,
      approval_number,
      approved_amount,
      approved_emi,
    } = (leadDetail?.lead_details || {});
    if (detailsType === "logged_in") {
      if (login_amount && login_number && bank_id && login_date) return true;
    } else if (detailsType === "sanctioned") {
      if (approval_date && approval_number && approved_amount && approved_emi) return true;
    }
    return false;
   }
  

   return (
            <>
          
           <div className="editleadnew">
          <div className="lefteditleadcont">
          <section className="step-wizard">
            <ul className="step-wizard-list">
            {[1, 2, 3].map((stepNumber) => (
          <li
            key={stepNumber}
            className={`step-wizard-item ${currentStep === stepNumber ? 'current-item' : ''}`}
            onClick={() => handleStepClick(stepNumber)}
          >
            <span className="progress-count">{stepNumber} </span>
          </li>
        ))}
            </ul>
            <ul className="Editlead_flowlis">
              <li onClick={() => handleStepClick(1)}>Logged IN</li>
              <li onClick={() => handleStepClick(2)}>Sanctioned</li>
              <li onClick={() => handleStepClick(3)}>Disbursed</li>                        
            </ul>
          </section>
        </div>
        <div className="righteditleadcont">          
          {currentStep === 1 && <LoginDetails leadDetail = {leadDetail} masterdata = {masterdata} masterStatusData = {masterStatusData} currentStep = {currentStep} handleStepClick = {handleStepClick}/>}
          </div>
      </div>
          
</>
        )
}
export default MoveToStatusTabs;