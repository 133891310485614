import React, { useEffect, useState, useMemo } from "react";
import Formsy from 'formsy-react';
import "../../../src/saathi-web/saathi-web-style/mainlead.css";
import InputField from "../../view/elements/Input";
import FormsyInputField from '../../view/elements/FormsyInputField';
import FormsySelect from '../../view/elements/FormsySelect';
import Select from "react-select";
import FormFields from "../../../src/saathi-web/MyLeads/formFields/customerDetailsFormFields.json";
import { SAVE_LOAN_DETAILS } from "../../queries/sangam/customer.gql";
import { executeGraphQLQueryFinex,executeGraphQLMutationFinex } from "../../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import { LEAD_DETAIL_QUERY } from '../../queries/sangam/leads.gql';
import Loader from '../../view/elements/PageLoader';
import { useHistory, useParams } from "react-router-dom";
const FORM_FIELDS = FormFields["LOAN_DETAILS"];

const LoanDetails = ({leadDetail,masterdata,masterStatusData,currentStep,handleStepClick}) => {
  const params = useParams();
  const history = useHistory();
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  //const [selectedRadioOption, setSelectedRadioOption] = useState("self");
  const [selectedLoanType, setSelectedLoanType] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [loanDetails, setLoanDetails] = useState({
    fulfillment_type: 'self',
    loan_type:""
  });

useEffect(() => {
  if (leadDetail && leadDetail.customer && leadDetail.lead_details) {    
    let loan_details = { ...leadDetail.lead_details, fulfillment_type: leadDetail?.fulfillment_type, loan_type: leadDetail?.loan_type, loan_sub_type: leadDetail?.loan_sub_type?.split(',') };
    loan_details["re_account_number"] = loan_details["account_number"] || "";
    setLoanDetails(loan_details);
  }
}, [leadDetail]);

  const handleInputChange = (event) => {
    if (event.target.name) {
      setLoanDetails(currentValue => ({
        ...currentValue,
        [event.target.name]: event.target.value
      }))
    }
  }

  const handleLoanTypeChange = (event) => {
    setSelectedLoanType(event.target.value);
    const { id, value } = event.target;
    setLoanDetails(prevState => ({
      ...prevState,
      loan_type: value,
    }));    
  };

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata } : {};
    return options;
  }, [masterdata]);


  const handleMultiCheckbox = event => {
    if (event.target.name) {
      let loan_sub_type = (loanDetails?.loan_sub_type?.length && loanDetails.loan_sub_type)  || [];
      if (event.target.checked) loan_sub_type.push(event.target.value);
      else {
          const index = loan_sub_type.indexOf(event.target.value);
          if (index > -1) loan_sub_type.splice(index, 1); 
      }
      setLoanDetails(currentValue => ({ ...currentValue, loan_sub_type: loan_sub_type }))
    }
  }

  const handlePatternFormatChange = (data, field_name) => {
    if (field_name && data.value !== undefined) {
      setLoanDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setLoanDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.id
      }))
    }
  }

  const handleSubmit = (isExitPage = false) => {
    setIsSubmitDisabled(true);
    let lead_id = leadDetail?.id || "";
    let mutation = SAVE_LOAN_DETAILS,
      variables = {
        api_called_by: 'saathi_web',
        partner_id: loginUserInfo?.dealer_id,
        LeadAdditionalInput: {
          lead_id: lead_id,
          lead_details: {
            loan_amount: loanDetails.loan_amount,
            tenure: +loanDetails?.tenure || 1,
            fulfillment_type: loanDetails.fulfillment_type,
            loan_type: +loanDetails.loan_type,
            loan_sub_type: loanDetails?.loan_sub_type?.join(",") || '',
          }
        }
      };
executeGraphQLMutationFinex(mutation, variables).then( async resp => {
      let response = resp?.data?.LeadAdditionalInput || null;
      if (response && !response.success) {
        setIsSubmitDisabled(false);
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      setIsSubmitDisabled(false);
    await getLoanDetail(lead_id)
      if (isExitPage) return history.push(`/saathi-details-page/${params.lead_id}`);
     handleStepClick(2);
    })
      .catch(err => {
        setIsSubmitDisabled(false);
        toast.error(err.message);
      })
  }

  const getLoanDetail = async (lead_id) => {
    setIsLoading(true);
    setIsSubmitDisabled(true);
   //let lead_id = leadDetail?.id || "";
    if(!lead_id) lead_id = 0;
    const result = await executeGraphQLQueryFinex(LEAD_DETAIL_QUERY(lead_id));
    if(result?.data?.get_lead_detail[0]){
      let loanDetail = result?.data?.get_lead_detail[0];
      let loan_details = { ...loanDetail.lead_details, fulfillment_type: loanDetail?.fulfillment_type, loan_type: loanDetail?.loan_type, loan_sub_type: loanDetail?.loan_sub_type?.split(',') };
      setLoanDetails(loan_details);
      setIsLoading(false);
      setIsSubmitDisabled(false);
    }
    
  };

  let LoanDetailsForm = FORM_FIELDS;

  return (
    <>
      <div>
      { isLoading ? <Loader/> : ''}
        <h4>Loan Details</h4>
        <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={() => handleSubmit(false)}
          >
            <h6  className="allheadingincome">Who will be fulfilling this lead?</h6>

            <div className="cibilloanflex">
                <div
                    className={`cibilhometab ${loanDetails.fulfillment_type === "self" ? "active" : ""}`}
                    onClick={() => setLoanDetails(prevState => ({ ...prevState, fulfillment_type: "self" }))}
                >
                    <span>I will do it myself</span>
                </div>

                <div
                    className={`cibilhometab ${loanDetails.fulfillment_type === "ambak" ? "active" : ""}`}
                    onClick={() => setLoanDetails(prevState => ({ ...prevState, fulfillment_type: "ambak" }))}
                >
                    <span>Refer to Ambak</span>
                </div>
            </div>
            {/* <div className='loanfulfillment'>
              <div className='loaninput'>
                <input type="radio" required id="ambakFulfillment" name="fulfillment_type" value="ambak"  checked={loanDetails.fulfillment_type === "ambak"} onChange={handleRadioChange} />
                <label htmlFor='ambakFulfillment'> Ambak Fulfillment</label>
              </div>
              <div className='loaninput'>
                <input type="radio" required id="selfFulfillment" name="fulfillment_type" value="self"  checked={loanDetails.fulfillment_type === "self"} onChange={handleRadioChange} />
                <label htmlFor='selfFulfillment'> Self Fulfillment</label>
              </div>
            </div> */}

            <div className="row">
              {LoanDetailsForm.map((field, index) => (
                ["text", "password", "pattern-format", "number-format"].includes(field.type) ? (
                  <fieldset className="col-md-6" key={index}>
                  
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={loanDetails && loanDetails[field.name]}
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={','}
                      decimalSeparator={'.'}
                      label={field.label}
                      onChange={
                        ["pattern-format", "number-format"].includes(field.type)
                          ? (data) => handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={field.validations}
                      validationError={
                        loanDetails[field.name] ? field.validationError : ""
                      }
                      required={field.required}
                    />
                  </fieldset>
                ) :

                  field.type === "dropdown" ? (
                    <fieldset className="col-md-6" key={index}>
                     <label>Desired Tenure</label> 
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                          options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && 
                          ALL_OPTIONS[field.optionsKey].filter((option) => option.value <= 30), // Restrict options to <= 30                          placeholder: "",
                          className: "react-select",
                          classNamePrefix: "react-select",
                          value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter((value) => value.id == loanDetails[field.name]),
                          isDisabled: field.readOnly
                        }}
                        required={field.required}
                        value={loanDetails[field.name]}
                        onChange={(data) => handleSelectChange(data, field.name)}
                      />
                    </fieldset>
                  ) : null
              ))}             

            </div>
            <div className='loanfulfillment'>
              <div className='loaninput'>
                <input type="radio" required id="homeloan" name="loan_type" value="1" checked={selectedLoanType == 1} onChange={handleLoanTypeChange} />
                <label htmlFor='homeloan'>Home Loan</label>
              </div>
              <div className='loaninput'>
                <input type="radio" required id="loanproperty" name="loan_type" value="2" checked={selectedLoanType == 2} onChange={handleLoanTypeChange} />
                <label htmlFor='loanproperty'>Loan Against Property</label>
              </div>
             </div>

             <div className='loanfulfillment'>
              <div className='loaninput'>
                <input type="checkbox" id="balancetransfer" name="bt" value="1" checked={loanDetails?.loan_sub_type?.includes("1")} onChange={handleMultiCheckbox} />
                <label htmlFor='balancetransfer'>Balance Transfer</label>
              </div>
              <div className='loaninput'>
                <input type="checkbox" id="top-up" name="top_up" value="2" checked={loanDetails?.loan_sub_type?.includes("2")} onChange={handleMultiCheckbox} />
                <label htmlFor='top-up'>Top-Up</label>
              </div>
            </div>

        <div className="saveandexit">
        <button type="button" className="markloatbrn" onClick={() => handleSubmit(true)} >Save & Exit</button>
        <button type="submit" className="editnextbtn1" disabled={isSubmitDisabled} >Next</button>
        </div>
        </Formsy>
      </div>
      
    </>
  );
};

export default LoanDetails;
