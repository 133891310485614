import React, { useEffect, useState } from "react";
import { handlePriceInputIndian } from "../../../MyLeads/helpers/helpers";
import InputField from "../../../../view/elements/Input";

const LoanOfferITR = ({
  breData,
  handleBreDataChange,
  customerType = "customer",
  coApplicantOfferDetails = [],
  coAppIndex = null,
}) => {
  const [activeTab, setActiveTab] = useState("year_1");

  useEffect(() => {
    let itrDetails = [];
    if (customerType === "customer") {
      itrDetails = breData.offer_itr_details;
    } else {
      itrDetails = coApplicantOfferDetails;
    }
    const obj = {};
    for (let i = 0; i < itrDetails?.length; i++) {
      if (itrDetails[i].itr_year === "1") {
        obj["year_1"] = { ...itrDetails[i] };
      }
      if (itrDetails[i].itr_year === "2") {
        obj["year_2"] = { ...itrDetails[i] };
      }
    }
    handleBreDataChange(null, null, obj);
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleValueChange = (event) => {
    const { name, value } = event.target;
    const obj = breData[activeTab] ? { ...breData[activeTab] } : {};
    obj[name] = value.replace(/[^0-9]/g, "");
    handleBreDataChange(activeTab, obj);
  };

  let npatVal = "",
    depreciationVal = "",
    interestval = "",
    taxPaidVal = "";
  if (customerType === "customer") {
    if (breData[activeTab]) {
      npatVal = breData[activeTab].npat;
      depreciationVal = breData[activeTab].depreciation;
      interestval = breData[activeTab].interest;
      taxPaidVal = breData[activeTab].tax_paid;
    }
  } else {
    if (breData.co_applicant[coAppIndex][activeTab]) {
      npatVal = breData.co_applicant[coAppIndex][activeTab].npat;
      depreciationVal =
        breData.co_applicant[coAppIndex][activeTab].depreciation;
      interestval = breData.co_applicant[coAppIndex][activeTab].interest;
      taxPaidVal = breData.co_applicant[coAppIndex][activeTab].tax_paid;
    }
  }

  return (
    <>
      <div className="cibilloanflex">
        <div
          className={`cibilhometab ${activeTab === "year_1" ? "active" : ""}`}
          onClick={() => handleTabChange("year_1")}
        >
          <span>Year 1</span>
        </div>

        <div
          className={`cibilhometab ${activeTab === "year_2" ? "active" : ""}`}
          onClick={() => handleTabChange("year_2")}
        >
          <span>Year 2</span>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-4">
          <InputField
            id="npat"
            name="npat"
            type="text"
            maxLength={12}
            placeholder="NPAT"
            value={handlePriceInputIndian(npatVal)}
            label="NPAT"
            onChange={handleValueChange}
          />
        </div>
        <div>
          <div className="col-sm-4">
            <InputField
              id="depreciation"
              name="depreciation"
              type="text"
              maxLength={12}
              label="Depreciation"
              placeholder="Depreciation"
              value={handlePriceInputIndian(depreciationVal)}
              onChange={handleValueChange}
            />
          </div>
          <div className="col-sm-4">
            <InputField
              id="interest"
              name="interest"
              type="text"
              maxLength={12}
              label="Interest"
              placeholder="Interest"
              value={handlePriceInputIndian(interestval)}
              onChange={handleValueChange}
            />
          </div>
          <div className="col-sm-4">
            <InputField
              id="tax_paid"
              name="tax_paid"
              type="text"
              maxLength={12}
              label="Tax paid"
              placeholder="Tax paid"
              value={handlePriceInputIndian(taxPaidVal)}
              onChange={handleValueChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanOfferITR;
