import React, { Component } from "react";
import InputField from './../../elements/InputField'
import Select from 'react-select';
import Button from '../../elements/Button'
import DatePicker from "react-datepicker";
import DateFormate from 'dateformat';
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { MOBILE_VALIDATION } from './../../../config/constant';
import { Editor } from '@tinymce/tinymce-react';
import { InventoryService } from './../../../service';
import { DESCRIPTION_FIELD_MIN_LENGTH } from './../../../config/constant';
const CURRENCY_SEPARATOR = ".";

class CertificationEdit extends Component {
    constructor(props) {
        super(props);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.editorInstanse = {};
        this.state = {
            currentIndex: this.props.currentIndex,
            formData: {
                car_id: [props.carId],
                dealer_id: this.props.dealer_id
            },
            car_id_hash: this.props.car_id_hash,
            errors: {}
        };
    }

    componentDidMount = async () => {
        this.getCarDetail();
    }

    getCarDetail = async () => {
        let getUpdatePostdata = {
            car_hash: this.state.car_id_hash
        };
        let thisObj = this;
        InventoryService.getCarDetail(getUpdatePostdata)
            .then((response) => {
                if (response.data.status == 200) {
                    let responseCardetail = response.data.data;
                    thisObj.setState({
                        redirectToList: false,
                        formData: {
                            ...thisObj.state.formData,
                            stock_used_car_id: responseCardetail.id,
                            display_price: this.__numberWithCommas(responseCardetail.car_price),
                            // display_km: this.__numberWithCommas(responseCardetail.km_driven),
                            // km_driven: responseCardetail.km_driven,
                            car_price: responseCardetail.car_price,
                            description: responseCardetail.description
                        }
                    });
                }
                else {
                    thisObj.setState({ redirectToList: true });
                }
            }).catch((error) => {
            })
    }


    __numberWithCommas = (num) => {
        num = num == null ? "" : "" + num;
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + CURRENCY_SEPARATOR)
    }

    __numToWords = n => {
        if (!n) return '';
        var string = n.toString(), units, tens, scales, start, end, chunks, chunksLen, chunk, ints, i, word, words, and = 'and';
        /* Remove spaces and commas */
        string = string.replace(/[, ]/g, "");
        /* Is number zero? */
        if (parseInt(string) === 0) {
            return 'zero';
        }
        /* Array of units as words */
        units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
        /* Array of tens as words */
        tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
        /* Array of scales as words */
        scales = ['', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion'];
        /* Split user arguemnt into 3 digit chunks from right to left */
        start = string.length;
        chunks = [];
        while (start > 0) {
            end = start;
            chunks.push(string.slice((start = Math.max(0, start - 3)), end));
        }

        /* Check if function has enough scale words to be able to stringify the user argument */
        chunksLen = chunks.length;
        if (chunksLen > scales.length) {
            return '';
        }

        /* Stringify each integer in each chunk */
        words = [];
        for (i = 0; i < chunksLen; i++) {

            chunk = parseInt(chunks[i]);

            if (chunk) {

                /* Split chunk into array of individual integers */
                ints = chunks[i].split('').reverse().map(parseFloat);

                /* If tens integer is 1, i.e. 10, then add 10 to units integer */
                if (ints[1] === 1) {
                    ints[0] += 10;
                }

                /* Add scale word if chunk is not zero and array item exists */
                if ((word = scales[i])) {
                    words.push(word);
                }

                /* Add unit word if array item exists */
                if ((word = units[ints[0]])) {
                    words.push(word);
                }

                /* Add tens word if array item exists */
                if ((word = tens[ints[1]])) {
                    words.push(word);
                }

                /* Add 'and' string after units or tens integer if: */
                if (ints[0] || ints[1]) {

                    /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
                    if (ints[2] || (i + 1) < chunksLen) { words.push(and); and = ''; }

                }

                /* Add hundreds word if array item exists */
                if ((word = units[ints[2]])) {
                    words.push(word + ' hundred');
                }

            }

        }

        return words.reverse().join(' ');
    }

    handleChangetext = (event) => {
        let formData = { ...this.state.formData };
        if (event.target.name == 'car_price' || event.target.name == 'km_driven') {
            var replace = "[" + CURRENCY_SEPARATOR + "]";
            var re = new RegExp(replace, "g");
            event.target.value = event.target.value.replace(re, '');
            var er = /^-?[0-9]+$/;
            if (er.test(event.target.value)) {
                formData[event.target.name] = event.target.value;
                if (event.target.name == 'car_price') {
                    formData['display_price'] = this.__numberWithCommas(event.target.value);
                }
                // else if (event.target.name == 'km_driven') {
                //     formData['display_km'] = this.__numberWithCommas(event.target.value);;
                // }
            }
            else if (event.target.value == '') {
                formData[event.target.name] = event.target.value;
                if (event.target.name == 'car_price') {
                    formData['display_price'] = '';
                }
                // else if (event.target.name == 'km_driven') {
                //     formData['display_km'] = '';
                // }
            }
        }
        else {
            formData[event.target.name] = event.target.value;
        }
        this.setState({
            formData: formData
        });
    }



    submitForm = (event) => {
        event.preventDefault();
        if (this.validFormData()) {
            let formData = this.state.formData;
            let updateJsonRow = {};
            // updateJsonRow.km_driven = formData.km_driven;
            updateJsonRow.car_price = formData.car_price;
            updateJsonRow.description = formData.description;
            formData.qc_status = '2';
            MasterService.post('inventory/inventory/updateusedstockcar', formData)
                .then((response) => {
                    if (response.status == 200 && response.data.status == 200) {
                        if (typeof this.props.updateStockData === 'function') {
                            this.props.updateStockData(updateJsonRow, this.state.currentIndex);
                        }
                        toast.success(response.data.message);
                        this.props.modalClose();
                    }
                    else {
                        toast.error(response.data.message);
                    }
                }).catch((error) => {

                })
        }
    }

    validFormData = () => {
        let formData = this.state.formData;
        let errors = {};
        let formIsValid = true;
        var numbers = /^[0-9]+$/;
        let descriptionCount = this.editorInstanse.plugins.wordcount.body.getCharacterCount();
        if (formData['description'] != null && descriptionCount < DESCRIPTION_FIELD_MIN_LENGTH && descriptionCount != 0) {
            formIsValid = false;
            errors["description"] = this.props.t('addInventory.validation_error.description_char_limit');
        }
        // if (!formData['km_driven']) {
        //     formIsValid = false;
        //     errors["km_driven"] = this.props.t('addInventory.validation_error.Car_driven_km_is_required', { stock_type: this.state.stockType });
        // } else if ((formData['km_driven'].toString()).match(numbers) == null) {
        //     formIsValid = false;
        //     errors["km_driven"] = this.props.t('addInventory.validation_error.Car_driven_should_numeric_characters_only', { stock_type: this.state.stockType });
        // }
        // else if (formData['km_driven'] < 1000 || formData['km_driven'] > 1000000) {
        //     formIsValid = false;
        //     errors["km_driven"] = this.props.t('addInventory.validation_error.Car_driven_should_less_than', { stock_type: this.state.stockType });
        // }
        if (!formData['car_price']) {
            formIsValid = false;
            errors["car_price"] = this.props.t('addInventory.validation_error.Car_price_is_required', { stock_type: this.state.stockType });
        } else if (formData['car_price'].toString().match(numbers) == null) {
            formIsValid = false;
            errors["car_price"] = this.props.t('addInventory.validation_error.Car_price_should_numeric_characters_only', { stock_type: this.state.stockType });
        }
        if (!formIsValid) {
            toast.error(this.props.t('addInventory.validation_error.fill_required_fields'));
        }
        this.setState({ errors: errors })
        return formIsValid;
    }

    handleEditorChange = (content, editor) => {
        this.setState({
            formData: {
                ...this.state.formData,
                description: content
            }
        });
    }

    setDescriptionEditorInstanse = (editorInstanse) => {
        if (Object.keys(this.editorInstanse).length === 0) {
            this.editorInstanse = editorInstanse;
        }
    }

    render() {
        const { sourceList, statusList, budgetList, formData, errors } = this.state;
        let css = '';
        if (errors) {
            css = `.form-error {display:block}`;
        }

        return (
            <div>
                <style type="text/css">
                    {css}
                </style>
                <div className="row">
                    {/* <div className="col-sm-6 col-md-6">
                        <InputField
                            inputProps={{
                                id: "km_driven",
                                type: "text",
                                maxLength: 8,
                                placeholder: this.props.t('addInventory.km'),
                                name: "km_driven",
                                autocompleate: "off",
                                label: this.props.t('addInventory.km_driven'),
                                value: (formData.display_km) ? formData.display_km : '',
                                dataerror: ''
                            }}
                            onChange={this.handleChangetext}
                        />
                        <span className="num-words">{this.__numToWords(this.state.formData.km_driven)}</span>
                    </div> */}
                    <div className="col-sm-12 col-md-12">
                        <InputField
                            inputProps={{
                                id: "car_price",
                                placeholder: this.props.t('addInventory.price'),
                                name: "car_price",
                                autocompleate: "off",
                                type: "text",
                                maxLength: 15,
                                label: this.props.t('addInventory.price'),
                                value: formData.display_price ? formData.display_price : '',
                                dataerror: ''
                            }}
                            onChange={this.handleChangetext}
                        />
                        <span className="num-words">{this.__numToWords(this.state.formData.car_price)}</span>
                    </div>
                </div>
                <h6>{this.props.t('addInventory.desc')}</h6>
                <div className="row">
                    <div className="col-sm-12 form-group ">
                        {/* <textarea name="description" placeholder={this.props.t('addInventory.additional_details_car')} className="form-control en-textarea" onChange={this.handleChangetext} value={formData.description ? formData.description: ''}></textarea> */}

                        <Editor
                            key={formData.stock_used_car_id || 1}
                            apiKey="9gh0yn6tndfh684vhu2ibdccw57q3n2pks1mxrmqyvqzmhaa"
                            initialValue={formData.description ? formData.description : ''}
                            init={{
                                height: 200,
                                menubar: false,
                                branding: false,
                                placeholder: this.props.t('addInventory.additional_details_car'),
                                init_instance_callback: (editor) => {
                                    this.setDescriptionEditorInstanse(editor);
                                    document.querySelector('button.tox-statusbar__wordcount').click();
                                    document.querySelector('.tox .tox-editor-header').style.zIndex = '0';
                                },
                                plugins: [
                                    'advlist autolink lists link charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic underline | \
                                                        alignleft aligncenter alignright alignjustify | \
                                                        bullist numlist outdent indent | removeformat'
                            }}
                            onEditorChange={this.handleEditorChange}
                        />
                        <span className="form-error">{errors.description}</span>
                    </div>
                </div>

                <div className="col-sm-6 col-md-12 form-group text-right">
                    <Button onClick={this.props.modalClose} colclass="col-sm-12" btnClass="btn btn-link mrg-r15" type="reset" name="addleadbtn" id="cancel" title={this.props.t('listInventory.cancel')} />
                    <Button onClick={this.submitForm} colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title={this.props.t('listInventory.save')} />
                </div>


            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

export default withTranslation('inventory')(connect(mapStateToProps)(CertificationEdit));