import React, { useState } from "react";
import { toast } from "react-toastify";

const MobileAlready = ({
  leadList,
  handleDataFromMobileAlready,
  makeNewLead,
  source,
}) => {
  const [selectedRadioOption, setSelectedRadioOption] = useState("");
  const [lead_data, setLead_data] = useState({});

  const handleRadioChange = (event) => {
    setSelectedRadioOption(event.target.value);
  };

  const handleInput = (data) => {
    setLead_data(data);
  };

  const handleNext = () => {
    if (source !== "offer") {
      if (!lead_data.customer?.mobile) {
        alert("Mobile is required");
        toast.error("Mobile is required");
        return;
      }
    }

    handleDataFromMobileAlready(lead_data);
  };

  const handleNewLead = () => {
    makeNewLead(true);
  };

  return (
    <>
      <div className="mytoolverification">
        <h2 className="verificationheadingmain">Mobile No. Already Exists</h2>
        <p>
          This number exists for the following customer(s). Select the customer
          to proceed.
        </p>

        <div className="row">
          {leadList.map((data) => {
            return (
              <div className="col-sm-6" key={data.id}>
                <div className="loanfulfillment">
                  <div className="loaninput">
                    <input
                      type="radio"
                      required
                      id={`fulfillment_${data.id}`}
                      name="fulfillment_type"
                      value={data.id} // Use a unique value for each radio button
                      checked={selectedRadioOption === String(data.id)} // Compare with the unique value
                      onChange={(e) => {
                        handleRadioChange(e);
                        handleInput(data); // Update lead_data on change
                      }}
                    />
                    <div>
                      <label htmlFor={`fulfillment_${data.id}`}>
                        {data.customer.first_name}
                      </label>
                      <div>
                        ID - {data.id} | {data.loan_type === 1 ? "HL" : "LAP"}
                      </div>
                    </div>
                  </div>
                  {/* <div className="primaryaccount">Primary</div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="buttonflexmobile">
        {(source === "new_lead" || source === "offer") && (
          <button className="cibilnewlead" onClick={handleNewLead}>
            + New Lead
          </button>
        )}
        <button className="cibilcheckbtn" onClick={handleNext}>
          Continue
        </button>
      </div>
    </>
  );
};

export default MobileAlready;
