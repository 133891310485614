import React, { useEffect, useState } from "react";
import NoDataFound from "../../../common/NoDataFound";
import { executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import { SCHEME_BANK_LIST } from "../../../queries/payout.gql";
import PageLoader from "../../../view/elements/PageLoader";

const SchemePopup = ({ bankList }) => {
    const [schemeBankList, setSchemeBankList] = useState([])
    const [loading, setLoading] = useState(false)

    const getList = async () => {
        setLoading(true)
        const result = await executeGraphQLQuery(SCHEME_BANK_LIST);
        const updatedResult = result.data.dsa_list.map(item => {
            return {
                ...item,
                logo: bankList.find(bank => bank.id == item.bank_id)?.logo || ""
            }
        })
        setSchemeBankList(updatedResult);
        setLoading(false)
    }
    useEffect(() => {
        getList()
    }, [])

    return (
        <>
            <div>
                <div className="scheme-popup-heading">Lender Codes</div>
                <div className="popup-table-container">
                    <table>
                        <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
                            <tr>
                                <th className="table-header">Lender Name</th>
                                <th className="table-header">Home Loan & LAP</th>
                            </tr>
                        </thead>
                        <tbody>
                            {schemeBankList?.length > 0 ? schemeBankList?.map((data, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td className="lender-list">{data?.logo ? <img src={data.logo} /> : null}{data?.bank_name || '--'}</td>
                                        <td>{`${data?.dsa_code || ''} ${data?.region || ''} ${data?.product_type || ''}`.trim() || '--'}</td>
                                    </tr>
                                </React.Fragment>
                            )) : <td colspan='2' style={{ textAlign: "center" }}><NoDataFound /></td>}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default SchemePopup;
