import { gql } from "@apollo/client";

export const SCHEME_LIST = gql`
query {
  getAllSchemsList{
    id
    scheme_name
   
  }
}`;

export const SCHEME_LIST_WITH_FILTERS = (page_no, fulfillment_type,product_type,scheme_type,expiry_from,expiry_to,scheme_name, status,city_ids,scheme_for,financersids) => gql`
{ 
  scheme_list(page_no:${page_no}, fulfillment_type:"${fulfillment_type}",product_type:"${product_type}",scheme_type:"${scheme_type}",expiry_from:"${expiry_from}",expiry_to:"${expiry_to}",scheme_name:"${scheme_name}", approved_status:${status},city_ids:"${city_ids}",scheme_for:"${scheme_for}", financer_id:"${financersids}" ){
    scheme_list {
      id
      scheme_name
      scheme_for
      approved_status
      start_date
      end_date
      is_deleted
      created_by
      updated_by
      updated_date
      created_date
      scheme_type
      is_all_cities
      is_all_financers
      is_default
      scheme_for
      scheme_code
      fulfillment_type
      schemecities{
        scheme_id
        city_id
        status 
    }
    }
    pagination{
      nextpage
      page_no
      totalpage
      totalrecords
    }
    total_count{
      all_schemes
      active_schemes
      expired_schemes
      deleted_schemes
    }
  }
}
`



export const Add_SCHEME = gql`
mutation addScheme($addSchemeArgs:AddSchemeArgs!){
  addScheme(addSchemeArgs:$addSchemeArgs)
  {
    msg
    code
    id
    error
  }
}`

export const Add_PARTNER_SCHEME = gql`
mutation saveSchemePartner($schemePartnerInputArgs: AddSchemePartnerInput!){
  saveSchemePartner(schemePartnerInputArgs:$schemePartnerInputArgs){
    status
    msg
  }
  
}`



export const Update_SCHEME = gql`
mutation updateScheme($updateSchemeArgs:UpdateSchemeArgs!){
  updateScheme(updateSchemeArgs:$updateSchemeArgs)
  {
    msg
    code
    id
    error
  }
}`

export const Update_SCHEME_CITIES = gql`
mutation saveSchemeCities($addSchemeCityArgs:AddSchemeCityArgs!){
  saveSchemeCities(addSchemeCityArgs:$addSchemeCityArgs)
}`

export const Add_Save_City_List = gql`
mutation saveSchemeCities($addSchemeCityArgs:AddSchemeCityArgs!){
  saveSchemeCities(addSchemeCityArgs:$addSchemeCityArgs)
}`

export const Add_Builders = gql`
mutation saveSchemeBuilder($schemeBuilderInputArgs: AddSchemeBuilderInput!){
  saveSchemeBuilder(schemeBuilderInputArgs:$schemeBuilderInputArgs)
}`

export const Add_Scheme_Range = gql`
mutation saveSchemeBuilder($schemeBuilderInputArgs: AddSchemeBuilderInput!){
  saveSchemeBuilder(schemeBuilderInputArgs:$schemeBuilderInputArgs)
}`

export const SCHEME_FIINACER_GROUP = gql`
mutation saveSchemeFinancerGroup($schemeFinancerGroupArgs:SchemeFinancerGroupInput!){
  saveSchemeFinancerGroup(schemeFinancerGroupArgs:$schemeFinancerGroupArgs)
}`

export const DELETE_FINANCER_FROM_GROUP_FINANCER = gql`
mutation deleteFinancerFromFinanceGroup($financer_id:Int!,$financer_group_id:Int!){
  deleteFinancerFromFinanceGroup(financer_id:$financer_id,financer_group_id:$financer_group_id)
}`


export const DELETE_SCHEME_RANGE  = gql`
mutation deleteSchemeRange($id:Int!){
  deleteSchemeRange(id:$id)
}`

export const DELETE_SCHEME_PATNER  = gql`
mutation deleteSchemePartner($id:Int!){
  deleteSchemePartner(id:$id)
}`

export const SAVE_FINANCE_RANGES = gql `
mutation saveSchemeRanges($schemeRangeInputArgs: SchemeRangeInputArgs!){
  saveSchemeRanges(schemeRangeInputArgs:$schemeRangeInputArgs)
}`

export const PARTNER_SCHEME_LIST = (partner_id=0) => gql`
{
  getSchemesByPartnerId(partner_id:${partner_id}){
      id
      scheme_name
      scheme_code
      scheme_type
      scheme_for
      start_date
      end_date
      schemepartner {
          partner_id
          created_date
          created_by
      }
      status
  }
}`;


export const GET_SCHEME_FINANCER_GROUPS = (scheme_id=0) => gql`
{
  schemeFinancerGroupbySchemeId(scheme_id:${scheme_id}){
   scheme_id
   id
   pay_out
   is_on_payin
   product_type
   loan_type
   employment_type
   schemebanks {
       scheme_id
       financer_id
       is_deleted
       created_by
   }
   schemerange {
       id
       scheme_id
       financer_group_id
       start_range
       end_range
       is_deleted
       payout
   }
  }
}
`;

export const GET_SCHEMA_LIST_BYID = (scheme_id=0) => gql`
query{
  schemeById(scheme_id: ${scheme_id}) {
      id
      scheme_name
      scheme_for
      schemerange {
          id
      }
}
}`;

export const UPDATE_SCHEME_STATUS = (scheme_id=0,approved_status) => gql`
query{
  change_scheme_approval_status(scheme_id: ${scheme_id}, approved_status: ${approved_status})
}`;

export const GET_SCHEMA_LIST_BY_CODE = (scheme_code='') => gql`
query{
  schemeByCode(scheme_code:"${scheme_code}"){
      id
      scheme_name
      scheme_for
      scheme_type
      scheme_code
      status
      start_date
      end_date
      is_all_builders
      is_all_cities
      is_all_financers
      is_default
      created_by
      created_date
      fulfillment_type
      approved_status
      schemerange {
          id
          scheme_id
          start_range
          end_range
          payout
          
      }
  }
}`;


export const SCHEME_CITIES_VARIABLE = (scheme_id=0) => gql`
query{
  schemecitybyschemeid(scheme_id:${scheme_id}){
    city_id
  }
}`;
export const PAYOUT_POINT_DETAILS = () => gql `
{
  points{
  id
  booking_point
  slug
  status
}}
`

export const FINEX_MASTER_LIST = () => gql`
query{
  masterdata{
  bank_list{
    id
    logo
    banklang{
        bank_id
        bank_name
    }
  }
  

  loan_type{
    id
    label
  }
  loan_sub_type{
    id
    label
  }
  fulfillment_type{
    id
    label
  }
  
 
  }
}`;

export const FINEX_BANK_LIST = () => gql`
query{
  bank_pa{
  bank_list{
    id
    banklang{
        bank_id
        bank_name
    }
  }
  }
}`;
// export const PAYOUT_POINT_DEATIL = () => gql `
// query {
  
//     points{
//     id
//     booking_point
//     slug
//     status
// }
// }
// `


export const SCHEME_COPY = (id) => gql`
query{
  copy_scheme(scheme_id:${id}){
    id
    code
    msg
  }
}`;

export const FINEX_MASTER = () => gql`
query{
  masterdata(api_called_by:web,is_partnership:"0"){
  bank_list{
    id
    banklang{
        bank_id
        bank_name
    }
    logo
  }
  loan_type{
    id
    label
  }
  loan_sub_type{
    id
    label
  }
  fulfillment_type{
    id
    label
  }
  }
}`;


// export const UPDATE_STATUS = gql`
//   mutation updateStatus($action_type: String!, $id: Float!, $status: String!, $user_id: Float!){
//     update_status(action_type: $action_type, id: $id, status: $status, user_id: $user_id) {
//       message
//     }
// }`

export const SCHEME_PARTNER_LISTING = (scheme_id, search_by, search_text, partner_status,page_no,is_subscribed) => gql`
{ 
  partners(scheme_id:${scheme_id}, search_by:"${search_by}", search_text:"${search_text}", partner_status:"${partner_status}",page_no:${page_no},is_subscribed:${is_subscribed} ){
    dealers{
      id
      agent_code
      profession_name
      partner_subscribed_id
      cityname
      first_name
      status
      organization
     
      
    }
    pagination{
      totalpage
      totalrecords
      nextpage
      prevpage
    }
  }
}
`

export const SCHEME_DATA = (scheme_id , partner_id , financer_group_id) => gql`
{
schemeFinancerGroupbySchemeId(scheme_id: ${scheme_id}, partner_id:${partner_id}, financier_group_id:${financer_group_id}) {
  id
  product_type
  loan_type
  scheme{
      scheme_name
      start_date
      end_date
  }
  schemerange{
      id
      start_range
      end_range
      payout
  }
  schemepartnerrange{
      id
      range_id
      payout
  }
  banksdetails{
      bank_logo
      bank_name
      bank_id
  }
}
}

`;

