import csvToJsonLib from "csvtojson";

const digit1 = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
const digit2 = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

export const convertCsvToJson = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const csvData = event.target.result;
      const jsonArray = await csvToJsonLib().fromString(csvData);
      resolve(jsonArray);
    };

    reader.onerror = (error) => reject(error);

    reader.readAsText(file);
  });
};

export const convertJsonToCsv = (json) => {
  const items = json.map((item) => Object.values(item));
  const header = Object.keys(json[0]);
  const csvData = [header.join(","), ...items.map((row) => row.join(","))].join(
    "\n"
  );
  return csvData;
};

export const downloadFile = (fileData, fileType, fileName) => {
  const blob = new Blob([fileData], { type: fileType });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const getNumberFormat_IN = (value) => {
  const formattedNumber = value.toLocaleString("en-IN");
  return formattedNumber;
};

export const sortStateListByTopStates = (state_list = []) => {
  const topStates = [
    "Delhi",
    "Haryana",
    "Karnataka",
    "Maharashtra",
    "Telangana",
    "Uttar Pradesh",
  ];

  const sortedStates = state_list.sort((a, b) => {
    const aTopIndex = topStates.indexOf(a.name);
    const bTopIndex = topStates.indexOf(b.name);

    if (aTopIndex !== -1 && bTopIndex !== -1) {
      return a.name.localeCompare(b.name);
    } else if (aTopIndex !== -1) {
      return -1;
    } else if (bTopIndex !== -1) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });
  return sortedStates;
};

export const numberWithCommas = (value)=> {
  return (value==0)?'--': '₹ '+value.toLocaleString('en-IN')
}

export const handlePriceInputIndian = (value)=>{
  if (!value || isNaN(value)) return "";
  return new Intl.NumberFormat('en-IN').format(+value);
}

export const formatCurrency = (x) => {
  if (x) {
      x = x.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers != '')
          lastThree = ',' + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return res
  }
  return null
}

export const convertToWords=(num)=> {
  if ((num = num.toString()).length > 9) return 'overflow';
  let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return; var str = '';
  str += (n[1] != 0) ? (digit1[Number(n[1])] || digit2[n[1][0]] + ' ' + digit1[n[1][1]]) + 'crore ' : '';
  str += (n[2] != 0) ? (digit1[Number(n[2])] || digit2[n[2][0]] + ' ' + digit1[n[2][1]]) + 'lakh ' : '';
  str += (n[3] != 0) ? (digit1[Number(n[3])] || digit2[n[3][0]] + ' ' + digit1[n[3][1]]) + 'thousand ' : '';
  str += (n[4] != 0) ? (digit1[Number(n[4])] || digit2[n[4][0]] + ' ' + digit1[n[4][1]]) + 'hundred ' : '';
  str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (digit1[Number(n[5])] || digit2[n[5][0]] + ' ' + digit1[n[5][1]]) + 'only ' : '';
  return str;
}
