import React, { Component } from 'react';
import Noimage from '../../../webroot/images/noimage.jpg';
import CheckBox from '../../elements/CheckBox';
import InputField from '../../elements/Input';
import Select from 'react-select';
import { toast } from 'react-toastify';
import MasterService from './../../../service/MasterService';

class TagPhotos extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.state = {
            key: 1,
            tags: "",
            loading: true,
            tagslides: props.slides || []
        }
    }
    handleSelect(key) {
        this.setState({ key });
    }

    handleChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.value
        });
    }
    handleTagOptionChange = (idx) => (ovalue) => {
        let tagslidestemp = [...this.state.tagslides];
        tagslidestemp[idx].tag_id = ovalue.image_tag_id;
        this.setState({ tagslides: tagslidestemp }, () => { })
    }
    handletCommentTextChange = (sname, idx, ovalue) => {
        let tagslidestemp = [...this.state.tagslides];
        tagslidestemp[idx][sname] = ovalue.target.value;
        this.setState({ tagslides: tagslidestemp }, () => { })
    }
    handleChangetext = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            tagslides: nextProps.slides,
        });
    }
    saveTagPhotoDetail = () => {     
            this.setState({ loading: true })    
            let tagslidestemp = [...this.state.tagslides]; 
            let postData={};
            let tagSlidedData=[];
            tagslidestemp.forEach((res, index) => {
                let tempTagSlidedData={
                    "id": res.id,
                    "tag_id": res.tag_id,
                    "comment": res.comment
                };
                tagSlidedData.push(tempTagSlidedData);
            })
            postData.updateImageTag=tagSlidedData;
            MasterService.post('inventory/inventory/save_tagged_image_list', postData)
            .then((result) => {
                if (result && result.data && result.data.status == 200) {
                    this.setState({ loading: false })
                    toast.success(result.data.message);
                }
                else {
                    this.setState({ loading: false })
                }
            }).catch(error => {
                this.setState({ loading: false })
            });
    }    
    render() {
        const { imageTagListDpd } = this.props;
        const { tagslides } = this.state;
        //console.log(tagslides);
        return (
            <div>
                {/* <form onSubmit={this.saveTagPhotoDetail} method="post"> */}
                <div className="row tagsphotos">
                    {tagslides.map((slide, index) =>
                        <div className="col-md-6 form-group" key={index}>
                            <div className="row tagscol">
                                <div className="col-sm-5">
                                    <div className="recent-list-img">
                                        <img className="img-responsive" alt="64x64" src={slide.src} />
                                    </div>
                                </div>
                                <div className="col-sm-7">
                                    <div><textarea onChange={this.handletCommentTextChange.bind(this, 'comment', index)} className="form-control small-textarea mrg-T5 arraytypefield" value={slide.comment || ''} placeholder="Description" id="comment" name="comment"></textarea></div>
                                    <Select
                                        id="tag"
                                        onChange={this.handleTagOptionChange(index)}
                                        options={imageTagListDpd}
                                        name="tag"
                                        placeholder="Select Tag"
                                        value={imageTagListDpd.filter(({ image_tag_id }) => image_tag_id === slide.tag_id)}
                                        getOptionLabel={({ tag_name }) => tag_name}
                                        getOptionValue={({ image_tag_id }) => image_tag_id}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="text-right"><button type="button" className="btn btn-primary" onClick={this.saveTagPhotoDetail}>Submit</button></div>
                {/* </form> */}
            </div>
        );
    }
}

export default TagPhotos;