import React, { useState } from 'react';

const SideBar = ({ children, isOpen, toggleSideBar, width, title }) => {

    return (
        <>
            {isOpen && (
                <div className="sidebar-overlay" onClick={toggleSideBar}></div>
            )}
            <div className={`sidebar ${isOpen ? 'open' : ''}`} style={{ width: width || '30%' }}>
                <div className="sidebar-header">
                    <span className="sidebar-title">{title}</span>
                    <span className="close" onClick={toggleSideBar}>&times;</span>
                </div>
                <div >
                    {children}
                </div>
            </div>
        </>
    );
};

export default SideBar;
